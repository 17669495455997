import React from 'react'

const AgencyNonFillIcon = () => {
  return (
    <div>
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.09543 18.2329L26 0.865247L49.9046 18.2329L40.7738 46.3344H11.2262L2.09543 18.2329Z" stroke="#5D38E0" stroke-width="1.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3334 22.6666C18.8731 22.6666 18.5 23.0397 18.5 23.5V31.8333C18.5 32.2935 18.8731 32.6666 19.3334 32.6666H32.6667C33.1269 32.6666 33.5 32.2935 33.5 31.8333V23.5C33.5 23.0397 33.1269 22.6666 32.6667 22.6666H19.3334ZM16.8334 23.5C16.8334 22.1192 17.9527 21 19.3334 21H32.6667C34.0474 21 35.1667 22.1192 35.1667 23.5V31.8333C35.1667 33.214 34.0474 34.3333 32.6667 34.3333H19.3334C17.9527 34.3333 16.8334 33.214 16.8334 31.8333V23.5Z" fill="#5D38E0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5656 18.3989C23.0344 17.93 23.6703 17.6666 24.3334 17.6666H27.6667C28.3297 17.6666 28.9656 17.93 29.4345 18.3989C29.9033 18.8677 30.1667 19.5036 30.1667 20.1666V33.5C30.1667 33.9602 29.7936 34.3333 29.3334 34.3333C28.8731 34.3333 28.5 33.9602 28.5 33.5V20.1666C28.5 19.9456 28.4122 19.7337 28.256 19.5774C28.0997 19.4211 27.8877 19.3333 27.6667 19.3333H24.3334C24.1124 19.3333 23.9004 19.4211 23.7441 19.5774C23.5878 19.7337 23.5 19.9456 23.5 20.1666V33.5C23.5 33.9602 23.1269 34.3333 22.6667 34.3333C22.2065 34.3333 21.8334 33.9602 21.8334 33.5V20.1666C21.8334 19.5036 22.0968 18.8677 22.5656 18.3989Z" fill="#5D38E0"/>
    </svg>
    </div>
  )
}

export default AgencyNonFillIcon
