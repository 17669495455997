import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useRef, useState } from 'react';
import { formatedMetric, formatedMetricWithUnits, formatSingleDateFn, interpolateColor } from '../../../../utils/commonFunction';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver'

interface Props {
  dataArr: any[]
  selectedMetric: string
  setSelectedMetric: React.Dispatch<React.SetStateAction<string>>
  selectedDate: any
  setSelectedDate: React.Dispatch<any>
  intervalType: any
  intervalRange: any
}

const DatewiseTable: React.FC<Props> = (props) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client
  const { dataArr, selectedMetric, setSelectedMetric, selectedDate, setSelectedDate, intervalRange } = props;
  const time_stamps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const [toggle, setToggle] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false)
  const [rowData, setRowData] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<any[]>([]);

  // const { start_date, end_date } = intervalRange;
  // let { data,isLoading:load } = useGetDatewiseQuery({ client_id: client_id, start_date: start_date, end_date: end_date });
  // console.log(data);

  // const fetchData = (data:any) => {
  //   const dateSet: any = {};
  //   data?.forEach((item: any) => {
  //     // console.log(item);
  //     const performance: any = item['date']
  //     if (!dateSet[performance]) {
  //       dateSet[performance] = [];
  //     }

  //     dateSet[performance].push(item);
  //   });
  //   let temp: any = [];
  //   Object.keys(dateSet).forEach((date) => {
  //     const dailyData: any = {
  //       date: date
  //     }
  //     dateSet[date].forEach((item: any) => {
  //       if (selectedMetric === 'ctr' || selectedMetric === 'acos' || selectedMetric === 'cpc' || selectedMetric === 'roas') {
  //         dailyData[item.hour + 1 + '_' + selectedMetric] = Math.round(item[selectedMetric] * 100) / 100;
  //       } else {
  //         dailyData[item.hour + 1 + '_' + selectedMetric] = Math.round(item[selectedMetric]);
  //       }
  //     })
  //     temp.push(dailyData)
  //   })
  //   // console.log(temp);

  //   // let totalObj: any = { date: 'Total' }
  //   // for (let hour of time_stamps) {
  //   //   let total = 0;
  //   //   for (let el of temp) {
  //   //     if (el[`${hour}_${selectedMetric}`]) {
  //   //       total += el[`${hour}_${selectedMetric}`]
  //   //     }
  //   //   }
  //   //   totalObj[`${hour}_${selectedMetric}`] = total;
  //   // }
  //   // setTotalRow([totalObj]);
  //   temp.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
  //   // setDates(dates)
  //   return temp;
  // }


  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setToggle(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);



  useEffect(() => {
    const { start_date, end_date } = intervalRange;
    setIsLoading(true);
    if (start_date && end_date) {
      axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/daily?startDate=${start_date}&endDate=${end_date}`)
        .then((res) => {
          const dateSet: any = {};
          res.data.forEach((item: any) => {
            // console.log(item);
            const performance: any = item['date']
            if (!dateSet[performance]) {
              dateSet[performance] = [];
            }

            dateSet[performance].push(item);
          });
          let temp: any = [];
          Object.keys(dateSet).forEach((date) => {
            const dailyData: any = {
              date: date
            }
            dateSet[date].forEach((item: any) => {
              // dailyData[item.hour+1 + '_' + selectedMetric] = Math.round(item[selectedMetric])
              if (selectedMetric === 'ctr' || selectedMetric === 'acos' || selectedMetric === 'cpc' || selectedMetric === 'roas' || selectedMetric === 'cvr') {
                dailyData[item.hour + 1 + '_' + selectedMetric] = Math.round(item[selectedMetric] * 100) / 100;
              } else {
                dailyData[item.hour + 1 + '_' + selectedMetric] = Math.round(item[selectedMetric]);
              }
            })
            temp.push(dailyData)
          })
          // console.log(temp);

          let totalObj: any = { date: 'Total' }
          for (let hour of time_stamps) {
            let total = 0;
            for (let el of temp) {
              if (el[`${hour}_${selectedMetric}`]) {
                total += el[`${hour}_${selectedMetric}`]
              }
            }
            totalObj[`${hour}_${selectedMetric}`] = total;
          }
          setTotalRow([totalObj]);
          temp.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
          // setDates(dates)
          setRowData(temp);
        }).catch((error) => {
          console.log(error);

        }).finally(() => {
          setIsLoading(false);
        })
    }
  }, [client_id, selectedMetric, intervalRange])


  function heatmap(value: any, el: any) {
    let a: any = Object.values(value);
    a.splice(0, 1);
    const currVal = value[`${el}_${selectedMetric}`]
    const min = Math.min(...a.filter((x: number) => x !== 0));
    const max = Math.max(...a.filter((x: number) => x !== 0));
    if (min === max || currVal === 0) return '#ffffff'
    const normalizedFactor = ((currVal - min) / (max - min))
    const color = interpolateColor('#B3FFFF', '#1ABFFF', normalizedFactor);
    return color
  }


  const hoursBody = (props: any, el: any) => {
    return (
      <div style={{
        backgroundColor: heatmap(props, el),
        width: '100%',
        height: '100%',
        textAlign: 'center',
        padding: '10px 0px',
        boxSizing: 'border-box'
      }}>
        {props[`${el}_${selectedMetric}`] !== undefined ? (props[`${el}_${selectedMetric}`])?.toLocaleString() : '-'}
      </div>
    )
  }
  const totalBody = (props: any) => {

    let total = 0;
    for (let time of time_stamps) {
      if (props[`${time}_${selectedMetric}`]) {
        total = total + props[`${time}_${selectedMetric}`];
      }
    }

    if (selectedMetric === 'acos' || selectedMetric === 'ctr' || selectedMetric === 'cpc' || selectedMetric === 'roas' || selectedMetric === 'cvr' || selectedMetric === 'aov') {
      total = total / (Object.keys(props).length - 1)
    } else {
      total = Math.round(total * 100) / 100
    }

    return (
      <div>
        {total?.toLocaleString()}
      </div>
    )
  }

  const performanceBody = (props: any) => {
    const { date } = props;
    // console.log(date);
    const newDate = new Date(date);
    const dayOfWeek = newDate.toLocaleString('en-IN', { weekday: 'short' });
    if (date === 'Total') {
      return (
        <div>{date}</div>
      )
    }
    return (
      <div>
        {`${formatSingleDateFn(date)} (${dayOfWeek})`}
      </div>
    )
  }

  const handleMetricChange = (el: any) => {
    setSelectedMetric(el.metric);
    setToggle(false);
    setSelectedDate(selectedDate)
  }

  const onRowSelect = (e: any) => {
    // console.log(e.value);
    if (e?.value?.date === 'Total') {
      return;
    }
    setSelectedDate(e.value)
  }

  //  const isTableDataEmpty=false;
  const isTableDataEmpty = rowData.length <= 1; // Check if data is empty


  const exportToCSV = () => {
    let csvContent = '';
    const header = ['date/hour', ...time_stamps].map(col => col).join(',');
    csvContent += header + '\n';

    totalRow.forEach((row: any) => {
      csvContent += row['date']
      csvContent += ','
      const rowData = time_stamps.map(col => {
        return row[`${col}_${selectedMetric}`]
      }).join(',');
      csvContent += rowData + '\n';
    });


    rowData.forEach((row: any) => {
      csvContent += row['date']
      csvContent += ','

      const rowData = time_stamps.map(col => {
        return row[`${col}_${selectedMetric}`]
      }).join(',');
      csvContent += rowData + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'date_wise_performance.csv');
  };

  return (
    <div className='table_card_new'>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <div ref={popupRef} style={{ position: 'relative', display: 'flex' }}>
          <div style={{}}>
            <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
          </div>
          <span style={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? 0.5 : 1 }}
            onClick={() => setToggle(prev => !prev)} className='metricSelect'>Select Metric &nbsp;&nbsp;&nbsp; <span className="metricSelectIcon"><i className="bi bi-chevron-down stroke"></i></span></span>
          {toggle &&
            <div className='metric_box'>
              {dataArr?.map((el, i) => {
                return (
                  <div key={i}>
                    <span className='kpi_card-selectEl' onClick={() => handleMetricChange(el)}>{formatedMetric(el.metric)}</span>
                  </div>
                )
              })}
            </div>
          }
        </div>
        <span style={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? 0.5 : 1 }} className='table_kpi_name'>{formatedMetricWithUnits(selectedMetric)}</span>
      </div>
      <PrimeReactProvider>
        {isLoading ? (
          <LoaderSkeletonTable height='350px' />
        ) : isTableDataEmpty ? (
          <div
            style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}
          >
            No Data Available
          </div>
        ) : (

          <DataTable
            value={rowData}
            size='small'
            columnResizeMode="expand"
            resizableColumns
            scrollable scrollHeight="350px"
            //  virtualScrollerOptions={{ itemSize: 20 }}
            selectionMode="single"
            selection={selectedDate}
            onSelectionChange={onRowSelect}
            className='no-padding'
            dataKey='date'
            frozenValue={totalRow}
          >
            <Column field='date' header='Date/Hour'
              style={{ width: '100px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
              body={performanceBody}
            />
            {
              time_stamps.map((el, i) => {
                return (
                  <Column
                    key={i}
                    header={el}
                    field={`${el}_${selectedMetric}`}
                    style={{ width: '42px' }}
                    headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', boxSizing: 'border-box' }}
                    bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                    bodyClassName="no-padding"
                    body={(props) => hoursBody(props, el)}

                  />
                )
              })
            }
            <Column field="" header='Total'
              style={{ width: '60px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
              body={totalBody}
            />
          </DataTable>
        )}
      </PrimeReactProvider>
    </div>
  )
}

export default DatewiseTable