import axios from 'axios';
import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import { formatDate, formatedMetric, getMontheInText, isValidDate } from '../../../../utils/commonFunction';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver'

const TableOne = ({ setClickedKPI, clickedHour, dates }: any) => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client
    const [rowData, setRowData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [rowSelect, setRowSelect] = useState<any[]>([]);

    const isRowDataEmpty = rowData.length === 0 || rowData.every(item => Object.keys(item).length === 0);

    useEffect(() => { 

        if (isValidDate(dates[0]) && isValidDate(dates[1]) && clickedHour) {
            setIsLoading(true)
            axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/hourly_comparative_metrics?hour=${clickedHour?.hour - 1}&startDate=${formatDate(dates[0])}&endDate=${formatDate(dates[1])}`)
                .then((res) => {
                    // console.log(res.data);
                    res?.data?.forEach((row: any) => row.hour += 1)
                    let temp: any = [];
                    let keysArr = ['cost', 'impressions', 'clicks', 'attributedSales14d', 'attributedConversions14d', 'attributedUnitsOrdered14d', 'cpc', 'ctr', 'acos', 'roas','aov']
                    keysArr.forEach((el) => {
                        let obj: any = {};
                        res.data.forEach((item: any) => {
                            obj['metric'] = el
                            obj['hour'] = item.hour
                            if (el === 'ctr' || el === 'acos' || el === 'cpc' || el === 'roas') {
                                obj[item.bucket] = Math.round(item[el] * 100) / 100;
                            } else {
                                obj[item.bucket] = Math.round(item[el]);
                            }

                        })
                        temp.push(obj);
                    })
                    // console.log(temp);

                    setRowData(temp);
                }).catch((error) => {
                    console.log(error);

                }).finally(() => {
                    setIsLoading(false)
                })
        }
    }, [client_id, clickedHour, dates])

    const onRowSelect = (e: any) => {
        setRowSelect(e.value);
        setClickedKPI(e.value)
    }

    const getThreeMonthRange = () => {
        let current = new Date();
        let prevMonth = new Date(current);
        let prevToPrevMonth = new Date(current);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        prevToPrevMonth.setMonth(prevToPrevMonth.getMonth() - 2);
        return [prevToPrevMonth, prevMonth, current];
    }

    const performanceBody = (props: any) => {
        const { metric } = props;
        return (
            <div>
                {formatedMetric(metric)}
            </div>
        )
    }

    const headers = [
        { field: 'metric', header: 'Metrices', body: performanceBody },
        { field: 'hour', header: 'Hour' },
        { field: `${new Date(getThreeMonthRange()[0]).getMonth()+1}_MONTHLY_AVERAGE`, header: getMontheInText(new Date(getThreeMonthRange()[0]).getMonth() + 1) },
        { field: `${new Date(getThreeMonthRange()[1]).getMonth()+1}_MONTHLY_AVERAGE`, header: getMontheInText(new Date(getThreeMonthRange()[1]).getMonth() + 1) },
        { field: `${new Date(getThreeMonthRange()[2]).getMonth()+1}_MONTHLY_AVERAGE`, header: getMontheInText(new Date(getThreeMonthRange()[2]).getMonth() + 1) },
        { field: 'BAU_AVERAGE', header: 'BAU' },
        { field: 'FREEDOM_SALE', header: 'EVENT' },
        { field: 'CURRENT_HOUR', header: 'CURRENT_HOUR' }
    ]

    const exportToCSV = () => {
        let csvContent = '';
        const headerLine = headers.map(({ header }) => header).join(',');
        csvContent += headerLine + '\n';

        rowData.forEach((row: any) => {
            const rowData = headers.map(({ field }) => {
                return row[field]
            }).join(',');
            csvContent += rowData + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'cumulative_hourly_metric_report_upto(hour).csv');
    };

    return (
        <div style={{ marginTop: '1rem' }} className='table_card_new'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
                    <span className='table_kpi_name'>Cumulative Hourly Metric Report Upto (Hour)</span>
                </div>
            </div>
            <PrimeReactProvider>
                {isLoading ? (<LoaderSkeletonTable height='350px' />) :
                    isRowDataEmpty ? (
                        <div style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}>
                            No Data Available
                        </div>
                    ) : (

                        <DataTable
                            value={rowData}
                            size='small'
                            columnResizeMode="expand"
                            resizableColumns
                            scrollable scrollHeight="350px"
                            selectionMode="single"
                            selection={rowSelect}
                            onSelectionChange={onRowSelect}
                            dataKey='metric'
                            sortIcon="bi bi-chevron-expand stroke"
                        >
                            {headers.map(({ field, header, body }) => (
                                <Column key={field} field={field} header={header}
                                    style={{ width: '100px' }}
                                    sortable
                                    headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                    bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                                    body={body}
                                />
                            ))}
                        </DataTable>
                    )}
            </PrimeReactProvider>
        </div>
    )
}

export default TableOne

