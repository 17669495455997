import { AgGridReact } from 'ag-grid-react';
import '../pages/userstyle.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AG_AR, BASE_URL_NEW } from '../../../appConstant';
import axios from 'axios';
import { toast } from 'react-toastify';
import './brandmodule.scss'
import { useAppSelector } from '../../../redux/hook';




const MODES = {
  NONE: { label: "None", color: "#ccc" },
  VIEW: { label: "View", color: "#5D38E0A6" },
  EDIT: { label: "Edit", color: "#5D38E0" },
};



const buttoncomp = (props) => {
  const { data } = props;

  return (
    <button className="custom-button" >
      Button
    </button>
  );

}



const BrandModules = ({ brand, profilecomp, nameComp, setIsLoading, toggleModuleMode }) => {
  const [rowData, setRowData] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dropdown, setDropdown] = useState(null); // Track which row is showing dropdown
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const loggeduser = useAppSelector((state) => state.user);

  const [gridApi, setGridApi] = useState(null); // Store grid API reference
  const { current_agency, current_brand, current_brand: { access_role } } = useAppSelector((state) => state.client)
  const access_role_user = access_role;





  useEffect(() => {
    // console.log("Dropdown State Updated:", dropdown);
  }, [dropdown]);






  useEffect(() => {
    // setIsLoading(true)
    axios.get(`${BASE_URL_NEW}/subscription?populate=true&brand=${brand?.client_id}`)
      .then((res) => {
        // console.log('res data branddd kkkk', res.data)

        const dynamicCols = res.data.map((module) => ({
          headerName: module.module.module.replace(/_/g, " "),
          field: module._id,
          cellRenderer: (props) => accessComp(props, module._id, module.module._id),
          cellClass: "center-text overflow-visible",
          width: 100,
          suppressRowTransform: true,
        }));
        setDynamicColumns(dynamicCols);

      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        // setIsLoading(false)
      })

    axios.get(`${BASE_URL_NEW}/user/brand/${brand?.client_id}`)
      .then((res1) => {
        toggleModuleMode === 'publish' ?
          setRowData(res1.data.sort((a, b) => (a?.user?.email === loggeduser?.email ? -1 : b?.user?.email === loggeduser?.email ? 1 : 0))) :
          setRowData(res1.data.filter((data) => data?.user?.email === loggeduser?.email))
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        // setIsLoading(false)
      })
  }, [brand, dropdown, toggleModuleMode])




  const updateData = [];



  const accessComp = (props, field, colorID) => { 
    const { data, node, api, column } = props; // Get the row data
    const { _id, access_role } = data; // Unique row ID


    // console.log('access_module data',data)
    // console.log('access_module passed data',props, field, colorID)


    const mode = data[colorID] || (access_role === 'ADMIN' || access_role === 'BRAND_ADMIN' || access_role === 'AGENCY_ADMIN' ? 'EDIT' : 'NONE');        // Extract the current access mode

    if (data[colorID] && field) {
      const newEntry = {
        subscription: field,
        access_right: data[colorID],
      };

      // Avoid duplicate entries
      if (!updateData.some(item => item.subscription === field && item.access_right === data[colorID])) {
        updateData.push(newEntry);
      }
    }

    // const isDisabled = access_role === "ADMIN" || access_role === "BRAND_ADMIN" || access_role === "AGENCY_ADMIN";

    const isDisabled =
      toggleModuleMode === 'request' ? false :
        access_role_user === "MEMBER" || access_role_user === "CLIENT" ||
        (access_role_user === "MANAGER" && (access_role === "MANAGER" || access_role === "ADMIN")) ||
        (access_role_user === "ADMIN" && access_role === "ADMIN");


    const identifier = String(_id) + "-" + String(field);



    const toggleDropdown = (event) => {
      if (isDisabled) return;
      setDropdown(prev => (prev === identifier ? null : identifier));

    };



    const updateMode = async (field, accessId, mode) => {

      const access = rowData.find((row) => row._id === accessId)


      if (toggleModuleMode === 'publish') {

        let subscription_to_update = []

        if (access.subscription_access.length) {
          subscription_to_update = access.subscription_access.map(({ subscription, access_right }) => {
            if (subscription._id === field) {
              return {
                "subscription": field,
                "access_right": mode
              }
            } else {
              return {
                "subscription": subscription._id,
                "access_right": access_right
              }
            }

          })
        } else {

          subscription_to_update = dynamicColumns.map((subs) => {


            if (subs.field === field) {
              return {
                "subscription": field,
                "access_right": mode
              }
            } else {
              let subscription = access.subscription_access.find((sub) => sub._id === subs.field)
              if (!subscription) {
                subscription = {
                  _id: subs.field,
                  access_right: 'NONE'
                }
              }

              return {
                "subscription": subscription._id,
                "access_right": subscription.access_right
              }
            }

          })

        }


        try {

          await axios.patch(`${BASE_URL_NEW}/access/${accessId}`, {
            "subscription_access": subscription_to_update
          }).then((res) => {
            //  toast('field updated success')
          }).catch((err) => {
            toast('err updating field')
          }).finally(() => {
          })

          setRowData(prevData =>
            prevData.map(row => row._id === accessId ? {
              ...row,
              modules: { ...row.modules, [field]: mode }
            } : row)
          );

        } catch (error) {
          console.error("Error updating mode:", error);
        }

      }
      else {


        try {


          // console.log('access', access)
          // console.log('field,accessID,mode', field, accessId, mode)

          // console.log('correct one')

          let subscription_to_update = []

          if (access.subscription_access.length) {
            subscription_to_update = access.subscription_access.map(({ subscription, access_right }) => {
              if (subscription._id === field) {
                return {
                  "subscription": field,
                  "status": "REQUESTED",
                  "access_right": mode
                }
              } else {
                return {
                  "subscription": subscription._id,
                  "status": "REQUESTED",
                  "access_right": access_right
                }
              }
  
            })
          } else {
  
            subscription_to_update = dynamicColumns.map((subs) => {
  
  
              if (subs.field === field) {
                return {
                  "subscription": field,
                  "status": "REQUESTED",
                  "access_right": mode
                }
              } else {
                let subscription = access.subscription_access.find((sub) => sub._id === subs.field)
                if (!subscription) {
                  subscription = {
                    _id: subs.field,
                    access_right: 'NONE'
                  }
                }
  
                return {
                  "subscription": subscription._id,
                  "status": "REQUESTED",
                  "access_right": subscription.access_right
                }
              }
  
            })
  
          }
  
         // we have to put some condition here to stop update req automatically it's in try block 

          try {
  
            await axios.patch(`${BASE_URL_NEW}/access/${accessId}`, {
              "subscription_access": subscription_to_update
            }).then((res) => {
              //  toast('field updated success')
            }).catch((err) => {
              toast('err updating field')
            }).finally(() => {
            })
  
            // setRowData(prevData =>
            //   prevData.map(row => row._id === accessId ? {
            //     ...row,
            //     modules: { ...row.modules, [field]: mode }
            //   } : row)
            // );
  
          } catch (error) {
            console.error("Error updating mode:", error);
          }

          toast.success('Request Submited')

        } catch (error) {
          console.error("Error updating mode:", error);
        }


      }




      setDropdown(null);
    };



    return (
      <div style={{ position: "relative" }}>
        {/* Clickable Box */}
        <div
          onClick={toggleDropdown}
          style={{
            width: "30px",
            height: "30px",
            backgroundColor: MODES[mode]?.color || "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            cursor: isDisabled ? "not-allowed" : "pointer",
            pointerEvents: isDisabled ? "none" : "auto",
          }}
        >
          <i className="bi bi-pencil-fill" style={{ color: "#fff" }}></i>
        </div>


        {/* Dropdown */}
        {dropdown === identifier && !isDisabled && (

          <div
            style={{
              // position: "absolute",
              position: "fixed",
              top: position.top,
              left: position.left,
              top: '50%',
              left: '50%',
              background: "#fff",
              boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
              borderRadius: "6px",
              zIndex: 1000,
              width: "120px",
              padding: "8px",
              border: "1px solid #ccc",
            }}
          >
            {Object.keys(MODES).map((key) => (
              <div
                key={key}
                onClick={() => {
                  updateMode(field, _id, key);
                  setDropdown(null);
                }}
                style={{
                  padding: "6px",
                  cursor: "pointer",
                  background: MODES[key].color,
                  color: "#fff",
                  textAlign: "center",
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                {MODES[key].label}
              </div>
            ))}
          </div>

        )}
      </div>
    );
  };





  const columnDefs = [

    { headerName: "PROFILE", field: "profile", cellRenderer: profilecomp, width: 90, suppressRowTransform: true, cellClass: 'overflow-visible' },
    { headerName: "NAME", field: "name", cellRenderer: nameComp, width: 140, suppressRowTransform: true, cellClass: 'overflow-visible' },
    {
      headerName: "Product Request ",
      children: dynamicColumns, // Dynamically added columns
    },
    // { headerName: "status", field: "name", cellRenderer: buttoncomp, width: 100 },
  ];


  const defaultColDef = useMemo(() => ({
    sortable: true, resizable: true, minWidth: 50
  }), []);

  return (

    <div className="ag-theme-alpine agency-table" style={{ width: '100%', }}>


      {/* Header Section with Indicators */}
      {dynamicColumns.length !== 0 ?
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: '0px 4px', marginBottom: '8px' }}>
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div style={{ width: "13px", height: "13px", backgroundColor: "#5D38E0", borderRadius: "50%" }}></div>
              <span style={{ fontSize: '.75rem', color: '#000000' }}>EDIT</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div style={{ width: "13px", height: "13px", backgroundColor: "#5D38E0A6", borderRadius: "50%" }}></div>
              <span style={{ fontSize: '.75rem', color: '#000000' }}>VIEW</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div style={{ width: "13px", height: "13px", backgroundColor: "#D2D2D2", borderRadius: "50%" }}></div>
              <span style={{ fontSize: '.75rem', color: '#000000' }}>NONE</span>
            </div>
          </div>
        </div> : ''
      }


      <div className="ag-theme-alpine agency-table" style={{ height: '60vh', width: '100%', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px ", }}>
        <AgGridReact
          rowData={rowData}
          rowHeight={AG_AR.rH}
          headerHeight={AG_AR.hH}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressNoRowsOverlay={true}
          suppressDragLeaveHidesColumns={true}
          suppressMenuHide={true}
          suppressCellFocus={true}
          suppressRowTransform={true}
          cellClass={'overflow-visible'}
          onGridReady={({ api }) => setGridApi(api)}
        >
        </AgGridReact>
      </div>
    </div>
  )
}




export default BrandModules;
