import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WidthFull } from '@mui/icons-material';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { TreeTable } from 'primereact/treetable';
import CloudUploadIcon from '../../../../assets/svgs/CloudUploadIcon';
import { toast } from 'react-toastify';
import LoaderAnimationfill from '../../../../assets/svgs/LoaderAnimationfill';
import { BASE_URL } from '../../../../appConstant';
import axios from 'axios';
import LoaderSkeletonLine from '../../../../utils/commonComponents/Loader/LoaderSkeletonLine';
import { useAppSelector } from '../../../../redux/hook';
import * as XLSX from 'xlsx';


const CompetitionMasterTable = ({ currentPlatform, rowSelect, setRowSelect }: { currentPlatform: any, rowSelect: any, setRowSelect: any }) => {
    const client = useAppSelector(state => state.client);
    const { current_brand } = client
    const [productMaster, setProductMaster] = useState([])
    const [competitionMaster, setCompetitionMaster] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingSub, setIsLoadingSub] = useState(false)
    const [error, setError] = useState(false)
    const [expandedRows, setExpandedRows] = useState<any>(null);
    const [isModalOpendownload, setIsModalOpendownload] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [editIconMode, setEditIconMode] = useState<string | null>(null);


    const [selectedPlatforms, setSelectedPlatforms] = useState<{ name: string; sku: string; img: string }[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    const availablePlatforms = [{ name: "Masala Protein puffs", sku: '456534', img: 'https://cdn.zeptonow.com/production///tr:w-1000,ar-1200-1200,pr-true,f-auto,q-80/cms/product_variant/3f7adac0-6e99-4a1e-b58a-f6a2142a2584.jpeg' },
    { name: "Nimbu Pudina Peanuts", sku: '123456', img: 'https://cdn.zeptonow.com/production///tr:w-1000,ar-1200-1200,pr-true,f-auto,q-80/cms/product_variant/34144f91-7d0c-4774-94f6-0775c924c29c.jpeg' },
    { name: "Cheese & Herbs Puffs", sku: '878789', img: 'https://cdn.grofers.com/da/cms-assets/cms/product/large_images/4ae35658-ee49-40d2-bcf3-f8a289042082.jpg?ts=1729671331' },
    { name: "Barbeque Protein puffs", sku: '498771', img: 'https://cdn.grofers.com/da/cms-assets/cms/product/large_images/bb8e210a-9b3e-4afb-b9d4-c1cf10a55a93.jpg?ts=1729671340' },];

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);  // New state to track edit mode

    const [isdropdownEdit, setIsdropdownEdit] = useState(false);



    // console.log('selected platform ', selectedPlatforms)





    const handlePlatformSelect = (platform: { name: string; sku: string; img: string }) => {

        if (!selectedPlatforms.some((p) => p.sku === platform.sku)) {
            setSelectedPlatforms((prev: any) => [...prev, platform]);
            setSearchTerm("");
            setIsDropdownVisible(false);
        }
    };

    const filteredPlatforms = availablePlatforms.filter(
        (platform) => (platform.name.toLowerCase().includes(searchTerm.toLowerCase()) || platform.sku.includes(searchTerm)) && !selectedPlatforms.some((p) => p.sku === platform.sku)
    );

    const removePlatform = (index: number) => {
        setSelectedPlatforms((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        console.log("Selected Platforms:", selectedPlatforms);

        //api call here 

        setIsModalOpenEdit(false); // Close modal after submission
    };

    const handleDelete = async (rowData: any) => {

        console.log('rowdata', rowData)

        try {
            await axios.post(`${BASE_URL}/delete-competition`, rowData);
            alert("Deleted successfully!");
            // Refresh Data
        } catch (error) {
            console.error("Error deleting:", error);
        }

        setIsEditing(false);
    };



    const onColumnResizeEnd = (e: any) => {

        const newColumns = columns.map(col => {
            if (col.field === e.column.props.field) {
                return { ...col, width: `${e.element.offsetWidth}px` };
            }
            return col;
        });
        setColumns(newColumns);
    };

    // Handle click outside to close dropdown
    // useEffect(() => {
    //     function handleClickOutside(event: MouseEvent) {
    //         if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
    //             setIsModalOpenEdit(false);
    //             setEditIconMode(null);
    //         }
    //     }

    //     if (isModalOpenEdit) {
    //         document.addEventListener("mousedown", handleClickOutside);
    //     }
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [isModalOpenEdit]);






    useEffect(() => {
        setIsLoading(true)
        axios.get(`${BASE_URL}/qcomm/competition-master?client_name=${current_brand?.client_name?.toLowerCase()}&platform=${currentPlatform}`,
        ).then((res) => {
            res.data.forEach((master: any) => {
                master.sp = `${master.max_sp}-${master.min_sp}`
                master.mrp = `${master.max_mrp}-${master.min_mrp}`
                master.expiry_days = `${master.max_expiry_days}-${master.min_expiry_days}`
                master.client_name = `${master.client_name} - ${master.competitions}`
            })
            setProductMaster(res.data)
        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
        })

        setRowSelect(undefined)
    }, [currentPlatform, current_brand])


    useEffect(() => {
        const selectedPlatformCode = rowSelect?.['platform_code']
        if (selectedPlatformCode) {
            setIsLoadingSub(true)
            axios.get(`${BASE_URL}/qcomm/competition-master?client_name=${current_brand?.client_name?.toLowerCase()}&platform_code=${selectedPlatformCode}&master_type=competition`,
            ).then((res) => {
                res.data.forEach((master: any) => {
                    master.sp = `${master.max_sp}-${master.min_sp}`
                    master.mrp = `${master.max_mrp}-${master.min_mrp}`
                    master.expiry_days = `${master.max_expiry_days}-${master.min_expiry_days}`
                })
                setCompetitionMaster(res.data)
            }).catch((err) => {
                console.log(err);
                setError(err.message)
            }).finally(() => {
                setIsLoadingSub(false);
            })
        }
    }, [rowSelect, current_brand])


    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
        if (file && allowedExtensions.exec(file.name)) {
            setUploadedFile(file);
            setIsModalOpen(false); // Close modal after upload
        } else {
            toast("Only Excel files (.xls, .xlsx, .csv) are allowed!");
        }
    };

    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }
    // const [columns, setColumns] = useState<any[]>([
    //     { header: 'Platform', field: 'platform', width: '130px' },
    //     { header: 'Platform Code', field: 'platform_code', bodyStyle, width: '130px' },
    //     { header: 'Brand', field: 'brand', width: '130px' },
    //     { header: 'Category', field: 'category', width: '150px' },
    //     { header: 'Product Name', field: 'product_name', width: '150px' },
    //     { header: 'Selling Price', field: 'selling_price', width: '130px' },
    //     { header: 'Weight(Gram)', field: 'weight', width: '130px' },
    // ]);


    const [columns, setColumns] = useState<any[]>([
        { header: 'Platform Code', field: 'platform_code', bodyStyle, width: '130px' },
        { header: 'Client Name', field: 'client_name', bodyStyle, width: '130px' },
        { header: 'cities', field: 'cities', width: '130px' },
        { header: 'pincodes', field: 'pincodes', width: '130px' },
        { header: 'Platform', field: 'platform', width: '130px' },
        { header: 'Name', field: 'pname', width: '130px' },
        { header: 'sp', field: 'sp', width: '150px' },
        { header: 'mrp', field: 'mrp', width: '150px' },
        { header: 'expiry_days', field: 'expiry_days', width: '150px' },
        { header: 'Main category', field: 'main_cat', width: '150px' },
        { header: 'Sub category', field: 'sub_cat', width: '130px' },
        { header: 'Pack Size', field: 'pack_size', width: '130px' },
        { header: 'Description', field: 'description', width: '130px' },
        { header: 'Ingredients', field: 'ingredients', width: '130px' },
    ]);
    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = [...firstColumn, ...columns].map(col => col.field).join(',');
    //     csvContent += header + '\n';

    //     performanceData.forEach((row: any) => {
    //         const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };

    const getBackgroundColorHeader2 = (header: string) => {
        const colorMap: Record<string, string> = {
            Blinkit: '#F6D75133',
            'Swiggy Instamart': '#FC801933',
            Zepto: '#950EDB33',
        };

        return colorMap[currentPlatform]

    };

    const rowExpansionTemplate = (data: any) => {
        return (
            //   <div style={{ padding: "1rem" }}>
            isLoadingSub ? <LoaderSkeletonLine /> :
                <DataTable value={competitionMaster}
                    size='small'
                    columnResizeMode="expand"
                    resizableColumns
                    onColumnResizeEnd={onColumnResizeEnd}
                    className='unq-tble'
                // showGridlines
                >
                    {columns.map((col) => (

                        <Column field={col.field}
                            // style={{ col.field? width: col.width }}
                            // style={{ width: col.field === 'pincodes' ? '128px' : col.width }}
                            style={{ width: col.header === 'Actions' ? '58px' : col.width }}
                            bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize', backgroundColor: isEditing ? '#FFEDED' : getBackgroundColorHeader2(col.header) }}
                        // headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                        // body={col.body}
                        ></Column>
                    ))}

                    <Column
                        header="Actions"
                        body={(rowData: any) => (
                            isEditing ? (
                                // <button
                                //     className="table_btn2"
                                //     onClick={() => handleDelete(rowData)}
                                //     style={{ cursor: "pointer", color: "red" }}
                                // >
                                //     Delete
                                // </button>
                                <i style={{ color: 'red', fontSize: '1rem', cursor: 'pointer' }} className="bi bi-dash-circle-fill table_btn2" onClick={() => handleDelete(rowData)} ></i>
                            ) : <div>-</div>
                        )}
                        style={{ width: "68px", textAlign: "center" }}
                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize', backgroundColor: isEditing ? '#FFEDED' : getBackgroundColorHeader2('') }}
                    />


                    {/* {isEditing && (
                        <Column
                            header="Actions"
                            bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize', backgroundColor: getBackgroundColorHeader2('') }}
                            body={(rowData: any) => (
                                <>
                                    { <button
                                  className="table_btn2"
                                  onClick={() => handleEdit(rowData)}
                                  style={{ cursor: "pointer", color: "blue" }}
                                >
                                  Edit
                                </button> }
                                    <button
                                        className="table_btn2"
                                        onClick={() => handleDelete(rowData)}
                                        style={{ cursor: "pointer", color: "red", marginLeft: "10px" }}

                                    >
                                        Delete
                                    </button>
                                </>
                            )}
                            style={{ width: "150px", textAlign: "center" }}
                        />
                    )} */}




                </DataTable>

        );
    };

    const onRowSelect = (e: any) => {
        setRowSelect(e.value);
        setExpandedRows([e.value]);
    };

    const onRowUnselect = () => {
        setRowSelect(null);
        setExpandedRows(null);
    };



    // Add this function to your component
    const exportToExcel = () => {
        setIsLoading(true); // Show loading state
        setIsModalOpendownload(true); // Show the download modal

        // Prepare data for export
        const exportData: any = [];

        // Process main table rows and their sub-tables
        productMaster.forEach((mainRow: any) => {
            // Add main row with an indicator
            const mainRowData = {
                ...mainRow,
                row_type: 'Main Product',
            };
            exportData.push(mainRowData);

            // Check if this row has sub-data (is expanded)
            if (expandedRows && expandedRows.some((row: any) => row.platform_code === mainRow.platform_code)) {
                // Find the matching sub-table data
                const subRows = competitionMaster.filter((subRow: any) =>
                    subRow.platform_code === mainRow.platform_code
                );

                // Add sub-rows with indentation and type indicator
                subRows.forEach((subRow: any) => {
                    exportData.push({
                        ...subRow,
                        row_type: 'Competition Product',
                        // Indent the name for visual hierarchy
                        pname: `    ${subRow.pname}`,
                    });
                });
            }
        });

        // Create Excel workbook
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Competition Master");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, `Competition_Master_${currentPlatform}_${new Date().toISOString().split('T')[0]}.xlsx`);

        setIsLoading(false);
        setIsModalOpendownload(false); // Close the modal after download
    };


    return (
        <div style={{ marginTop: '1rem' }} className=' table_card_new'>
            <div style={{ position: 'relative' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <span className='table_kpi_name'>Competetion Master </span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <button className='table_btn1' onClick={() => setIsModalOpendownload(true)}>
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                            </svg>
                        </button>
                        {isModalOpendownload && <>

                            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                    <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <LoaderAnimationfill />

                                    </div>
                                    <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '1rem' }}>You can download the file after progress is complete</p>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '.5rem' }}><button className="download-button" ><i className="bi bi-download"></i>&nbsp;&nbsp;Download Now</button></div>
                                    <div
                                        className="back-button"
                                        style={{ color: '#5595F6', cursor: 'pointer', }}
                                        onClick={() => setIsModalOpendownload(false)}
                                    >
                                        Back
                                    </div>
                                </div>

                            </div>
                        </>}
                        <button className='table_btn1' onClick={() => setIsModalOpen(true)}>
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z"
                                    fill="black"
                                    transform="scale(1, -1) translate(0, -16)" />
                            </svg>
                        </button>
                        {isModalOpen && <>

                            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                    <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <CloudUploadIcon />
                                    </div>
                                    <label
                                        htmlFor="file-upload"
                                        className="file-upload-label"
                                        style={{ border: '1.5px dashed #000000', borderRadius: '.5rem', padding: '.7rem .9rem', margin: '.7rem 0', textAlign: 'center', cursor: 'pointer', color: '#5B6871', fontSize: '.85rem' }}
                                    >
                                        Choose File
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept=".xls,.xlsx,.csv"
                                            className="file-upload-input"
                                            onChange={handleFileUpload}
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                    <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '.5rem' }}>Upload your Excel file to import data</p>
                                    <div
                                        className="back-button"
                                        style={{ color: '#5595F6', cursor: 'pointer', }}
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        Back
                                    </div>
                                </div>

                            </div>
                        </>}

                        <div style={{ position: 'relative' }}>
                            <button className='table_btn1' disabled={rowSelect ? false : true} style={{ cursor: rowSelect ? '' : 'auto', pointerEvents: rowSelect ? "auto" : "none", }}
                                onClick={() => {
                                    // setIsModalOpenEdit(true);
                                    isdropdownEdit === true ? setIsdropdownEdit(false) : setIsdropdownEdit(true);
                                    setIsEditing(false);
                                }}>
                                <i className="bi bi-pencil"></i>
                            </button>

                            {isdropdownEdit &&
                                <div style={{ position: 'absolute', right: '2rem', top: '-1rem', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '1rem', padding: '.5rem', display: 'flex', width: 'max-content', zIndex: 1000, }}>
                                    <div className="mt-0 " style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', gap: '.5rem' }}>
                                        <span style={{ color: '#5D38E0', fontSize: '.85rem', }}>Choose Option:</span>
                                        <button
                                            className="submit-btn"
                                            style={{ marginTop: '0px', marginRight: '.5rem', fontSize: '.7rem', backgroundColor: '#5D38E0', borderRadius: '.5rem' }}
                                            onClick={() => { setEditIconMode('add'); setIsModalOpenEdit(true); setIsdropdownEdit(false); }}
                                        >
                                            Add &nbsp;<i className="bi bi-plus-lg"></i>
                                        </button>
                                        <button
                                            className="submit-btn"
                                            style={{ marginTop: '0px', fontSize: '.7rem', backgroundColor: '#5D38E0', borderRadius: '.5rem' }}
                                            onClick={() => { setIsEditing(true); setIsModalOpenEdit(false); setIsdropdownEdit(false); }}
                                        >
                                            <i className="bi bi-pencil"></i>&nbsp;  Edit
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>


                        {isModalOpenEdit && <>
                            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '1rem 2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                    {/* <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <span className="loader"></span>
                                    </div> */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.5rem' }}>
                                        {editIconMode === 'add' ?
                                            <h2 style={{ textAlign: "center", marginBottom: "1rem", color: "#333", fontSize: '.9rem' }}>Add Competition</h2> : <div></div>
                                        }

                                    </div>



                                    {editIconMode === 'add' ?
                                        <div>


                                            {/* Platform Selection Dropdown */}


                                            {/* Searchable Input Field */}
                                            <input
                                                type="text"
                                                className="dropdown-search"
                                                placeholder="Add Platform Code of Search Product Name"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                onFocus={() => setIsDropdownVisible(true)}
                                                onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)}
                                                style={{ padding: "0.5rem", borderRadius: "5px", width: "100%", border: "1px solid #ccc", fontSize: '.8rem' }}
                                            />

                                            {/* Filtered Platform List */}
                                            {
                                                (isDropdownVisible &&
                                                    <div className="dropdown-list" style={{ backgroundColor: "#fff", border: "1px solid #ccc", fontSize: '.8rem', borderRadius: "5px", marginTop: "0.5rem", maxHeight: "150px", maxWidth: '350px', overflowY: "auto" }}>
                                                        {filteredPlatforms.length > 0 ? (
                                                            filteredPlatforms.map((platform: any) => (
                                                                <div key={platform} onMouseDown={() => handlePlatformSelect(platform)} className="dropdown-item" style={{ fontSize: '.8rem', padding: "0.5rem", cursor: "pointer", textAlign: "left" }}>
                                                                    <img src={platform?.img} alt={platform?.name} style={{ width: "30px", height: "30px", borderRadius: "5px" }} />
                                                                    <span>{platform?.name} (<b>sku:</b> {platform?.sku})</span>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="dropdown-item" style={{ color: "#999", backgroundColor: "#f8f9fa", padding: "8px", textAlign: "center", fontStyle: "italic" }}>
                                                                No Product found.
                                                            </div>
                                                        )}

                                                    </div>

                                                )}


                                            {/* Selected Platforms List */}
                                            <div className="selected-platforms" style={{ marginTop: "1rem", maxHeight: "150px", overflowY: "auto" }}>
                                                {selectedPlatforms.length > 0 ? (
                                                    selectedPlatforms.map((platform, index) => (
                                                        <div key={index} className="selected-platform-item" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: '.8rem', padding: "0.5rem", backgroundColor: "#f1f1f1", borderRadius: "5px", marginBottom: "0.5rem" }}>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                                <img src={platform?.img} alt={platform?.name} style={{ width: "30px", height: "30px", borderRadius: "5px" }} />
                                                                <span>{platform?.name} (<b>sku:</b> {platform?.sku})</span>
                                                            </div>
                                                            <i className="bi bi-x" style={{ cursor: "pointer", color: "red" }} onClick={() => removePlatform(index)}></i>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p style={{ fontSize: "0.85rem", color: "#777" }}>No Product selected.</p>
                                                )}
                                            </div>


                                            {/* Submit & Cancel Buttons */}
                                            <div style={{ display: "flex", justifyContent: "left", gap: "1rem", marginTop: "1.5rem", alignItems: 'baseline' }}>
                                                <button className="submit-btn" style={{ fontSize: "0.85rem", padding: "0.5rem 1rem", backgroundColor: "#5D38E0", borderRadius: "5px", color: "#fff" }} onClick={handleSubmit}>
                                                    Submit
                                                </button>
                                                <button className="cancel-btn submit-btn" style={{ fontSize: "0.85rem", padding: "0.5rem 1rem", backgroundColor: "#ccc", borderRadius: "5px", color: "#333" }} onClick={() => { setIsModalOpenEdit(false); setEditIconMode(null); }}>
                                                    Cancel
                                                </button>
                                                {/* <span style={{ fontSize: '.75rem', cursor: 'pointer' }}>Bulk Upload?</span> */}
                                            </div>

                                        </div> : 'edit logic here'
                                    }



                                </div>

                            </div>
                        </>}


                    </div>
                </div>

                <PrimeReactProvider>
                    {
                        isLoading ?
                            <LoaderSkeletonTable height='350px' /> :

                            <DataTable
                                value={productMaster}
                                size='small'
                                columnResizeMode="expand"
                                resizableColumns
                                onColumnResizeEnd={onColumnResizeEnd}
                                selectionMode="single"
                                selection={rowSelect}
                                onSelectionChange={(e) => {
                                    e.value ? onRowSelect(e) : onRowUnselect();
                                }}
                                expandedRows={expandedRows}
                                onRowToggle={(e) => setExpandedRows(e.data)}
                                rowExpansionTemplate={rowExpansionTemplate}
                                showGridlines
                                id='unique-table'
                                className='unique-tble'
                                scrollable scrollHeight="350px"
                            >
                                {columns.map((col, index) => {
                                    return (
                                        <Column
                                            key={index}
                                            field={col.field}
                                            header={col.header}
                                            // header={renderHeader(col.header, col.field)}
                                            // style={{ width: '150px' }}
                                            style={{ width: col.width }}
                                            headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                            bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                            // bodyStyle={column?.bodyStyle}
                                            body={col.body}
                                        />
                                    )
                                }
                                )}

                                <Column
                                    header="Actions"
                                    headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                    body={(rowData: any) => (
                                        isEditing ? (
                                            <div>-</div>
                                        ) : <div>-</div>
                                    )}
                                    style={{ width: "70px", textAlign: "center" }}
                                />

                            </DataTable>
                    }

                </PrimeReactProvider>
            </div>
        </div>
    )
}

export default CompetitionMasterTable

