import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import AppLayout from './appLayout/AppLayout';
import ManagementDetails from './components/userManagement/components/ManagementDetails';
import MyAccount from './components/userManagement/components/MyAccount';
import Approvals from './components/userManagement/pages/Approvals';
import OnboardBrand from './components/userManagement/pages/OnboardBrand';
import { useAppDispatch, useAppSelector } from './redux/hook';

import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/auth/pages/Login';
import Signup from './components/auth/pages/Signup2';
import ForgetPassword from './components/auth/pages/ForgetPassword';
import Otp from './components/auth/pages/Otp';
import ProductMaster from './components/masters/pages/ProductMaster';
import ProtectedRoute from './utils/ProtectedRoute';
import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL_NEW } from './appConstant';
import { brandDetails } from './redux/slices/clientSlice';
import DailyReport from './components/reports/pages/DailyReport';
import MonthlyBudgets from './components/monthlyTargets/pages/MonthlyBudgets';
import Achievements from './components/reports/pages/Achievements';
import SellerMaster from './components/masters/pages/SellerMaster';
import KeywordCategorization from './components/tools/pages/KeywordCategorization';
import KeywordTypeReport from './components/tools/pages/KeywordTypeReport';
import MarketingStreamPage from './components/reports/pages/MarketingStreamPage';
import RetailReadiness from './components/reports/pages/RetailReadiness';
import ProductStatus from './components/reports/pages/ProductStatus';
import PublicRoute from './utils/PublicRoute';
import TotalSales from './components/reports/pages/TotalSales';
import HourlyReport from './components/reports/pages/HourlyReport';
import Subscription from './components/userManagement/pages/Subscription';
import PerformanceByPlacement from './components/reports/pages/PerformanceByPlacement';
import ProductAssortment from './components/tools/pages/ProductAssortment';
import AMC from './components/tools/pages/amc'
import ProductStatusQcom from './components/reports/pages/ProductStatusQcom';
import MonitoringSystem from './components/tools/pages/MonitoringSystem';
import BrandOnboardingModule from './components/reports/components/brandOnboarding/BrandOnboarding';
import CompetitiveMaster from './components/reports/pages/CompetitiveMaster';
import BrandsOnboarded from './components/reports/components/brandOnboarding/BrandsOnboarded';
import StatusScreen from './components/reports/components/brandOnboarding/StatusScreen';
import ProductHarmonization from './components/reports/pages/ProductHarmonization';
import ProductMasterNew from './components/reports/pages/ProductMasterNew';
import Powerbi from './components/reports/components/powerbi/Powerbi';
import PowerBiReports from './components/reports/pages/PowerBiReports';
import Login2 from './components/auth/pages/Login2';
import ForgetPassword2 from './components/auth/pages/ForgotPassword2';
import Otp2 from './components/auth/pages/Otp2';
import SignupMode from './components/auth/pages/SignupMode';
import SignupBrandOwner from './components/auth/pages/SignupBrandOwner';
import OtpVerification from './components/auth/pages/OtpVerification';
import PasswordChange from './components/auth/pages/PasswordChange';
import Signup2 from './components/auth/pages/Signup2';
import { GoogleOAuthProvider } from '@react-oauth/google';
import InviteSignup from './components/auth/pages/InviteSignup';
import HomeSelectionScreen from './components/userManagement/HomeSelectionScreen';
import HistoryAMC from './components/tools/components/amc/HistoryAMC';
import ReportSchedulingAMC from './components/tools/components/amc/ReportSchedulingAMC';
import AMCaudience from './components/tools/components/amc/audience/AMCaudience';
import AudienceHistory from './components/tools/components/amc/audience/AudienceHistory';
import AudienceQueryView from './components/tools/components/amc/audience/AudienceQueryView';
import SearchCrawler from './components/tools/crawler/Search';
import BrandOnboardingModulenew from './components/reports/components/brandOnboarding/brandOnboardingnewUI';






const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user)
  // const brands = useAppSelector(state => state.client)
  // console.log(user, brands);
  const { isAuthenticated } = useAppSelector(state => state.auth)
  const navigate=useNavigate();
  // console.log(isAuthenticated);
  // console.log(user);
  // console.log(brands);




  //yogi remove this quick fix later pls
  const demoBrand = { 
    client_id: "demo_brand", 
    name: "Demo Brand", 
    agency: "Demo Agency" 
  }; // Define a default demo brand




  useEffect(() => {



    if (!user?._id) return; // Ensure user is available



    axios.get(`${BASE_URL_NEW}/user/${user?._id}/userBrands`)
      .then((res) => {

        let brand_array = res.data;
        let current_brand = brand_array[0];
        if (brand_array?.length) {
          let currentBrandInLocal = localStorage.getItem("currentBrand");
          if (currentBrandInLocal) {
            for (let b of brand_array) {
              if (b.client_id === currentBrandInLocal) {
                current_brand = b;
                break;
              }
            }
          }
        }else{
          // Assign demo brand if no real brand is found
        brand_array = [demoBrand];
        current_brand = demoBrand;
        }
        dispatch(brandDetails({ brand_array: brand_array, current_brand: current_brand, current_agency: current_brand?.agency }));



        // //remove later
        // let currentBrandInLocal = localStorage.getItem("currentBrand");
        // if (!currentBrandInLocal && isAuthenticated) {
        //   navigate("/home/brandOnboarding");
        // }
        // //

        



      }).catch((error) => {
        console.log(error);


        //also remove this logic yogi this is temporary fix 
        if(isAuthenticated){
          // Assign demo brand if no real brand is found
        let brand_array = [demoBrand];
        let current_brand = demoBrand;
        
        dispatch(brandDetails({ brand_array: brand_array, current_brand: current_brand, current_agency: current_brand?.agency }));
        }
      })
  }, [user])


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to={isAuthenticated ? "/home/brandOnboarding" : "/login"} />} />

        {/* Public Routes */}

        <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/login" element={
            <GoogleOAuthProvider clientId='93503779962-kje5ce9637u8g0c20e26h15285rp4cgs.apps.googleusercontent.com'>
              <Login2 />
            </GoogleOAuthProvider>
            } />
          {/* <Route path="signup" element={<Signup />} /> */}
          <Route path="signup" element={<SignupBrandOwner/>} />
          <Route path="/:userId" element={<InviteSignup/>} />
          <Route path="signup2" element={<Signup2/>} />
          <Route path="signup-mode" element={<SignupMode />} />
          {/* <Route path="forgetPassword" element={<ForgetPassword />} /> */}
          <Route path="forgetPassword" element={<ForgetPassword2/>} />
          {/* <Route path="verifyOtp" element={<Otp />} /> */}
          <Route path="verifyOtp" element={<Otp2/>} />
          <Route path="password-change" element={<PasswordChange/>} />
          <Route path="verifySignupOtp" element={<OtpVerification/>} />
        </Route>


        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          <Route path='reports' element={<AppLayout />}>
            <Route path='dailyReports' element={<DailyReport />} />
            {/* <Route path='amc' element={<AMC />} /> */}
            <Route path='powerbi' element={<PowerBiReports />} />
            <Route path='powerbi/:id' element={<Powerbi />} />
            <Route path='performancebyPlacement' element={<PerformanceByPlacement />} />
            <Route path='achievements' element={<Achievements />} />
            <Route path='marketingStream' element={<MarketingStreamPage />} />
            <Route path='hourlyReport' element={<HourlyReport />} />
            <Route path='retailReadiness' element={<RetailReadiness />} />
            {/* <Route path='productStatus' element={<ProductStatus/>} /> */}
            {/* <Route path='competitivemaster' element={<CompetitiveMaster/>} />  */}
            <Route path='totalSales' element={<TotalSales />} />
          </Route>

          <Route path='Qcom' element={<AppLayout />}>
            <Route path='productStatus' element={<ProductStatusQcom />} />
            <Route path='productMaster' element={<ProductMasterNew />} />
            <Route path='competitivemaster' element={<CompetitiveMaster />} />
            <Route path='productHarmonization' element={<ProductHarmonization />} />
          </Route>

          {/* <Route path='automation' element={<Home />}>
              <Route path='dailyActions' element={<BidChangesContainer />} />
              <Route path='dailyTargets' element={<DailyTargets />} />
              <Route path='pausedTargets' element={<PausedBid />} />
              <Route path='hourlyBids' element={<HourlyBids />} />
            </Route> */}
          <Route path='budget' element={<AppLayout />}>
            <Route path='monthlyBudget' element={<MonthlyBudgets />} />
            {/* <Route path='monthlyDistribution' element={ } /> */}
          </Route>
          <Route path='master' element={<AppLayout />}>
            {/* <Route path='productMaster' element={<ProductMaster />} /> */}
            <Route path='sellerMaster' element={<SellerMaster />} />
          </Route>
          <Route path='tools' element={<AppLayout />}>
            <Route path='keywordCategorization' element={<KeywordCategorization />} />
            <Route path='keywordTypeReport' element={<KeywordTypeReport />} />
            <Route path='monitoringSystem' element={<MonitoringSystem />} />
            <Route path='productAssortment' element={<ProductAssortment/>} />
            <Route path='amc' element={<AMC />} />
            <Route path='amc/history' element={<HistoryAMC/>} />
            <Route path='amc/report-scheduling' element={<ReportSchedulingAMC/>} />
            <Route path='amc-audience' element={<AMCaudience/>} />
            <Route path='amc-audience/history' element={<AudienceHistory/>} />
            <Route path='amc-audience/query' element={<AudienceQueryView/>} />
            <Route path='search-crawler' element={<SearchCrawler/>} />


            {/* <Route path='searchVolume' element={<SearchVolume />} />
              <Route path='keywordAsinIndex' element={<KeywordIndexing />} />
              <Route path='visibilityChecker' element={<Visibility />} /> */}
          </Route>
          {/* <Route path='benchmarks' element={<Home />}>
              <Route path='pastTrends' element={<BenchmarkContainer />} />
            </Route> */}
          {/* <Route path='campaignManager' element={<Home />}>
              <Route path='spCampaigns' element={<SPCampaigns />} />
              <Route path='addTargets' element={<AddTarget />} />
              <Route path='negativeTargets' element={<NegativeTargets />} />
            </Route> */}

          <Route path="home" element={<AppLayout />} >
            <Route path='myAccount' element={<MyAccount />} />
            <Route path='subscription' element={<Subscription />} />
            <Route path='details' element={<ManagementDetails />} />
            <Route path='onboard' element={<OnboardBrand />} />
            <Route path='brandOnboarding' element={<BrandOnboardingModule />} />
            {/* <Route path='brandOnboarding' element={<BrandOnboardingModulenew/>} /> */}
            <Route path='brandsOnboarded' element={<BrandsOnboarded />} />
            <Route path='brandsOnboarded/StatusScreen/:id' element={<StatusScreen />} />
            <Route path='approvals' element={<Approvals />} />
            <Route path='selectionScreen' element={<HomeSelectionScreen/>} />
          </Route>
        </Route>
        {/* <Route path="loading" element={<Loader />} />
        <Route path="inactiveUser" element={<InactiveUserPage />} />
        <Route path='*' element={'Not found'} /> */}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
      />
    </div >
  );
}

export default App;
