import axios from 'axios';
import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver'

const TableZero = ({ setClickedHour, setClickedKPI }: any) => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client
    const [rowData, setRowData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelect, setRowSelect] = useState<any[]>([]);


    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/comparative_data_hourly`)
            .then((res) => {
                res?.data?.forEach((row: any) => row.hour += 1)
                // console.log(res.data);
                
                setRowData(res.data);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
    }, [client_id])


    const headers = [
        { field: 'hour', header: 'Hour' },
        { field: 'cost', header: 'Spend' },
        { field: 'impressions', header: 'Impressions' },
        { field: 'clicks', header: 'Clicks' },
        { field: 'attributedSales14d', header: 'Sales' },
        { field: 'attributedConversions14d', header: 'Orders' },
        { field: 'attributedUnitsOrdered14d', header: 'Units' },
        { field: 'acos', header: 'ACOS' },
        { field: 'roas', header: 'ROAS' },
        { field: 'cpc', header: 'CPC' },
        { field: 'ctr', header: 'CTR' },
        { field: 'cvr', header: 'CVR' },
        { field: 'aov', header: 'AOV' },
    ]


    const isRowDataEmpty = rowData.every(item => Object.keys(item).length === 0);

    const onRowSelect = (e: any) => {
        setClickedKPI(null)
        setRowSelect(e.value);
        setClickedHour(e.value)
    }

    const exportToCSV = () => {
        let csvContent = '';
        const headerLine = headers.map(({ header }) => header).join(',');
        csvContent += headerLine + '\n';

        rowData.forEach((row: any) => {
            const rowData = headers.map(col => {
                return row[col?.field]
            }).join(',');
            csvContent += rowData + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'cumulative_hourly_report_upto(hour).csv');
    };

    return (
        <div style={{ marginTop: '1rem' }} className='table_card_new'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
                    <span className='table_kpi_name'>Cumulative Hourly Report Upto (Hour)</span>
                </div>
            </div>
            <PrimeReactProvider>
                {isLoading ? (<LoaderSkeletonTable height='100px' />
                ) : isRowDataEmpty ? (
                    <div
                        style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}
                    >
                        No Data Available
                    </div>
                ) : (

                    <DataTable
                        value={rowData}
                        size='small'
                        columnResizeMode="expand"
                        resizableColumns
                        scrollable scrollHeight="150px"
                        selectionMode="single"
                        selection={rowSelect}
                        onSelectionChange={onRowSelect}
                        sortIcon="bi bi-chevron-expand stroke"
                        // dataKey='hour'
                    >
                        {rowData.length > 0 &&
                            headers.map((el) => (
                                <Column key={el.field} field={el?.field} header={el?.header}
                                    style={{ width: '100px' }}
                                    sortable
                                    headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                    bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                                />
                            ))
                        }
                    </DataTable>
                )}
            </PrimeReactProvider>
        </div>
    );
}

export default TableZero