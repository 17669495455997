import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import './authStyle.scss';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import { toast } from "react-toastify";
import ImagePlaceholder from "../../../assets/svgs/ImagePlaceholder";
import EnlyticalImg from "../../../assets/enlyticalImg.png"
import { BASE_URL_NEW } from "../../../appConstant";
import { useAppDispatch } from "../../../redux/hook";
import { userDetails } from "../../../redux/slices/userSlice";
import { login } from "../../../redux/slices/authSlice";


const Otp2: React.FC = () => {
    let navigate = useNavigate();


    const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
    const [isVerified, setIsVerified] = useState<boolean | null>(null);
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const [email,setEmail]=useState<string | null>(null)

    const [timer, setTimer] = useState(30);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleChange = (index: number, value: string) => {
        if (!/^[0-9]?$/.test(value)) return; // Only allow numbers

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move focus to next field if available
        if (value && index < 5) {
            inputRefs.current[index + 1]?.focus();
        }

        // If all 6 digits are entered, check OTP
        if (newOtp.join("").length === 6) {
            verifyOTP(newOtp.join(""));
        }
    };


    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    // useEffect(() => {
    //     const loginEmail = localStorage.getItem("email");
    //     console.log('loginEmail',loginEmail)
    //     setState(prevState => ({
    //         ...prevState,
    //         email: loginEmail
    //     }));
    // }, []);

   
    const handleResendOTP = async () => {
        if (!isDisabled) {
            setIsDisabled(true); // Disable button after clicking
            setTimer(30); // Reset timer
    
            try {
                const response = await axios.post(`${BASE_URL_NEW}/auth/forgot-password`, {
                    email: email,
                });
    
                if (response.status === 200 ||response.status === 201) {
                    toast.success("OTP Resent Successfully!");
                    console.log("OTP Resent!");
                } else {
                    toast.error(`Error ${response.status}: Failed to resend OTP`);
                }
            } catch (error:any) {
                const status = error.response?.status;
                const message = error.response?.data?.error || error.message || "Something went wrong";
    
                toast.error(`Error ${status}: ${message}`);
                console.error(`Error ${status}: ${message}`);
            }
        }
    };

    useEffect(() => {
        const loginEmail = localStorage.getItem("email");
            setEmail(loginEmail)
    }, []);
    
    // const verifyOTP = (enteredOtp: string) => {
    //     setTimeout(() => {
    //         if (enteredOtp === DUMMY_OTP) {
    //             setIsVerified(true);
    //         } else {
    //             setIsVerified(false);
    //         }
    //     }, 500);
    // };
    const verifyOTP = async (enteredOtp: string) => {
        try {
            console.log('otp in otp 2',{email,otp: enteredOtp.toString()})
            const response = await axios.post(`${BASE_URL_NEW}/auth/verify-otp`, {
                email,otp: enteredOtp.toString()
            });
    
            if (response.status === 201 ) {
                setIsVerified(true);
                toast('verification done otp');
            } else {
                setIsVerified(false);
                // toast.error("Invalid OTP. Please try again.");
            }
        } catch (error:any) {
            setIsVerified(false);
            toast.error(error.response?.data?.message || "OTP verification failed");
        }
    };

    useEffect(() => {
        let countdown: NodeJS.Timeout;
        if (isDisabled) {
            countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(countdown);
                        setIsDisabled(false); // Enable button after 30 sec
                        return 30; // Reset timer
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdown); // Cleanup
    }, [isDisabled]);


    return (
        <div className="login">
            <div className="login-left">
                <div className="login-loginContainer">
                 <div style={{display:'flex', flexDirection:'column',gap:'6.5rem'}}>

                    <div>
                    <div style={{ display: "flex", justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }} className="login-loginContainerHeader" >
                        <div><img src={EnlyticalLogo} alt="logo" height={"45px"} /></div>
                    </div>

                    <div className="login-loginContainerHeader">
                        <div><h4 className="mb-1">Sign Up</h4></div>
                        <div className="Small-login-text">Complete Your Profile</div>
                    </div>
                    </div>


                    <div className="otp-container" style={{marginTop:'0rem'}}>
                        <div className="otp-message">
                            An authentication code has been sent to your Email at <strong>{email}</strong>
                        </div>

                        <div className="otp-input-container">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    ref={(el) => (inputRefs.current[index] = el!)}
                                    type="text"
                                    maxLength={1}
                                    className={`otp-input ${isVerified === true ? "success" : isVerified === false ? "error" : ""}`}
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    disabled={isVerified === true}
                                />
                            ))}
                        </div>

                        <div className="otp-prompt">Enter 6 Digit OTP!</div>

                        {isVerified !== null && (
                <div className={`otp-status ${isVerified ? "otp-success" : "otp-fail"}`}>
                    {isVerified ? "OTP Verified Successfully!" : "Incorrect OTP! Please check again."}
                </div>
            )}


                    {!isVerified &&
                        <div className="resend-otp"  >
                            Didn’t get the OTP? <span onClick={handleResendOTP} style={{ color: isDisabled ? "#aaa" : "#007bff", cursor: isDisabled ? "not-allowed" : "pointer" }}>Resend it {isDisabled && <span>{timer} s </span>}
                            </span>
                        </div>
                        }
                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem', marginTop: '0rem' }}>
                        <div>
                            <button
                                className="login-btn"
                                id="signup-btn"
                                type="button"
                             // onClick={handleSubmit}
                                disabled={!isVerified}
                                onClick={()=>navigate('/password-change')}
                            >
                                Next
                            </button>
                        </div>
                        
                    </div>

                 </div>
                </div>
            </div>

            <div className="login-right">
                <div className="right-text">
                    <h2 className="mb-0">
                        <strong>AI enabled </strong>Growth
                    </h2>
                    <h2 className="mb-0">We make E-commerce Faster,</h2>
                    <h2 className="mb-0">Simpler and Better</h2>
                </div>
                <div className="right-content">
                    {/* <div className="right-text">Your command center for tracking, managing, and optimizing all aspects of your business. Whether you're overseeing projects, monitoring performance metrics, or collaborating with your team, Enlytical.ai provides you with the tools and insights you need to stay ahead of the curve.</div> */}
                    <div className="main-enlytical-img-container"><img src={EnlyticalImg} alt="img" className="main-enlytical-img" /></div>
                </div>

            </div>
        </div>
    );
};

export default Otp2;

