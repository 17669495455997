import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WidthFull } from '@mui/icons-material';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { TreeTable } from 'primereact/treetable';
import CloudUploadIcon from '../../../../assets/svgs/CloudUploadIcon';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ENLYTICAL_PROCESSING_BASE_URL, BASE_URL } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import * as XLSX from "xlsx";


const ProductMasterTable = ({ currentPlatform, rowSelect, setRowSelect }: { currentPlatform: any, rowSelect: any, setRowSelect: any }) => {
    const client = useAppSelector(state => state.client);
    const { current_brand } = client
    const [productMaster, setProductMaster] = useState([])
    const [expandedProductMaster, setExpandedProductMaster] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [expandedRows, setExpandedRows] = useState<any>(null);

    const [isModalOpendownload, setIsModalOpendownload] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [isModalOpenEditsku, setIsModalOpenEditsku] = useState(false);


    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [SKUentries, setSKUentries] = useState<string[]>(['']);
    const [bulkUpload, setBulkUpload] = useState(false);


    useEffect(() => {
        setIsLoading(true)
        axios.get(`${BASE_URL}/qcomm/product-master?client_name=${current_brand?.client_name?.toLowerCase()}&platform=${currentPlatform}`,
        ).then((res) => {
            res.data.forEach((master: any) => {
                master.sp = `${master.max_sp}-${master.min_sp}`
                master.mrp = `${master.max_mrp}-${master.min_mrp}`
                master.expiry_days = `${master.max_expiry_days}-${master.min_expiry_days}`
            })
            setProductMaster(res.data)
        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
        })

        setRowSelect(undefined)
    }, [currentPlatform, current_brand])

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
        if (file && allowedExtensions.exec(file.name)) {
            setUploadedFile(file);
            setIsModalOpen(false); // Close modal after upload
        } else {
            toast("Only Excel files (.xls, .xlsx, .csv) are allowed!");
        }
    };

    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }
    const [columns, setColumns] = useState<any[]>([
        { header: 'Platform Code', field: 'platform_code', bodyStyle, width: '130px' },
        { header: 'Platform', field: 'platform', width: '130px' },
        { header: 'Name', field: 'pname', width: '130px' },
        { header: 'sp', field: 'sp', width: '150px' },
        { header: 'mrp', field: 'mrp', width: '150px' },
        { header: 'expiry_days', field: 'expiry_days', width: '150px' },
        { header: 'Main category', field: 'main_cat', width: '150px' },
        { header: 'Sub category', field: 'sub_cat', width: '130px' },
        { header: 'cities', field: 'cities', width: '130px' },
        { header: 'pincodes', field: 'pincodes', width: '130px' },
        { header: 'Pack Size', field: 'pack_size', width: '130px' },
        { header: 'Description', field: 'description', width: '130px' },
        { header: 'Ingredients', field: 'ingredients', width: '130px' },
    ]);

    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = [...firstColumn, ...columns].map(col => col.field).join(',');
    //     csvContent += header + '\n';

    //     performanceData.forEach((row: any) => {
    //         const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };

    const getBackgroundColorHeader2 = (header: string) => {
        const colorMap: Record<string, string> = {
            Blinkit: '#F6D75133',
            Instamart: '#FC801933',
            Zepto: '#950EDB33',
            'Swiggy Instamart': '#FC801933'
        };

        return colorMap[currentPlatform]

    };

    // const rowExpansionTemplate = (data: any) => {
    //     return (
    //         //   <div style={{ padding: "1rem" }}>
    //         <DataTable value={expandedProductMaster}
    //             size='small'
    //             columnResizeMode="expand"
    //             resizableColumns
    //             className='unq-tble'
    //         // showGridlines
    //         >
    //             {columns.map((col) => (

    //                 <Column field={col.field}
    //                     style={{ width: col.width }}
    //                     bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize', backgroundColor: getBackgroundColorHeader2(col.header) }}
    //                 // headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
    //                 // body={col.body}
    //                 ></Column>
    //             ))}
    //         </DataTable>
    //     );
    // };

    const onRowSelect = (e: any) => {
        // setIsLoading(true)
        // axios.get(`${BASE_URL}/qcomm/product-master?client_name=${'taali'}&platform=${currentPlatform}&asin=${e.value.platform_code}`,
        // ).then((res) => {
        //     res.data.forEach((master:any)=>{
        //         master.sp = `${master.max_sp}-${master.min_sp}`
        //         master.mrp = `${master.max_mrp}-${master.min_mrp}`
        //     })
        //     setExpandedProductMaster(res.data)
        // }).catch((err) => {
        //     console.log(err);
        //     setError(err.message)
        // }).finally(() => {
        //     setIsLoading(false);
        // })

        setRowSelect(e.value);
        setExpandedRows([e.value]);
    };

    const onRowUnselect = () => {
        setRowSelect(null);
        setExpandedRows(null);
    };






    const addSKUEntry = () => {
        setSKUentries([...SKUentries, ""]);
    };

    // Remove a user entry
    const removeSKUEntry = (index: number) => {
        setSKUentries(SKUentries.filter((_, i) => i !== index));
    };

    const updateSKUentry = (index: number, value: string) => {
        setSKUentries((prev) => prev.map((entry, i) => (i === index ? value : entry)));
    };

    // Handle File Upload  sku codes (Read Excel File)
    const handleFileUploadSKU = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;


        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Read first sheet
            const sheet = workbook.Sheets[sheetName];
            const rows = XLSX.utils.sheet_to_json<{ SKU?: string }>(sheet, { header: 1 });

            // Extract SKU values from column (Assumes SKU column exists)
            // const skus = rows.slice(1).map((row: any) => (Array.isArray(row) ? row[0]?.toString() : "")).filter(Boolean);

            const skus = rows.slice(1).map((row: any) => (Array.isArray(row) && row[0] ? row[0].toString().trim() : null))
                         .filter((sku): sku is string => !!sku);

            setSKUentries((prev) => [...skus]);
        };

        reader.readAsArrayBuffer(file);
    };

    // Download Sample Template
    const downloadSampleFilesku = () => {
        const ws = XLSX.utils.aoa_to_sheet([["SKU"]]); // Header Only
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sample");
        XLSX.writeFile(wb, "SKU_Sample_Template.xlsx");
    };

    // Handle API submission
    const handleSubmit = () => {

        if (SKUentries.length === 1 && SKUentries[0] === '') {
            toast('Enter least 1 SKU entry');
            return;
        }
        console.log("Submitting SKU entries:", SKUentries);
        // API call logic here...
    };


    return (
        <div style={{ marginTop: '1rem' }} className=' table_card_new'>
            <div style={{ position: 'relative' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <span className='table_kpi_name'>Product Master</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <button className='table_btn1' onClick={() => setIsModalOpendownload(true)}>
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                            </svg>
                        </button>
                        {isModalOpendownload && <>

                            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                    <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <span className="loader"></span>
                                    </div>
                                    <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '1rem' }}>You can download the file after progress is complete</p>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '.5rem' }}><button className="download-button" ><i className="bi bi-download"></i>&nbsp;&nbsp;Download Now</button></div>
                                    <div
                                        className="back-button"
                                        style={{ color: '#5595F6', cursor: 'pointer', }}
                                        onClick={() => setIsModalOpendownload(false)}
                                    >
                                        Back
                                    </div>
                                </div>

                            </div>
                        </>}
                        <button className='table_btn1' onClick={() => setIsModalOpen(true)}>
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z"
                                    fill="black"
                                    transform="scale(1, -1) translate(0, -16)" />
                            </svg>
                        </button>
                        {isModalOpen && <>

                            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                    <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <CloudUploadIcon />
                                    </div>
                                    <label
                                        htmlFor="file-upload"
                                        className="file-upload-label"
                                        style={{ border: '1.5px dashed #000000', borderRadius: '.5rem', padding: '.7rem .9rem', margin: '.7rem 0', textAlign: 'center', cursor: 'pointer', color: '#5B6871', fontSize: '.85rem' }}
                                    >
                                        Choose File
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept=".xls,.xlsx,.csv"
                                            className="file-upload-input"
                                            onChange={handleFileUpload}
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                    <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '.5rem' }}>Upload your Excel file to import data</p>
                                    <div
                                        className="back-button"
                                        style={{ color: '#5595F6', cursor: 'pointer', }}
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        Back
                                    </div>
                                </div>

                            </div>
                        </>}

                        <button className='table_btn1' onClick={() => setIsModalOpenEdit(true)} >
                            <i className="bi bi-pencil"></i>
                        </button>
                        {isModalOpenEdit && <>
                            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                    <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <span className="loader"></span>
                                    </div>
                                    <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '1rem' }}>Redirecting You to Excel Sheet</p>
                                    <div
                                        className="back-button"
                                        style={{ color: '#5595F6', cursor: 'pointer', }}
                                        onClick={() => setIsModalOpenEdit(false)}
                                    >
                                        Back
                                    </div>
                                </div>

                            </div>
                        </>}

                        <button className='table_btn1' onClick={() => setIsModalOpenEditsku(true)} >
                            <i className="bi bi-plus-lg"></i>
                        </button>
                        {isModalOpenEditsku && <>
                            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>

                                    <b> <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '0rem' }}>ADD SKU</p></b>

                                    {/* <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <span className="loader"></span>
                                    </div> */}

                                    {!bulkUpload ? (
                                        <>

                                            {SKUentries.map((entry, entryIndex) => (
                                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                                                    <div>
                                                        <input
                                                            type="sku"
                                                            className="input-field"
                                                            placeholder="Add SKU/URL"
                                                            value={entry || ""}
                                                            onChange={(e) => updateSKUentry(entryIndex, e.target.value)}
                                                        />
                                                    </div>


                                                    {SKUentries.length > 1 && (
                                                        <div className="remove-btn" onClick={() => removeSKUEntry(entryIndex)}>Remove</div>
                                                    )}
                                                </div>
                                            ))}


                                            <div className='add-more-btn' onClick={addSKUEntry}>
                                                Add more +
                                            </div>
                                        </>
                                    ) : (


                                        <>
                                            {/* File Upload Section */}
                                            <div style={{display:'flex' ,flexDirection:'column', justifyContent:'center', alignItems:'center',marginTop:'.5rem' }}>
                                               <input type="file" accept=".xlsx, .xls"  style={{display:'flex'}} onChange={handleFileUploadSKU} />
                                               <span style={{ marginTop: "10px", fontSize:'.85rem', cursor:'pointer' }} onClick={downloadSampleFilesku}> Download Sample File</span>
                                            </div>
                                        </>
                                    )}



                                    <div className="mt-4 " style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', gap: '.5rem' }}>
                                        <button
                                            className="submit-btn"
                                            style={{ marginRight: '.5rem', fontSize: '.75rem' }}
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            className="submit-btn"
                                            style={{ fontSize: '.75rem' }}
                                            onClick={() => {setSKUentries(['']);  setIsModalOpenEditsku(false)}}
                                        >
                                            Cancel
                                        </button>
                                        <span style={{ color: "#06486A", cursor: "pointer", fontSize: ".75rem" }} onClick={() => setBulkUpload(!bulkUpload)}> &nbsp; {bulkUpload ? "Manual Entry?" : "Bulk Upload?"} </span>
                                    </div>



                                </div>

                            </div>
                        </>}


                    </div>
                </div>

                <PrimeReactProvider>
                    {
                        isLoading ?
                            <LoaderSkeletonTable height='350px' /> :

                            <DataTable
                                value={productMaster}
                                size='small'
                                columnResizeMode="expand"
                                resizableColumns
                                selectionMode="single"
                                selection={rowSelect}
                                onSelectionChange={(e) => {
                                    e.value ? onRowSelect(e) : onRowUnselect();
                                }}
                                expandedRows={expandedRows}
                                onRowToggle={(e) => setExpandedRows(e.data)}
                                // rowExpansionTemplate={rowExpansionTemplate}
                                showGridlines
                                id='unique-table'
                                className='unique-tble'
                                scrollable scrollHeight="350px"
                            >
                                {columns.map((col, index) => {
                                    return (
                                        <Column
                                            key={index}
                                            field={col.field}
                                            header={col.header}
                                            // header={renderHeader(col.header, col.field)}
                                            // style={{ width: '150px' }}
                                            style={{ width: col.width }}
                                            headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                            bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                            // bodyStyle={column?.bodyStyle}
                                            body={col.body}
                                        />
                                    )
                                }

                                )}
                            </DataTable>
                    }

                </PrimeReactProvider>
            </div>
        </div>
    )
}

export default ProductMasterTable

