import React from 'react'

const BrandOwnerFillIcon = () => {
  return (
    <div>
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 0L50.7275 17.9656L41.2824 47.0344H10.7176L1.27253 17.9656L26 0Z" fill="#5D38E0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7204 28.887C20.5018 28.1056 21.5616 27.6666 22.6667 27.6666H29.3333C30.4384 27.6666 31.4982 28.1056 32.2796 28.887C33.061 29.6684 33.5 30.7282 33.5 31.8333V33.5C33.5 33.9602 33.1269 34.3333 32.6667 34.3333C32.2064 34.3333 31.8333 33.9602 31.8333 33.5V31.8333C31.8333 31.1703 31.5699 30.5344 31.1011 30.0655C30.6323 29.5967 29.9964 29.3333 29.3333 29.3333H22.6667C22.0036 29.3333 21.3677 29.5967 20.8989 30.0655C20.4301 30.5344 20.1667 31.1703 20.1667 31.8333V33.5C20.1667 33.9602 19.7936 34.3333 19.3333 34.3333C18.8731 34.3333 18.5 33.9602 18.5 33.5V31.8333C18.5 30.7282 18.939 29.6684 19.7204 28.887Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26 19.3333C24.6193 19.3333 23.5 20.4526 23.5 21.8333C23.5 23.214 24.6193 24.3333 26 24.3333C27.3808 24.3333 28.5 23.214 28.5 21.8333C28.5 20.4526 27.3808 19.3333 26 19.3333ZM21.8334 21.8333C21.8334 19.5321 23.6989 17.6666 26 17.6666C28.3012 17.6666 30.1667 19.5321 30.1667 21.8333C30.1667 24.1345 28.3012 26 26 26C23.6989 26 21.8334 24.1345 21.8334 21.8333Z" fill="white"/>
    </svg>
    </div>
  )
}

export default BrandOwnerFillIcon
