import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import './authStyle.scss';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../redux/hook";
import { login } from "../../../redux/slices/authSlice";
import axios from "axios";
import { BASE_URL_NEW } from "../../../appConstant";
import { userDetails } from "../../../redux/slices/userSlice";
import { brandDetails } from "../../../redux/slices/clientSlice";
import EnlyticalImg from "../../../assets/enlyticalImg.png"
import GoogleIcon from "../../../assets/svgs/GoogleIcon";
import MicrosoftIcon from "../../../assets/svgs/MicrosoftIcon";
import { useGoogleLogin } from '@react-oauth/google';
import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

const Login2: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState({
        password: "",
        email: "",
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const togglePasswordVisibility = (): void => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((state) => ({
            ...state,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        const { email, password } = state;
        if (email === "") {
            toast.error('email is required')
            return;
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            toast.error('Please enter a valid email')
            return;
        } else if (password === "") {
            toast.error('Password is required')
            return;
        } else if (password.length < 5) {
            toast.error('Password must be longer than or equal to 5 characters')
            return;
        }
        e.preventDefault();
        setLoading(true);
        try {
            const auth = await axios.post(`${BASE_URL_NEW}/auth/login`, state)
            const { token, user } = auth.data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            dispatch(userDetails(user));
            dispatch(login());
            navigate('/home/brandOnboarding');
            setLoading(false);
            // const brands = await axios.get(`${BASE_URL_NEW}/user/${user?._id}/userBrands`)
            // let brandArr = brands.data;
            // // console.log(brandArr);
            // let current_brand = brandArr[0];
            // dispatch(brandDetails({ brand_array: brandArr, current_brand: current_brand, current_agency: current_brand.agency }));
        } catch (error: any) {
            setLoading(false);
            toast.error(error.response.data.error || 'something went wrong')
        }



        //   // try {
        //   //   const auth = await axios.post(`${BASE_URL}auth/login`, state);
        //   //   const { token, user } = auth.data.data;
        //   //   dispatch(saveUserData(user));
        //   //   // console.log(user);
        //   //   localStorage.setItem("token", token);
        //   //   if (user.user_domain === 'enlytical_admin') {
        //   //     const getAgency = await axios.get(`${BASE_URL}agency`, {
        //   //       headers: {
        //   //         token
        //   //       }
        //   //     })
        //   //     const { agency_array } = getAgency.data.data;
        //   //     const { _id, } = agency_array[0];
        //   //     // console.log(agency_array);
        //   //     const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
        //   //       headers: {
        //   //         token
        //   //       }
        //   //     })
        //   //     const { brand_array } = getBrand.data.data;
        //   //     // console.log(brand_array);
        //   //     let current_brand = brand_array[0];
        //   //       if (brand_array?.length) {
        //   //         let currentBrandInLocal = localStorage.getItem("currentBrand");
        //   //         if (currentBrandInLocal) {
        //   //           for (let b of brand_array) {
        //   //             if (b._id === currentBrandInLocal) {
        //   //               current_brand = b;
        //   //               break;
        //   //             }
        //   //           }
        //   //         }
        //   //       }
        //   //     dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
        //   //     if (brand_array.length === 0) {
        //   //       navigate("/inactiveUser");
        //   //     } else {
        //   //       navigate("/home/app");
        //   //     }
        //   //   } else if (user.user_domain === 'user') {
        //   //     const getAgency = await axios.get(`${BASE_URL}agency`, {
        //   //       headers: {
        //   //         token
        //   //       }
        //   //     })
        //   //     const { agency_array } = getAgency.data.data;
        //   //     // console.log(agency_array);
        //   //     if (agency_array.length === 0) {
        //   //       const getBrand = await axios.get(`${BASE_URL}brand`, {
        //   //         headers: {
        //   //           token
        //   //         }
        //   //       })
        //   //       const { brand_array } = getBrand.data.data;
        //   //       // console.log(brand_array);
        //   //       let current_brand = brand_array[0];
        //   //       if (brand_array?.length) {
        //   //         let currentBrandInLocal = localStorage.getItem("currentBrand");
        //   //         if (currentBrandInLocal) {
        //   //           for (let b of brand_array) {
        //   //             if (b._id === currentBrandInLocal) {
        //   //               current_brand = b;
        //   //               break;
        //   //             }
        //   //           }
        //   //         }
        //   //       }
        //   //       dispatch(saveAppParamsData({ agency_array, current_agency: [], brand_array, current_brand: current_brand }));

        //   //       if (brand_array.length === 0) {
        //   //         navigate("/inactiveUser");
        //   //       } else {
        //   //         navigate("/home/app");
        //   //       }
        //   //     } else if (agency_array.length > 0) {
        //   //       const { _id, name } = agency_array[0];
        //   //       const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
        //   //         headers: {
        //   //           token
        //   //         }
        //   //       })
        //   //       const { brand_array } = getBrand.data.data;
        //   //       let current_brand = brand_array[0];
        //   //       if (brand_array?.length) {
        //   //         let currentBrandInLocal = localStorage.getItem("currentBrand");
        //   //         if (currentBrandInLocal) {
        //   //           for (let b of brand_array) {
        //   //             if (b._id === currentBrandInLocal) {
        //   //               current_brand = b;
        //   //               break;
        //   //             }
        //   //           }
        //   //         }
        //   //       }
        //   //       dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
        //   //       if (brand_array.length === 0) {
        //   //         navigate("/inactiveUser");
        //   //       } else {
        //   //         navigate("/home/app");
        //   //       }
        //   //     }
        //   //   }
        //   // } catch (error) {
        //   //   setLoading(false);
        //   //   NotificationManager.error(error.response.data.data.message, "Error", 3000);
        //   // }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            // console.log(tokenResponse);

            const response = await axios.post(`${BASE_URL_NEW}/auth/google-login`, {
                token: tokenResponse.access_token,
            });
            // console.log('Google Login Success:', response);
            const data = await response.data;
            if (data.token) {
                localStorage.setItem('token', data.token);
                console.log('Google Login successful:', data);
                localStorage.setItem('user', JSON.stringify(data.user));
                dispatch(userDetails(data.user));
                dispatch(login());
                navigate('/home/brandOnboarding');
            } else {
                // console.log('Google Login failed:', data);
                navigate('/login');
                toast.error('User does not exist')
            }
        },
        onError: () => {
            navigate('/login');
            toast.error('User does not exist')
            console.log('Google Login Failed');
        },
    });

    // useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const code = urlParams.get("code");

    //     if (code) {
    //         console.log("Received Microsoft Authorization Code:", code);

    //         // Send code to backend for token exchange
    //         axios.post(`${BASE_URL_NEW}/auth/microsoft-login`, { code })
    //             .then(response => {
    //                 const data = response.data;
    //                 if (data.token) {
    //                     localStorage.setItem("token", data.token);
    //                     localStorage.setItem("user", JSON.stringify(data.user));
    //                     navigate("/home/brandOnboarding");
    //                     console.log("Microsoft Login Successful:", data);
    //                 } else {
    //                     toast.error("User does not exist");
    //                     navigate("/login");
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error("Microsoft Login Failed", error);
    //                 toast.error("Microsoft Login Failed");
    //                 navigate("/login");
    //             });
    //     } else {
    //         console.error("No authorization code found in URL");
    //         navigate("/login");
    //     }
    // }, []);

    // const microsoftLogin = () => {
    //     const clientId = 'b974bb12-8e24-4264-9e9c-e932eb768166';
    //     const redirectUri = encodeURIComponent(`${BASE_URL_NEW}/auth/microsoft/callback`);
    //     let randomString = (Math.random() + 1).toString(36).substring(7)
    //     const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=User.Read&prompt=login&state=${randomString}`
    //     window.location.href = authUrl;
    // }


    const { instance } = useMsal();   

    const handleLogin = async () => {
        console.log('microsoft login clicked');
        try {
            const response = await instance.loginPopup({
                scopes: ["openid", "profile", "email", "User.Read"],
            });            

            const accessToken = response.accessToken;
            

            const backendResponse = await fetch("http://localhost:3001/auth/microsoft", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: accessToken }),
            });

            const data = await backendResponse.json();
            console.log("Backend response:", data);
        } catch (error) {
            console.log(error);
            
            console.error("Login failed:", error);
        }
    };

    return (
        <div className="login">

            {/*

            <div className="login-left">

                {
                    loading && <div style={{ position: "absolute", left: "calc(75%) ", top: "calc(50%)" }}><Loader /></div>
                }

                <div className="login-loginContainer">
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <img src={EnlyticalLogo} alt="logo" height={"80px"} />
                    </div>
                    <div className="login-loginContainerHeader">
                        <h4 className="mb-1">Login !</h4>
                        <h6 className="mb-2">Time to login to continue your journey <span style={{ color: "var(--active-color)" }}></span></h6>
                    </div>
                    <hr />
                    <form className="form">
                        <div className="mb-3">
                            <label className="mb-1 login-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                className="form-control login-input"
                                id="email"
                                placeholder="name@example.com"
                                onChange={handleInputChange}
                                value={state.email}
                            ></input>
                        </div>
                        <div style={{ position: 'relative' }} className="mb-3">
                            <label className="mb-1 login-label">Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={state.password}
                                placeholder="Password"
                                style={{ paddingRight: '30px' }}
                                onChange={handleInputChange}
                                className="form-control login-input"
                                id="password"
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
                            <div>
                                <button className="login-btn"
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={loading ? true : false}
                                >Log In </button>
                            </div>

                            <div>
                                <Link style={{ color: 'var(--active-color)', fontWeight: '600' }} to={"/forgetPassword"}>Forgot Password?</Link>
                            </div>

                             <div>
                <Link style={{ color: 'var(--active-color)', fontWeight: '600' }} to={"/signup"}>Sign Up</Link>
              </div> 

                        </div>
                    </form>
                </div>
            </div>

                          */  }


            <div className="login-left">
                {/* {loading && (
                    <div style={{ position: "absolute", left: "calc(75%)", top: "calc(50%)" }}>
                        <Loader />
                    </div>
                )} */}

                <div className="login-loginContainer">
                    {/* Logo */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={EnlyticalLogo} alt="logo" height="80px" />
                    </div>

                    {/* Header */}
                    <div className="login-loginContainerHeader">
                        <h4 className="mb-1">Login!</h4>
                        <h6 className="mb-2">
                            Time to login to continue your journey{" "}
                            <span style={{ color: "var(--active-color)" }}></span>
                        </h6>
                    </div>

                    {/* Form */}
                    <form className="form">
                        {/* Email */}
                        <div className="mb-3">
                            <label className="mb-1 login-label">Email address*</label>
                            <input
                                type="email"
                                name="email"
                                className="form-control login-input"
                                id="email"
                                placeholder="name@example.com"
                                onChange={handleInputChange}
                                value={state.email}
                            />
                        </div>

                        {/* Password */}
                        <div style={{ position: "relative" }} className="mb-3">
                            <label className="mb-1 login-label">Password*</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={state.password}
                                placeholder="Password"
                                className="form-control login-input"
                                id="password"
                                onChange={handleInputChange}
                                style={{ paddingRight: "30px" }}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                className="password-toggle"
                            >
                                {showPassword ? (
                                    <i className="bi bi-eye"></i>
                                ) : (
                                    <i className="bi bi-eye-slash"></i>
                                )}
                            </span>
                        </div>

                        {/* Remember Me & Forgot Password */}
                        <div className="login-remember-forgot">
                            <div className="login-checkbox">
                                <input type="checkbox" id="rememberMe" />
                                <label htmlFor="rememberMe">Remember Me</label>
                            </div>
                            <Link to="/forgetPassword" className="login-forgot">
                                Forgot Password?
                            </Link>
                        </div>

                        {/* Login Button */}
                        <button
                            className="login-btn"
                            type="button"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? (
                                <div className="loader2"></div>
                            ) : (
                                'Log In' // Default button text
                            )}
                        </button>

                        {/* Sign Up Section */}
                        <p className="login-signup-text">Don't have an account?</p>
                        <Link to="/signup-mode" className="login-signup">
                            Sign Up
                        </Link>
                    </form>

                    {/* OR Divider */}
                    <div className="login-divider">
                        <span>OR</span>
                    </div>

                    {/* SSO Login */}
                    <div className="login-sso">
                        <button onClick={() => googleLogin()} className="login-sso-btn google">
                            <div className="google-icon"><GoogleIcon /></div> <div>Continue with Google</div><div></div>
                        </button>
                        <button onClick={handleLogin} className="login-sso-btn microsoft">
                            {/* <i className="bi bi-microsoft"></i> */}
                            <div className="microsoft-icon"><MicrosoftIcon /></div><div>Continue with Microsoft</div><div></div>
                        </button>
                    </div>

                </div>
            </div>


            <div className="login-right">
                <div className="right-text">
                    <h2 className="mb-0">
                        <strong>AI enabled </strong>Growth
                    </h2>
                    <h2 className="mb-0">We make E-commerce Faster,</h2>
                    <h2 className="mb-0">Simpler and Better</h2>
                </div>
                <div className="right-content">
                    {/* <div className="right-text">Your command center for tracking, managing, and optimizing all aspects of your business. Whether you're overseeing projects, monitoring performance metrics, or collaborating with your team, Enlytical.ai provides you with the tools and insights you need to stay ahead of the curve.</div> */}
                    <div className="main-enlytical-img-container"><img src={EnlyticalImg} alt="img" className="main-enlytical-img" /></div>
                </div>
            </div>
        </div>
    );
};

export default Login2;

