import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL_NEW, USER_ROLES } from '../../../appConstant';
import ReportsErrorPage from '../../../utils/commonComponents/errorFallback/ReportsErrorPage';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { Avatar } from '@mui/material';
import axios from 'axios';
import { getUserColor } from '../../../utils/commonFunction';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModifyBrandRole from '../components/ModifyBrandRole';
import { useAppSelector } from '../../../redux/hook';
import { toast } from 'react-toastify';
import BrandModules from '../components/BrandModules';
import BrandAccess from '../components/BrandAccess';
import set from 'date-fns/fp/set';


const SettingsComponent = ({ data, setModalOpen, setModifyRole, fetchData }) => {
  const loggeduser = useAppSelector((state) => state.user);
  const { current_brand: { access_role } } = useAppSelector((state) => state.client)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const access_role_each=data?.access_role;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleModifyRole = () => {
    if (access_role === 'AGENCY_ADMIN' || access_role === 'BRAND_MANAGER'  || access_role === 'ADMIN' || access_role === 'AGENCY_ADMIN' || access_role === 'MANAGER' ) {
      setModifyRole(data);
      setModalOpen(true);
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose();
  }

  const handleDelete = () => {
    if (access_role === 'AGENCY_ADMIN' || access_role === 'BRAND_MANAGER' || access_role === 'ADMIN' || access_role === 'AGENCY_ADMIN' || access_role === 'MANAGER' ) {
      axios.patch(`${BASE_URL_NEW}/access/${data._id}`, {
        // access_type: "BRAND",
        // access_role: "BRAND_MANAGER",
        status: "INACTIVE"
      }).then((res) => {
        // console.log(res);
        // fetchData();
        toast.success("User deleted successfully in this brand")
      }).catch(error => {
        console.log(error);
        toast.error("Something went Wrong")
      }).finally(() => {

      })
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose();
  }

  return (
    <div>
      {/*<i onClick={handleClick} className="bi bi-three-dots-vertical"></i> */}
      <div style={{display:'flex',gap:'.2rem'}}>
    {  access_role === "MEMBER"  || access_role === "BRAND_MEMBER"  || access_role_each === 'ADMIN' || access_role === 'CLIENT' ? "-"  :
      <>
       {
          (access_role_each==='MANAGER' && access_role==='MANAGER' ) ? "-":
       <>
        <div style={{ fontSize: '0.75rem' }} onClick={handleModifyRole}><EditOutlinedIcon style={{ fontSize: '1.5rem', color: '#fff',backgroundColor:'#06486A', padding:'.2rem',borderRadius:'.2rem' ,cursor:'pointer' }} />&nbsp; </div>
        <div style={{ fontSize: '0.75rem' }} onClick={handleDelete}><DeleteOutlineOutlinedIcon style={{ fontSize: '1.5rem', color: 'fff',backgroundColor:'red',  padding:'.2rem',borderRadius:'.2rem' ,cursor:'pointer'  }} />&nbsp; </div>
       </>
       }
      </>
    }
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleModifyRole}><EditOutlinedIcon sx={{ fontSize: '0.85rem', color: '#384D6C' }} />&nbsp; Modify Brand Roles</MenuItem>
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleDelete}><DeleteOutlineOutlinedIcon sx={{ fontSize: '0.85rem', color: 'red' }} />&nbsp; Delete User</MenuItem>
      </Menu>
    </div>
  )
}

const BrandPage = (props) => {
  const { brandList, setBrandList, filterBrandWiseUser, brandWiseUserData, setBrandWiseUserData, filterBrandList } = props;
  const gridRef = useRef();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAfter, setIsLoadingAfter] = useState(false);
  const [view, setView] = useState('list')
  const [brand, setBrand] = useState();
  const [activeBrand, setActiveBrand] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [isTableOpen, setIsTableOpen] = useState(false);
  // const [brandGrid, setBrandGrid] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modifyRole, setModifyRole] = useState({});
  const [toggleTable, setToggleTable] = useState('modules');
  const [toggleModuleMode, setToggleModuleMode] = useState('publish');
  const user = useAppSelector((state) => state.user);
  const [isBrandAccess,setisBrandAccess]=useState(false);

  const [selectedBrand,setSelectedBrand]=useState(null);


  // console.log('selected BRAND',selectedBrand)


  // console.log('filtered brand list', filterBrandList)


  

  useEffect(() => {
    setIsLoading(true)
    // axios.get(`${BASE_URL_NEW}/brand`)
    axios.get(`${BASE_URL_NEW}/user/${user._id}/userbrands2`)
      .then((res) => {
        setBrandList(res.data);
        // console.log('res data ',res.data)
        if (brandList) setBrand(brandList[0]);
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      })
  }, [])

  // useEffect(() => {
  //   const selectedEl = document.getElementById('listEl');
  //   console.log(selectedEl);
  //   selectedEl.addEventListener('load', () => {
  //     selectedEl.style.border = "2px solid #384D6C";
  //   })
  // return () => {
  //   selectedEl.removeEventListener('load', () => {
  //     selectedEl.className = 'brandPage-brandEachActive';
  //   })
  // }
  // }, [])

  const fetchData = () => {
    setIsLoading(true)
    if (brand) {
      // console.log('brand',brand)
      axios.get(`${BASE_URL_NEW}/user/brand/${brand?.client_id}`)
        .then((res) => {
          res.data.forEach((brand) => {
            const user = brand.user
            if (!user?.last_active_on) brand.status = "NA"
            else if (Date.now() - user.last_active_on < 60000 * 2) brand.status = 'ACTIVE'
            else if (Date.now() - user.last_active_on < 60000 * 60) brand.status = Math.round((Date.now() - user.last_active_on) / 60000) + " min"
            else if (Date.now() - user.last_active_on < 60000 * 60 * 24) brand.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60)) + " hr"
            else brand.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60 * 24)) + " days"
          })
          // setBrandWiseUserData(res.data);

          setBrandWiseUserData(
            res.data.sort((a, b) => (a?.user?.email === user?.email ? -1 : b?.user?.email === user?.email ? 1 : 0))
        );

        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          setIsLoading(false);
        })
    }
  }



  useEffect(() => {
    setTimeout(() => {
      fetchData()
    }, 0)
    const intervalId = setInterval(fetchData, 60000 * 2);
    return () => clearInterval(intervalId);
  }, [brand?.client_id])


  const nameComp = (props) => {
    const { data: { user, access_role, status } } = props;
    return (
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
       { user?.first_name ?
        <span style={{ textTransform: "capitalize" }}>{user?.first_name + ' ' + user?.last_name}</span>
       :
        <span style={{ textTransform: 'capitalize' }}>{user?.email?.split('@')[0]}</span>
       }
      </div>
    )
  }
   
  const profilecomp = (props) => {
    const { data: { user, access_role, status } } = props;
    return (
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>

        <span style={{ position: 'relative' }}>
          <Avatar sx={{ width: '1.25rem', height: '1.25rem', backgroundColor: `${getUserColor(access_role)}` }}>
            <span style={{ fontSize: '0.65rem', color: '#ffffff' }}>
            {(user?.first_name? (user?.first_name?.split(" ")[0][0]?.toUpperCase()) : (user?.email?.split(" ")[0][0]?.toUpperCase()) ) +
             (user?.last_name ? user?.last_name?.split(" ")[0][0]?.toUpperCase() : '' ) } 
            </span>
          </Avatar>
          {status === 'ACTIVE' &&
            <i style={{ position: 'absolute', top: '-12px', right: '-4px', zIndex: 999, color: '#2FE346', WebkitTextStroke: '0.25rem', }} className="bi bi-dot"></i>
          }
        </span>
      </div>
    )
  }

  const emailComp = (props) => {
    const { data: { user } } = props;
    return (
      <div >{user?.email}</div>
    )
  }

  const roleComp = (props) => {
    const { data: { access_role } } = props;
    return (
      <div style={{ borderLeft: `4px solid ${getUserColor(access_role)}`, paddingLeft: '0.5rem' }}>
        {USER_ROLES[access_role]}
      </div>
    )
  }

  const statusComp = (props) => {
    const { status,user } = props.data;

    return (
      <div>
        {
          status === 'ACTIVE' ?
            <span style={{ backgroundColor: '#2fe34742', padding: '2px 4px', border: '2px solid #2FE346', borderRadius: '1rem' }}>
              <i style={{ color: '#2FE346', WebkitTextStroke: '0.25rem' }} class="bi bi-dot"></i>&nbsp;Active
            </span> :
            <span> { user?.first_name && user?.email ? <>{status} ago</> : 'Signup Pending' }</span>
        }
      </div>
    )
  }

  const columnDefs = useMemo(() => [
    { headerName: "Profile", field: "profile", cellRenderer: profilecomp, width: 60,cellClass: 'center-text', },
    { headerName: "NAME", field: "name", cellRenderer: nameComp, width: 140 },
    { headerName: "EMAIL", field: 'email', cellClass: "email-cell", cellRenderer: emailComp, width: 200 },
    { headerName: "STATUS", field: 'status', cellRenderer: statusComp, cellClass: 'center-text', width: 120, },
    { headerName: "BRAND ROLE", field: 'access_role', cellRenderer: roleComp, width: 120, },
    { headerName: "ACTION", field: '', cellRenderer: ({ data }) => SettingsComponent({ data, setModalOpen, setModifyRole, fetchData }), cellClass: 'center-text', width: 120, },
  ], [])

  const handleSelectBrand = (el, index) => {

    setSelectedBrand(el)
    // console.log(el);
   if(el?.access_role && el?.access_right && el?.access_type && el?.access_id){

    setisBrandAccess(true);
     setBrand(el);
     setActiveBrand(el)
     setActiveRow(index);
     setIsTableOpen(!isTableOpen);


   }else{

    setisBrandAccess(false);
    setBrand(el);

   }

  }


  const handleAccessRequest=()=>{

   const data = {
    "access_type":"BRAND",
    "access_role":"MEMBER",
    "access_right":"VIEW",
    "agency": selectedBrand?.agency?._id,
    "brand": selectedBrand?.client_id,
    "user": user?._id
   }

   setIsLoadingAfter(true);

    axios.post(`${BASE_URL_NEW}/request`,data)
    .then((res) => {
        toast.success(' Request Submitted ')
    }).catch((err) => {
       if(err?.response?.data?.status===409){
        toast.info(err?.response?.data?.error)
       }
        console.log(err);
    }).finally(() => {
      setIsLoadingAfter(false);
    })


  }

  // const handleSelectBrandGrid = (el, index) => {
  //   setBrandGrid(el)
  //   setActiveBrand(el)
  //   setActiveRow(index);
  //   setIsTableOpen(!isTableOpen);
  // }

  function findSubsets(array, n) {
    var answers = [];
    for (var i = 0; i < array.length; i += n) {
      answers.push(array.slice(i, i + n));
    }
    return answers;
  }
  // console.log(findSubsets(filterBrandList, 5));
  const subSetBrandArr = findSubsets(filterBrandList, 5)

  return (
    <div>

   {/*    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div></div>
        <div className='tabs_box'>
          <button onClick={() => setToggleTable('access')} className={toggleTable === 'access' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}>Access</button>
          <button onClick={() => setToggleTable('modules')} className={toggleTable === 'modules' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}>Modules</button>
        </div>

        <div style={{display:'flex' ,gap:'.5rem'}}>
          <div  className=" request-btn bg-blue-500 text-white px-3 py-1 rounded-md cursor-pointer flex items-center gap-2" > Request <i class="bi bi-upload"></i></div> 
          <div  className=" request-btn bg-blue-500 text-white px-3 py-1 rounded-md cursor-pointer flex items-center gap-2" > Publish <i class="bi bi-upload"></i></div> 
       </div>


        <div>
          <button className={`togglebtn ${view === 'grid' ? 'togglebtnActive' : ''}`} onClick={() => setView('grid')} type='button'><i style={{ WebkitTextStroke: '.5px' }} className="bi bi-grid-3x3-gap"></i></button>
          <button className={`togglebtn ${view === 'list' ? 'togglebtnActive' : ''}`} onClick={() => setView('list')} type='button'><i style={{ WebkitTextStroke: '.5px' }} className="bi bi-list-columns-reverse"></i></button>
        </div> 
      </div>
      */}

      {view === 'list' &&
        <div className='brandPage'>
          <div className='brandPage-brandList'>
            {
              filterBrandList?.map((el) => {
                return (
                  <div id='listEl' onClick={() => handleSelectBrand(el)} key={el._id} className={brand === el ? 'brandPage-brandEachActive' : 'brandPage-brandEach'}>
                    {el?.logo  ? <img src={el.logo} alt="" width={100} height={40} /> : el.client_name}
                  </div>
                )
              })
            }
            
          </div>

            
          {isBrandAccess?(
          <div style={{display:'flex', flexDirection:'column',width:'100%',justifyContent:'top'}}>
          <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginBottom:'1rem' }}>

          <div className='tabs_box'>
          <button onClick={() => setToggleTable('access')} className={toggleTable === 'access' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}>Access</button>
          <button onClick={() => setToggleTable('modules')} className={toggleTable === 'modules' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}>Modules</button>
          </div>

          <div style={{display:'flex' ,gap:'.5rem'}}>
          {toggleTable === 'modules' && <>

          <div className='tabs_box'>
          <button  onClick={()=>{setToggleModuleMode('request')}} className={toggleModuleMode === 'request' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}>Request </button>
          <button onClick={()=>{setToggleModuleMode('publish')}} className={toggleModuleMode === 'publish' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}>Publish </button>
          </div>



          {/* <div  className={` request-btn  ${toggleModuleMode ==='request'? 'bg-blue-500 text-white' : 'bg-white text-black border'} px-3 py-1 rounded-md cursor-pointer flex items-center gap-2`} style={{cursor:'pointer'}} onClick={()=>{setToggleModuleMode('request')}} > Request <i class="bi bi-upload"></i></div>  */}
          {/* <div  className={` request-btn ${toggleModuleMode ==='publish'? 'bg-blue-500 text-white' : 'bg-white text-black border'} px-3 py-1 rounded-md cursor-pointer flex items-center gap-2`}  style={{cursor:'pointer'}} onClick={()=>{setToggleModuleMode('publish')}} > Publish <i class="bi bi-upload"></i></div>  */}
          </>}
          </div>
        
          </div>

          {toggleTable === 'access' && <BrandAccess
            brandWiseUserData={brandWiseUserData}
            columnDefs={columnDefs}
            error={error}

          />}
          {toggleTable === 'modules' && <BrandModules
            nameComp={nameComp}
            profilecomp={profilecomp}
            brand={brand}
            setIsLoading={setIsLoading}
            toggleModuleMode={toggleModuleMode}
          />}


          {
            isLoading && (<div style={{ position: "absolute", left: "calc(60%) ", top: "calc(50%)" }} >< Loader /></div>)
          }

          </div>
          ):
          <div className='access-req-block' style={{display:'flex', flexDirection:'column',width:'100%',justifyContent:'center',alignItems:'center',marginTop:'10rem'}}>
           { selectedBrand &&
            <div>
              <div style={{fontSize:'0.85rem'}}>To Access this brand information,</div>
              <div style={{fontSize:'0.85rem' ,marginBottom:'.5rem'}}>You need to submit request.</div>
              <span className={` request-btn bg-blue-500 text-white px-3 py-1.5 rounded-md cursor-pointer flex items-center gap-2`} style={{cursor:'pointer'}} onClick={()=>{handleAccessRequest()}} >
                {isLoadingAfter ? (
                        <div className="loader2"></div> 
                        ) : (
                         'Request Access' // Default button text
                       )}</span> 
            </div> 
           }
            </div> 

            }
        </div>
      }
      {view === 'grid' && 
               
        <div className='brandPage-grid' >
          {
            subSetBrandArr?.map((el, index) => {
              return (
                <React.Fragment>
                  {el.map((ele) => (
                    <div onClick={() => handleSelectBrand(ele, index)} className={activeBrand === ele ? "brandPage-brandInGridActive" : 'brandPage-brandInGrid'}>
                      {ele?.logo ? <img src={ele?.logo} alt="" width={80} height={40} /> : ele.client_name}
                    </div>
                  ))}
                  <div className={brand === activeBrand && activeRow === index ? 'hidden-table active' : 'hidden-table'}>
                    <div>
                      {toggleTable === 'access' && 
                      <BrandAccess
                        brandWiseUserData={brandWiseUserData}
                        columnDefs={columnDefs}
                        error={error}

                      />}
                      {toggleTable === 'modules' && 
                      <BrandModules
                        nameComp={nameComp}
                      />}
                      {
                        isLoading && <div style={{ position: "absolute", left: "calc(60%) ", top: "calc(50%)" }} >< Loader /></div>
                      }
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
  
      }
      <ModifyBrandRole
        modifyRole={modifyRole}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        fetchData={fetchData}
      />
    </div>
  )
}

export default BrandPage