import React, { useState, useMemo, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ReportsErrorPage from '../../../utils/commonComponents/errorFallback/ReportsErrorPage';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { AG_AR, BASE_URL_NEW } from '../../../appConstant';
import '../../../appLayout/layoutComponents/Content.scss';
import DrawerComponent from '../components/DrawerComponent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Avatar } from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import axios from 'axios';
import { getUserColor } from '../../../utils/commonFunction';
import ModifyRole from '../components/ModifyRole';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../redux/hook';
import LoaderSkeletonMngmtTable from '../../../utils/commonComponents/Loader/LoaderSkeletonMngmtTable';

const SettingsComponent = ({ data, setModalOpen, setModifyRole, fetchData }) => {
  const loggeduser = useAppSelector((state) => state.user);

  // console.log('logged user vvvvvv',loggeduser)
  const { current_agency, current_brand: { access_role } } = useAppSelector((state) => state.client)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const access_role_each = data?.access_role[0];





  const handleModifyRole = () => {
    if (access_role === 'AGENCY_ADMIN' || access_role === 'ADMIN' || access_role === 'BRAND_ADMIN') {
      setModifyRole(data);
      setModalOpen(true);
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose()
  }

  const handleSetAdmin = () => {
    if (access_role === 'AGENCY_ADMIN' || access_role === 'ADMIN' || access_role === 'BRAND_ADMIN') {
      axios.post(`${BASE_URL_NEW}/access/admin`, {
        user: data?._id,
        agency: current_agency?._id,
        invited_by: loggeduser?._id
      }).then((res) => {
        fetchData();
        toast.success("User is now Set as Admin successfully in this agency")
      }).catch((error) => {
        console.log(error);
        toast.error("Something went Wrong")
      })
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose();
  }

  const handleDelete = () => {
    if (access_role === 'AGENCY_ADMIN' || access_role === 'ADMIN' || access_role === 'BRAND_ADMIN') {
      axios.patch(`${BASE_URL_NEW}/access/?user=${data._id}&agency=${current_agency._id}`, {
        status: "INACTIVE"
      }).then((res) => {
        // console.log(res);
        fetchData();
        toast.success("User deleted successfully in this agency")
      }).catch(error => {
        console.log(error);
        toast.error("Something went Wrong")
      }).finally(() => {

      })
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose();
  }

  return (
    <div>
      {/*<i onClick={handleClick} className="bi bi-three-dots-vertical"></i> */}




      <div style={{
        display: 'flex', gap: '.2rem',
        // cursor: access_role_each === "AGENCY_ADMIN" || access_role_each === "ADMIN" || access_role_each === "BRAND_ADMIN" 
        //   ? "none" : "pointer",
        // pointerEvents: access_role_each === "AGENCY_ADMIN" || access_role_each === "ADMIN" || access_role_each === "BRAND_ADMIN" 
        //   ? "none" : "auto",
        // background: access_role_each === "AGENCY_ADMIN" || access_role_each === "ADMIN" || access_role_each === "BRAND_ADMIN" 
        //   ? "#ccc" : "none",  
        // opacity: access_role_each === "AGENCY_ADMIN" || access_role_each === "ADMIN" || access_role_each === "BRAND_ADMIN" 
        //   ? "0.5" : "1",    
      }}>
        {access_role.toLowerCase() === "member" || access_role.toLowerCase() === "brand_member" || access_role_each.toLowerCase() === 'admin' || access_role.toLowerCase() === 'client' ? "-" :
          <>

            {
              (access_role_each.toLowerCase() === 'manager' && access_role.toLowerCase() === 'manager') ? "-" :
                <>
                  <div sx={{ fontSize: '0.75rem' }} onClick={handleModifyRole}><EditOutlinedIcon style={{ fontSize: '1.5rem', color: '#fff', backgroundColor: '#06486A', padding: '.2rem', borderRadius: '.2rem', cursor: 'pointer' }} /></div>
                  <div sx={{ fontSize: '0.75rem' }} onClick={handleDelete}><DeleteOutlineOutlinedIcon style={{ fontSize: '1.5rem', color: 'fff', backgroundColor: 'red', padding: '.2rem', borderRadius: '.2rem', cursor: 'pointer' }} /></div>
                </>
            }
          </>

        }
      </div>


      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}

      >
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleSetAdmin}><PersonOutlineIcon sx={{ fontSize: '0.85rem', color: '#384D6C' }} />&nbsp; Set as Admin</MenuItem>
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleModifyRole}><EditOutlinedIcon sx={{ fontSize: '0.85rem', color: '#384D6C' }} />&nbsp; Modify Brand Roles</MenuItem>
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleDelete}><DeleteOutlineOutlinedIcon sx={{ fontSize: '0.85rem', color: 'red' }} />&nbsp; Delete User</MenuItem>
      </Menu>

    </div>
  )
}

const AgencyPage = (props) => {
  const { brandList, setBrandList, UsersData, setUsersData, filterUserData } = props;
  const appParams = useAppSelector((state) => state.client);
  const { current_agency, current_brand } = appParams;
  const user = useAppSelector((state) => state.user);
  // console.log(user);
  const gridRef = useRef();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [modifyRole, setModifyRole] = useState({});

  let brandListnew = [];

  // console.log('current agency', appParams);
  // console.log('brand list',brandList);

  const fetchData = () => {
    setIsLoading(true);


    axios.get(`${BASE_URL_NEW}/user/${user._id}/userbrands2`)
      .then((res) => {
        brandListnew = res.data;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
      })

    axios.get(`${BASE_URL_NEW}/user/agency/${current_agency?._id}`)
      .then((res) => {
        // console.log('res data agency page',res.data)
        res.data.forEach((user) => {
          if (!user.last_active_on) user.status = "NA"
          else if (Date.now() - user.last_active_on < 60000 * 2) user.status = 'ACTIVE'
          else if (Date.now() - user.last_active_on < 60000 * 60) user.status = Math.round((Date.now() - user.last_active_on) / 60000) + " min"
          else if (Date.now() - user.last_active_on < 60000 * 60 * 24) user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60)) + " hr"
          else user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60 * 24)) + " days"
        })
        // setUsersData(res.data)


        if (current_brand?.access_role.toLowerCase() === 'client') {

          const filteredUsers = res.data.filter(user =>
            user.brands.some(brand =>
              brandListnew.some(b => b.client_id === brand._id)
            )
          );
          // Sort users
          const sortedUsers = filteredUsers.sort((a, b) =>
            a.email === user?.email ? -1 : b.email === user?.email ? 1 : 0
          );

          setUsersData(sortedUsers);
        } else {

          const sortedUsers = res.data.sort((a, b) =>
            a.email === user?.email ? -1 : b.email === user?.email ? 1 : 0
          );

          setUsersData(sortedUsers);

        }


      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false)
      })
  }


  useEffect(() => {
    fetchData()
    const intervalId = setInterval(fetchData, 60000 * 2);
    return () => clearInterval(intervalId);
  }, [current_agency?._id])

  const handleclose = () => {
    if (gridRef.current.api) {
      gridRef.current.api.getSelectedNodes().forEach(node => {
        node.setSelected(false)
      });
    }
    setOpen(false)
  }

  const handleOpenDrawer = (data) => {
    setOpen(true);
    setSelectedRowId(data)
  }

  const nameComp = (props) => {
    const { data } = props;
    const { status, access_role } = props.data;
    return (
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }} onClick={() => handleOpenDrawer(data)}>
        {/*
        <span style={{ position: 'relative' }}>
          <Avatar sx={{ width: '1.25rem', height: '1.25rem', backgroundColor: `${getUserColor(access_role[0])}` }}>
            <span style={{ fontSize: '0.65rem', color: '#ffffff' }}>{data?.first_name?.split(" ")[0][0]?.toUpperCase() + data?.last_name?.split(" ")[0][0]?.toUpperCase()}</span>
          </Avatar>
          {status === 'ACTIVE' &&
            <i style={{ position: 'absolute', top: '-12px', right: '-4px', zIndex: 999, color: '#2FE346', WebkitTextStroke: '0.25rem', }} className="bi bi-dot"></i>
          }
        </span>
      */ }
        {data?.first_name ?
          <span style={{ textTransform: 'capitalize' }}>{data?.first_name + ' ' + data?.last_name}</span>
          :
          <span style={{ textTransform: 'capitalize' }}>{data?.email?.split('@')[0]}</span>
        }
      </div>
    )
  }

  const profilecomp = (props) => {
    const { data } = props;
    const { status, access_role } = props.data;
    return (
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'center' }} onClick={() => handleOpenDrawer(data)}>
        <span style={{ position: 'relative' }}>
          <Avatar sx={{ width: '1.25rem', height: '1.25rem', backgroundColor: `${getUserColor(access_role[0])}` }}>
            <span style={{ fontSize: '0.65rem', color: '#ffffff' }}>
              {(data?.first_name ? (data?.first_name?.split(" ")[0][0]?.toUpperCase()) : (data?.email?.split(" ")[0][0]?.toUpperCase())) +
                (data?.last_name ? data?.last_name?.split(" ")[0][0]?.toUpperCase() : '')}
            </span>
          </Avatar>
          {status === 'ACTIVE' &&
            <i style={{ position: 'absolute', top: '-12px', right: '-4px', zIndex: 999, color: '#2FE346', WebkitTextStroke: '0.25rem', }} className="bi bi-dot"></i>
          }
        </span>
      </div>
    )
  }

  const statusComp = (props) => {
    const { status, first_name } = props.data;

    return (
      <div>
        {
          status === 'ACTIVE' ?
            <span style={{ backgroundColor: '#2fe34742', padding: '2px 4px', border: '2px solid #2FE346', borderRadius: '1rem' }}>
              <i style={{ color: '#2FE346', WebkitTextStroke: '0.25rem' }} class="bi bi-dot"></i>&nbsp;Active
            </span> :
            <span>{first_name ? <>{status} ago</> : 'Signup Pending'}</span>
        }
      </div>
    )
  }

  const brandComp = (props) => {
    const { brands, access_role } = props.data;
    // console.log('access_role',access_role);
    // console.log(props.data);


    const sortedBrandsprev = brands.sort((a, b) => a.client_name.localeCompare(b.client_name));

    let sortedBrands=[]
    if(current_brand?.access_role.toLowerCase() === 'client'){
         sortedBrands = sortedBrandsprev.filter(brand =>
      brandListnew.some(b => b.client_id === brand._id)
    );
  }else{
       sortedBrands=brands.sort((a, b) => a.client_name.localeCompare(b.client_name));
  }
    
    // console.log('sorted brands', sortedBrands);
    // console.log('brand list',brandListnew)

    const first3element = sortedBrands.slice(0, 3);
    const otherElements = sortedBrands.length - 3;


    const tippyContent = () => {
      return (
        <div>
          <div style={{ textTransform: 'capitalize' }}>Name : {props.data.first_name}&nbsp;{props.data.last_name}</div>
          <div>Brands: <br /> <span style={{ fontWeight: 500 }}>{sortedBrands.map((el) => el.client_name).join(', ')}</span></div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", padding: '0rem 1.8rem' }}>
        <div style={{
          display: 'flex',
          overflow: 'hidden',
          paddingLeft: '.5rem',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          flexGrow: 1, // Allows it to take up space but not push the icon
          minWidth: 0,
        }}>
          {
            (access_role[0] === 'AGENCY_ADMIN' || access_role[0] === 'ADMIN') && current_brand?.access_role.toLowerCase() !== 'client'  ? <div>All Brands</div> :
              <div style={{ display: 'flex' }}>
                {
                  sortedBrands.length > 3 ? <div style={{ display: 'flex' }}>
                    {
                      first3element.map((brand) => {
                        return (
                          <div key={brand?._id}>
                            <span>{brand?.logo ? <img src={brand?.logo} alt="" width={40} height={20} /> : brand.client_name},&nbsp;</span>
                          </div>
                        )
                      })
                    }
                    <span style={{ fontSize: '0.7rem', color: '#888888' }}>{`+${otherElements} more`}</span>
                  </div> : <div style={{ display: 'flex' }}>
                    {
                      sortedBrands.map((brand) => {
                        return (
                          <div key={brand?._id}>
                            <span>{brand?.logo ? <img src={brand?.logo} alt="" width={40} height={20} /> : brand.client_name}&nbsp;</span>
                            {/* <span>{brand?.client_name},&nbsp;</span> */}
                          </div>
                        )
                      })
                    }
                  </div>
                }

              </div>
          }
        </div>

        <div>
          <Tippy content={tippyContent()}
            placement='bottom' theme='custom-theme'
          >
            <i style={{ color: '#384D6C', marginLeft: '.5rem' }} className="bi bi-info-circle-fill"></i>
          </Tippy>
        </div>
      </div>
    )
  }

  const roleComp = (props) => {
    const { data: { access_role } } = props;
    if (access_role[0] === 'AGENCY_ADMIN') {
      return (
        <div style={{ borderLeft: `4px solid ${getUserColor(access_role[0])}`, paddingLeft: '0.5rem' }}>
          ADMIN
        </div>
      )
      // return 'ADMIN'
    } else if (access_role[0] === 'BRAND_MANAGER') {
      return (
        <div style={{ borderLeft: `4px solid ${getUserColor(access_role[0])}`, paddingLeft: '0.5rem' }}>
          MANAGER
        </div>
      )
      // return 'MANAGER'
    } else if (access_role[0] === 'BRAND_MEMBER') {
      return (
        <div style={{ borderLeft: `4px solid ${getUserColor(access_role[0])}`, paddingLeft: '0.5rem' }}>
          MEMBER
        </div>
      )
      // return 'MEMBER'
    } else if (access_role[0] === 'BRAND_VIEWER') {
      return (
        <div style={{ borderLeft: `4px solid ${getUserColor(access_role[0])}`, paddingLeft: '0.5rem' }}>
          VIEWER
        </div>
      )
      // return 'VIEWER'
    } else if (access_role[0] === "EXTERNAL_BRAND_VIEWER") {
      return (
        <div style={{ borderLeft: `4px solid ${getUserColor(access_role[0])}`, paddingLeft: '0.5rem' }}>
          GUEST
        </div>
      )
      // return 'GUEST'
    }


    return (
      <div style={{ borderLeft: `4px solid ${getUserColor(access_role[0])}`, paddingLeft: '0.5rem' }}>
        {access_role[0]}
      </div>
    )
  }

  const columnDefs = useMemo(() => [
    { headerName: "Profile", field: "profile", cellRenderer: profilecomp, width: 60, cellClass: 'center-text', },
    { headerName: "NAME", field: "name", cellRenderer: nameComp, width: 140, },
    { headerName: "EMAIL", field: 'email', cellClass: "email-cell", width: 220, },
    { headerName: "STATUS", field: 'status', cellRenderer: statusComp, width: 120, cellClass: 'center-text', },
    { headerName: "BRANDS", field: '', cellRenderer: brandComp, width: 240, cellClass: 'brand-cell', },
    { headerName: "ROLE", field: 'access_role', cellRenderer: roleComp, width: 94, cellClass: 'left-text', },
    { headerName: "ACTION", field: '', cellRenderer: ({ data }) => SettingsComponent({ data, setModalOpen, setModifyRole, fetchData }), width: 100, cellClass: 'center-text', },
  ], [])

  const defaultColDef = useMemo(() => ({
    sortable: true, resizable: true, minWidth: 75,
    headerClass: 'custom-header',
    cellStyle: { borderRight: "1px solid #EFF2F6" }
  }), []);

  return (
    <div>
      <div className="ag-theme-alpine agency-table" style={{ height: '75vh', width: '100%', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px " }}>
        {
          error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
            <AgGridReact
              ref={gridRef}
              rowData={filterUserData}
              rowHeight={AG_AR.rH}
              headerHeight={AG_AR.hH}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressNoRowsOverlay={true}
              suppressDragLeaveHidesColumns={true}
              suppressMenuHide={true}
              suppressCellFocus={true}
              className="ag-theme-alpine"
              gridOptions={{
                rowStyle: { borderBottom: "1px solid #EFF2F6" },
                defaultColDef: { cellStyle: { borderRight: "1px solid #EFF2F6" } },
              }}
            >
            </AgGridReact>

        }
        {
          isLoading &&
          <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
        }
        <DrawerComponent
          open={open}
          handleclose={handleclose}
          selectedRowId={selectedRowId}
        />
        <ModifyRole
          modifyRole={modifyRole}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          fetchData={fetchData}
        />
      </div>
    </div>
  )
}

export default AgencyPage