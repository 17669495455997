import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WidthFull } from '@mui/icons-material';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { toast } from 'react-toastify';
import { AnyAaaaRecord } from 'node:dns';
import CloudUploadIcon from '../../../../assets/svgs/CloudUploadIcon';
import LoaderAnimationfill from '../../../../assets/svgs/LoaderAnimationfill';
import { BASE_URL } from '../../../../appConstant';
import axios from 'axios';
import LoaderSkeletonLine from '../../../../utils/commonComponents/Loader/LoaderSkeletonLine';
import { useAppSelector } from '../../../../redux/hook';



const CompetitionMasterCityTable = ({ currentPlatform, topRowSelect }: { currentPlatform: any, topRowSelect: any }) => {

    const client = useAppSelector(state => state.client);
    const { current_brand } = client

    const [rowSelect, setRowSelect] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSub, setIsLoadingSub] = useState(false);
    const [expandedRows, setExpandedRows] = useState<any>(null);

    const [isModalOpendownload, setIsModalOpendownload] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cityWiseCompetitionMaster, setCityWiseCompetitionMaster] = useState([])
    const [competitionMaster, setCompetitionMaster] = useState([])
    const [error, setError] = useState(undefined)

    const [isEditing, setIsEditing] = useState(false);
    const [editIconMode, setEditIconMode] = useState<string | null>(null);

    const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const availablePlatforms = ["Amazon", "Flipkart", "Snapdeal", "Myntra", "Paytm"]; // Example List
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const [isdropdownEdit,setIsdropdownEdit] = useState(false);
    


    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
        if (file && allowedExtensions.exec(file.name)) {
            setUploadedFile(file);
            setIsModalOpen(false); // Close modal after upload
        } else {
            toast("Only Excel files (.xls, .xlsx, .csv) are allowed!");
        }
    };

    useEffect(() => {
        const selectedPlatformCode = topRowSelect?.['platform_code']
        if (topRowSelect) {
            setIsLoading(true)
            axios.get(`${BASE_URL}/qcomm/competition-master?client_name=${current_brand?.client_name?.toLowerCase()}&platform_code=${selectedPlatformCode}`,
            ).then((res) => {
                res.data.forEach((master: any) => {
                    master.sp = `${master.max_sp}-${master.min_sp}`
                    master.mrp = `${master.max_mrp}-${master.min_mrp}`
                    master.expiry_days = `${master.max_expiry_days}-${master.min_expiry_days}`
                    master.client_name = `${master.client_name} - ${master.competitions}`
                })
                setCompetitionMaster(res.data)
            }).catch((err) => {
                console.log(err);
                setError(err.message)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [topRowSelect, current_brand])


    useEffect(() => {
        const selectedPlatformCode = rowSelect?.['platform_code']
        const selectedCity = rowSelect?.['city']
        if (selectedPlatformCode) {
            setIsLoadingSub(true)
            axios.get(`${BASE_URL}/qcomm/competition-master?client_name=${current_brand?.client_name?.toLowerCase()}&platform_code=${selectedPlatformCode}&city=${selectedCity}&master_type=competition`,
            ).then((res) => {
                res.data.forEach((master: any) => {
                    master.sp = `${master.max_sp}-${master.min_sp}`
                    master.mrp = `${master.max_mrp}-${master.min_mrp}`
                    master.expiry_days = `${master.max_expiry_days}-${master.min_expiry_days}`
                })
                setCityWiseCompetitionMaster(res.data)
            }).catch((err) => {
                console.log(err);
                setError(err.message)
            }).finally(() => {
                setIsLoadingSub(false);
            })
        }
    }, [rowSelect, current_brand])



    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }


    const [columns, setColumns] = useState<any[]>([
        { header: 'city', field: 'city', width: '130px' },
        { header: 'client name', field: 'client_name', width: '130px' },
        { header: 'pincodes', field: 'pincodes', width: '130px' },
        { header: 'Platform Code', field: 'platform_code', bodyStyle, width: '130px' },
        { header: 'Platform', field: 'platform', width: '130px' },
        { header: 'Name', field: 'pname', width: '130px' },
        { header: 'sp', field: 'sp', width: '150px' },
        { header: 'mrp', field: 'mrp', width: '150px' },
        { header: 'expiry_days', field: 'expiry_days', width: '150px' },
        { header: 'Main category', field: 'main_cat', width: '150px' },
        { header: 'Sub category', field: 'sub_cat', width: '130px' },
        { header: 'Pack Size', field: 'pack_size', width: '130px' },
        { header: 'Description', field: 'description', width: '130px' },
        { header: 'Ingredients', field: 'ingredients', width: '130px' },
    ]);

    const getBackgroundColorHeader = (header: string) => {
        const colorMap: Record<string, string> = {
            Blinkit: '#F6D75133',
            'Swiggy Instamart': '#FC801933',
            Zepto: '#950EDB33',
        };
        if (header !== 'City') {
            return colorMap[currentPlatform]
        }
        else return 'rgba(245, 245, 247, 1)'; // Default color
    };

    const getBackgroundColorHeader2 = (header: string) => {
        const colorMap: Record<string, string> = {
            Blinkit: '#F6D75133',
            'Swiggy Instamart': '#FC801933',
            Zepto: '#950EDB33',
        };

        return colorMap[currentPlatform]

    };


    const renderheader = (head: string) => {
        if (head === 'City') return '';
        else {
            return head;
        }
    }
    const renderHeader2 = (col: string) => {
        if (col === "city") return 'City';
        {
            if (topRowSelect !== null)
                return topRowSelect[col];
        }
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                {columns.map((col) => (
                    <Column header={renderheader(col?.header)}
                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                        style={{ width: col.width }}
                    />
                ))}
                <Column
                    header="Actions"
                    headerStyle={{color: '#000000',textAlign: 'center',fontSize: '0.75rem',fontWeight: 'bold',backgroundColor: 'rgba(245, 245, 247, 1)',}}
                    style={{ width: '70px' }}
                />
            </Row>
            <Row>
                {columns.map((col) => (
                    //change it later
                    <Column
                        header={renderHeader2(col?.field)}
                        //   header={(col.field)}
                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', backgroundColor: getBackgroundColorHeader(col.header), }}
                        style={{ width: col.width }}
                    />
                ))}
                <Column
                    header="" // Leave empty or add a sub-header if required
                    headerStyle={{
                        color: '#000000',
                        textAlign: 'center',
                        fontSize: '0.75rem',
                        backgroundColor: getBackgroundColorHeader('Actions'), // Adjust as needed
                    }}
                    style={{ width: '70px' }}
                />
            </Row>

        </ColumnGroup>
    )


    const handlePlatformSelect = (platform: string) => {


        if (platform && !selectedPlatforms.includes(platform)) {
            setSelectedPlatforms((prev) => [...prev, platform]);
            setSearchTerm("");
            setIsDropdownVisible(false);
        }
    };

    const filteredPlatforms = availablePlatforms.filter(
        (platform) => platform.toLowerCase().includes(searchTerm.toLowerCase()) && !selectedPlatforms.includes(platform)
    );

    const removePlatform = (index: number) => {
        setSelectedPlatforms((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        console.log("Selected Platforms:", selectedPlatforms);
        setIsModalOpenEdit(false); // Close modal after submission
    };

    const handleDelete = async (rowData: any) => {

        console.log('rowdata', rowData)

        try {
            await axios.post(`${BASE_URL}/delete-competition`, rowData);
            alert("Deleted successfully!");
            // Refresh Data
        } catch (error) {
            console.error("Error deleting:", error);
        }

        setIsEditing(false);
    };



    const onColumnResizeEnd = (e: any) => {
        const newColumns = columns.map(col => {
            if (col.header === e.column.props.header) {
                return { ...col, width: `${e.element.offsetWidth}px` };
            }
            return col;
        });
        setColumns(newColumns);
    };

    const rowExpansionTemplate = (data: any) => {
        return (
            //   <div style={{ padding: "1rem" }}>
            isLoadingSub ? <LoaderSkeletonLine /> :
                <DataTable value={cityWiseCompetitionMaster}
                    size='small'
                    columnResizeMode="expand"
                    resizableColumns
                    onColumnResizeEnd={onColumnResizeEnd}
                    className='unq-tble'
                // showGridlines
                >
                    {columns.map((col) => (

                        <Column field={col.field}
                            // style={{ width: col.width }}
                            style={{ width: col.field === 'ingredients' ? '128px' : col.width }}
                            bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize', backgroundColor: isEditing ? '#FFEDED' : getBackgroundColorHeader2(col.header) }}
                            // headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                            body={col?.body}
                        ></Column>

                    ))}

                    <Column
                        header="Actions"
                        body={(rowData: any) => (
                            isEditing ? (
                                // <button
                                //     className="table_btn2"
                                //     onClick={() => handleDelete(rowData)}
                                //     // style={{ cursor: "pointer", color: "#fff" ,backgroundColor:'red' ,border:'1px solid #ccc', borderRadius:'50%' }}
                                // >
                                //     <i style={{color:'red'}} className="bi bi-dash-circle-fill"></i>
                                // </button>
                                <i style={{color:'red', fontSize:'1rem',cursor:'pointer'}} className="bi bi-dash-circle-fill table_btn2" onClick={() => handleDelete(rowData)} ></i>
                            ) : <div>-</div>
                        )}
                        style={{ width: "68px", textAlign: "center" }}
                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize', backgroundColor: isEditing ? '#FFEDED' : getBackgroundColorHeader2('') }}
                    />



                </DataTable>
        );
    };


    const onRowSelect = (e: any) => {
        setRowSelect(e.value);
        setExpandedRows([e.value]);
    };

    const onRowUnselect = () => {
        setRowSelect(null);
        setExpandedRows(null);
    };

    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = [...firstColumn, ...columns].map(col => col.field).join(',');
    //     csvContent += header + '\n';

    //     performanceData.forEach((row: any) => {
    //         const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };

    return (
        <>{
            topRowSelect ?
                <div style={{ marginTop: '1rem' }} className=' table_card_new'>
                    <div style={{ position: 'relative' }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                            <span className='table_kpi_name'>City Wise</span>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <button className='table_btn1' onClick={() => setIsModalOpendownload(true)} >
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                                    </svg>
                                </button>
                                {isModalOpendownload && <>

                                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                        <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                            <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                                <LoaderAnimationfill />
                                            </div>
                                            <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '1rem' }}>You can download the file after progress is complete</p>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '.5rem' }}><button className="download-button" ><i className="bi bi-download"></i>&nbsp;&nbsp;Download Now</button></div>
                                            <div
                                                className="back-button"
                                                style={{ color: '#5595F6', cursor: 'pointer', }}
                                                onClick={() => setIsModalOpendownload(false)}
                                            >
                                                Back
                                            </div>
                                        </div>

                                    </div>
                                </>}
                                <button className='table_btn1' onClick={() => setIsModalOpen(true)}>
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z"
                                            fill="black"
                                            transform="scale(1, -1) translate(0, -16)" />
                                    </svg>
                                </button>
                                {isModalOpen && <>

                                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                        <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                            <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                                <CloudUploadIcon />
                                            </div>
                                            <label
                                                htmlFor="file-upload"
                                                className="file-upload-label"
                                                style={{ border: '1.5px dashed #000000', borderRadius: '.5rem', padding: '.7rem .9rem', margin: '.7rem 0', textAlign: 'center', cursor: 'pointer', color: '#5B6871', fontSize: '.85rem' }}
                                            >
                                                Choose File
                                                <input
                                                    id="file-upload"
                                                    type="file"
                                                    accept=".xls,.xlsx,.csv"
                                                    className="file-upload-input"
                                                    onChange={handleFileUpload}
                                                    style={{ display: 'none' }}
                                                />
                                            </label>
                                            <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '.5rem' }}>Upload your Excel file to import data</p>
                                            <div
                                                className="back-button"
                                                style={{ color: '#5595F6', cursor: 'pointer', }}
                                                onClick={() => setIsModalOpen(false)}
                                            >
                                                Back
                                            </div>
                                        </div>

                                    </div>
                                </>}
                                <button className='table_btn1' disabled={rowSelect ? false : true} style={{ cursor: rowSelect ? '' : 'auto', pointerEvents: rowSelect ? "auto" : "none", }}
                                    onClick={() => {
                                        // setIsModalOpenEdit(true)
                                        isdropdownEdit===true?setIsdropdownEdit(false):setIsdropdownEdit(true);
                                        setIsEditing(false);
                                    }} >
                                    <i className="bi bi-pencil"></i>
                                </button>

                                { isdropdownEdit &&
                            <div style={{ position: 'absolute', right: '2rem', top: '-1rem', backgroundColor: '#fff',border:'1px solid #ccc',borderRadius:'1rem',padding:'.5rem', display: 'flex',width:'max-content', zIndex: 1000, }}>
                                <div className="mt-0 " style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', gap: '.5rem' }}>
                                    <span style={{ color: '#5D38E0',fontSize: '.85rem', }}>Choose Option:</span>
                                    <button
                                        className="submit-btn"
                                        style={{ marginTop:'0px',marginRight: '.5rem', fontSize: '.7rem', backgroundColor: '#5D38E0', borderRadius: '.5rem' }}
                                        onClick={() => { setEditIconMode('add'); setIsModalOpenEdit(true); setIsdropdownEdit(false);  }}
                                    >
                                        Add &nbsp;<i className="bi bi-plus-lg"></i>
                                    </button>
                                    <button
                                        className="submit-btn"
                                        style={{ marginTop:'0px',fontSize: '.7rem', backgroundColor: '#5D38E0', borderRadius: '.5rem' }}
                                        onClick={() => { setIsEditing(true); setIsModalOpenEdit(false); setIsdropdownEdit(false); }}
                                    >
                                        <i className="bi bi-pencil"></i>&nbsp;  Edit
                                    </button>
                                </div>
                            </div>
                            } 


                                {isModalOpenEdit && <>
                                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                        <div className="modal-card" style={{ backgroundColor: '#fff', padding: '1rem 2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                            {/* <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                        <span className="loader"></span>
                                    </div> */}
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.5rem' }}>
                                                {editIconMode === 'add' ?
                                                    <h2 style={{ textAlign: "center", marginBottom: "1rem", color: "#333", fontSize: '.9rem' }}>Add Competition</h2> : <div></div>
                                                }

                                              
                                            </div>

                                            {
                                                editIconMode === 'add' ?
                                                    <div>

                                                        {/* Platform Selection Dropdown */}


                                                        {/* Searchable Input Field */}
                                                        <input
                                                            type="text"
                                                            className="dropdown-search"
                                                            placeholder="Search platform..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            onFocus={() => setIsDropdownVisible(true)}
                                                            onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)}
                                                            style={{ padding: "0.5rem", borderRadius: "5px", width: "100%", border: "1px solid #ccc" }}
                                                        />

                                                        {/* Filtered Platform List */}
                                                        {
                                                            (isDropdownVisible &&
                                                                <div className="dropdown-list" style={{ backgroundColor: "#fff", border: "1px solid #ccc", borderRadius: "5px", marginTop: "0.5rem", maxHeight: "150px", maxWidth: '350px', overflowY: "auto" }}>
                                                                    {filteredPlatforms.length > 0 ? (
                                                                        filteredPlatforms.map((platform: string) => (
                                                                            <div key={platform} onMouseDown={() => handlePlatformSelect(platform)} className="dropdown-item" style={{ padding: "0.5rem", cursor: "pointer", textAlign: "left" }}>
                                                                                {platform}
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className="dropdown-item" style={{ color: "#999", backgroundColor: "#f8f9fa", padding: "8px", textAlign: "center", fontStyle: "italic" }}>
                                                                            No platform found.
                                                                        </div>
                                                                    )}

                                                                </div>

                                                            )}


                                                        {/* Selected Platforms List */}
                                                        <div className="selected-platforms" style={{ marginTop: "1rem", maxHeight: "150px", overflowY: "auto" }}>
                                                            {selectedPlatforms.length > 0 ? (
                                                                selectedPlatforms.map((platform, index) => (
                                                                    <div key={index} className="selected-platform-item" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0.5rem", backgroundColor: "#f1f1f1", borderRadius: "5px", marginBottom: "0.5rem" }}>
                                                                        <span>{platform}</span>
                                                                        <i className="bi bi-x" style={{ cursor: "pointer", color: "red" }} onClick={() => removePlatform(index)}></i>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p style={{ fontSize: "0.85rem", color: "#777" }}>No platform selected.</p>
                                                            )}
                                                        </div>


                                                        {/* Submit & Cancel Buttons */}
                                                        <div style={{ display: "flex", justifyContent: "left", gap: "1rem", marginTop: "1.5rem", alignItems: 'baseline' }}>
                                                            <button className="submit-btn" style={{ fontSize: "0.85rem", padding: "0.5rem 1rem", backgroundColor: "#5D38E0", borderRadius: "5px", color: "#fff" }} onClick={handleSubmit}>
                                                                Submit
                                                            </button>
                                                            <button className="cancel-btn submit-btn" style={{ fontSize: "0.85rem", padding: "0.5rem 1rem", backgroundColor: "#ccc", borderRadius: "5px", color: "#333" }} onClick={() => { setIsModalOpenEdit(false); setEditIconMode(null); }}>
                                                                Cancel
                                                            </button>
                                                            <span style={{ fontSize: '.75rem', cursor: 'pointer' }}>Bulk Upload?</span>
                                                        </div>

                                                    </div> : 'edit logic here'

                                            }

                                        </div>

                                    </div>
                                </>}
                            </div>
                        </div>

                        <PrimeReactProvider>
                            {
                                isLoading ?
                                    <LoaderSkeletonTable height='350px' /> :

                                    <DataTable
                                        value={competitionMaster}
                                        size='small'
                                        columnResizeMode="expand"
                                        resizableColumns
                                        onColumnResizeEnd={onColumnResizeEnd}
                                        selectionMode="single"
                                        selection={rowSelect}
                                        onSelectionChange={(e) => {
                                            e.value ? onRowSelect(e) : onRowUnselect();
                                        }}
                                        // onSelectionChange={(e) => { setRowSelect(e.value) }}
                                        headerColumnGroup={headerGroup}
                                        showGridlines
                                        id='unique-table'
                                        className='unique-tble'
                                        scrollable scrollHeight="350px"

                                        expandedRows={expandedRows}
                                        onRowToggle={(e) => setExpandedRows(e.data)}
                                        // onRowExpand={onRowExpand}
                                        // onRowCollapse={onRowCollapse}
                                        rowExpansionTemplate={rowExpansionTemplate}

                                    >

                                        {columns.map((col, index) => (
                                            <Column
                                                key={index}
                                                field={col.field}
                                                header={col.header}
                                                // header={renderheader(col.header)}
                                                // expander={allowExpansion}
                                                // expander={allowExpansion}
                                                style={{ width: col.width }}
                                                headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                                bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                                // bodyStyle={column?.bodyStyle}
                                                body={col.body}

                                            />

                                        ))}

                                        <Column
                                            field="actions" // Add a unique field name
                                            header="Actions"
                                            headerStyle={{color: '#000000',textAlign: 'center',fontSize: '0.75rem',fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)',}}
                                            body={(rowData: any) => (    
                                                <div>-</div>
                                            )}
                                            style={{ width: "70px", textAlign: "center" }}
                                        />

                                    </DataTable>
                            }

                        </PrimeReactProvider>
                    </div>
                </div>
                : null
        }</>
    )
}

export default CompetitionMasterCityTable

