import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import './authStyle.scss';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import { toast } from "react-toastify";
import ImagePlaceholder from "../../../assets/svgs/ImagePlaceholder";
import EnlyticalImg from "../../../assets/enlyticalImg.png"
import { BASE_URL_NEW } from "../../../appConstant";
import { userInfo } from "node:os";


const SignupBrandOwner: React.FC = () => {
    let navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");


    const [state, setState] = useState({
        name: "",
        first_name: "",
        last_name: "",
        phone_num: "",
        email: "",
        password: '',
        cnfmPassword: '',
        type: type,
    });

    const [Image, setImage] = useState<string | null>(null);
    const [Image2, setImage2] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // console.log('times log')



    // useEffect(() => {
    //     // if (!type) {
    //     if (type !== 'BRAND_OWNER' && type !== 'AGENCY') {
    //         navigate("/signup-mode"); // Redirect to home or any other fallback route
    //     }
    // }, [type, navigate]);



    // const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target;
    //     setState({
    //         ...state,
    //         [name]: value,
    //     });
    // };


    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    }, []);

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();
        if (!Image) {
            toast.error(`${type === "BRAND_OWNER" ? "Brand" : "Agency"} Image is required`);
            return
        }
        if (state.name === '') {
            toast.error(`${type === "BRAND_OWNER" ? "Brand" : "Agency"} Name is required`);
            return
        }
       
        navigate("/signup2", { state: { Image,Image2, name:state.name,type } });
    };



    const handleImageUpload2 = (event: any) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            if (file.size <= 10 * 1024 * 1024) { // 10MB limit
                const reader = new FileReader();
                reader.onload = (event:any) => {
                    setImage(reader.result as string);
                    setImage2(file);
                };
                reader.readAsDataURL(file);
            } else {
                alert("File size exceeds 10MB limit!");
            }
        } else {
            alert("Only PNG and JPEG formats are allowed.");
        }
    };


    return (
        <div className="login">
            <div className="login-left">
                <div className="login-loginContainer login-left-signup-container">


                    <div  style={{display:'flex', flexDirection:'column', justifyContent:'space-between' ,gap:'4rem'}}>

                    <div>    
                    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }} className="mt-2 login-loginContainerHeader" >
                        <div style={{padding:'.5rem'}}><h4 className="mb-1">Sign Up</h4></div>
                        {/* <div><img src={EnlyticalLogo} alt="logo" height={"45px"} /></div> */}
                    </div>

                    <div style={{ display: "flex", justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }} className="" >
                        <div><img src={EnlyticalLogo} alt="logo" height={"70px"} /></div>
                    </div>
                    </div>


                    <div style={{marginTop:'0rem'}}>
                    <div className="agency-brand-container">


                        {Image ? (
                            <div className="MainImage-preview">
                                <img src={Image} alt="Profile" className="profile-image" />
                            </div>
                        ) : (
                            <>
                                <div className="icon"><i className="bi bi-cloud-arrow-up"></i></div>
                                <div className="div1">{type === "BRAND_OWNER" ? "Brand" : "Agency"} Image</div>
                                <div className="div2">Upload from device or drag & drop it here</div>
                                <div className="div3">JPEG, PNG, up to 5MB</div>
                                <div>
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={handleImageUpload2}
                                        className="file-input"
                                        id="fileInput2"
                                    />
                                    <label htmlFor="fileInput2" className="upload-button"> <i className="bi bi-upload"></i>Upload Image</label>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="mb-2 mt-3 signup-input">
                        <label style={{fontSize:'.85rem'}} className="mb-1 mt-2 login-label">{type === "BRAND_OWNER" ? "Brand Name*" : "Agency Name*"}</label>
                        <input
                            type="text"
                            name="name"  //brand/agency
                            className="form-control login-input"
                            style={{fontSize:'.85rem'}}
                            id="name" //brand/agency
                            placeholder={`Enter ${type === "BRAND_OWNER" ? "Brand Name*" : "Agency Name*"}`}
                            onChange={handleInputChange}
                            value={state.name}
                        />
                    </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '2rem' }}>
                            <div>
                                <button
                                    className="login-btn"
                                    id="signup-btn"
                                    type="button"
                                    onClick={handleSubmit}
                                    // disabled={!agreeTerms}
                                >
                                    Next
                                </button>
                            </div>
                            <div>
                                <span style={{ fontSize: '.75rem' }}>Already have an account?</span> <Link style={{ color: '#5D38E0', fontWeight: '600', fontSize: '.85rem' }} to={"/login"}> Sign in </Link>
                            </div>
                    </div>

                    </div>
                    </div>
            </div>

            <div className="login-right">
                <div className="right-text">
                    <h2 className="mb-0">
                        <strong>AI enabled </strong>Growth
                    </h2>
                    <h2 className="mb-0">We make E-commerce Faster,</h2>
                    <h2 className="mb-0">Simpler and Better</h2>
                </div>
                <div className="right-content">
                    {/* <div className="right-text">Your command center for tracking, managing, and optimizing all aspects of your business. Whether you're overseeing projects, monitoring performance metrics, or collaborating with your team, Enlytical.ai provides you with the tools and insights you need to stay ahead of the curve.</div> */}
                    <div className="main-enlytical-img-container"><img src={EnlyticalImg} alt="img" className="main-enlytical-img" /></div>
                </div>

            </div>
        </div>
    );
};

export default SignupBrandOwner;
