// import { useEffect, useState } from 'react'
// import ModalComponent from '../../../utils/commonComponents/Modal/ModalComponent';
// import CustomSelect from '../../../utils/commonComponents/customSelect/CustomSelect';
// import { BASE_URL_NEW } from '../../../appConstant';
// import axios from 'axios';
// import Loader from '../../../utils/commonComponents/Loader/Loader';
// import { toast } from 'react-toastify';

// const customStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     // width: 400,
//     bgcolor: 'background.paper',
//     borderRadius: 10,
//     boxShadow: 24,
//     p: 4,
// };

// const ModifyRole = ({ modifyRole, modalOpen, setModalOpen, fetchData }) => {
//     // console.log(modifyRole);

//     const [selectedBrand, setSelectedBrand] = useState('');
//     const [selectedRole, setSelectedRole] = useState('');
//     const [selectedRights, setSelectedRights] = useState('');
//     const [row, setRow] = useState({});
//     const [isLoading, setIsLoading] = useState(false);

//     useEffect(() => {
//         axios.get(`${BASE_URL_NEW}/user/userDetails/${modifyRole?._id}`)
//             .then((res) => {
//                 // console.log(res.data);
//                 setRow(res.data);
//             }).catch((error) => {
//                 console.log(error);
//             }).finally(() => {
//                 // setIsLoading(false)
//             })
//     }, [modifyRole?._id])

//     const handleModifyRole = () => {
//         setIsLoading(true)
//         axios.patch(`${BASE_URL_NEW}/access/${selectedBrand}`, {
//             access_type: "BRAND",
//             access_role: selectedRole,
//             status: "ACTIVE",
//         }).then((res) => {
//             // console.log(res);
//             fetchData();
//             toast.success("User role changed successfully in this agency")
//         }).catch((error) => {
//             console.log(error);
//             toast.error("Somthing went wrong" || error.response.data.message)
//         }).finally(() => {
//             setIsLoading(false)
//         })
//         setModalOpen(false);
//         setSelectedRole('');
//         setSelectedBrand('')
//     }

//     return (
//         <div>
//             <ModalComponent
//                 open={modalOpen}
//                 modalClose={() => setModalOpen(false)}
//                 customStyle={customStyle}
//             ><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem 8rem' }}>
//                     <h5>Modify Brand Roles</h5>
//                     <p style={{ textTransform: 'capitalize', fontSize: '0.9rem' }}>{row?.first_name + " " + row?.last_name}</p>
//                     <div style={{ display: 'flex', gap: '1rem', marginBottom: '0.5rem' }}>
//                         <CustomSelect className={"modifyRoleSelect"} name="" id="" value={selectedBrand} onchange={(e) => setSelectedBrand(e.target.value)}>
//                             <option value="">Select Brand</option>
//                             {row?.brands?.map((el) => {
//                                 return (
//                                     <option key={el?._id} value={el?.access_id}>{el.client_name}</option>
//                                 )
//                             })}
//                         </CustomSelect>
//                         <CustomSelect className={'modifyRoleSelect'} name="" id="" value={selectedRole} onchange={(e) => setSelectedRole(e.target.value)}>
//                             <option value="">Select Role</option>
//                             <option value="BRAND_MANAGER">Brand Manager</option>
//                             <option value="BRAND_MEMBER">Brand Member</option>
//                             <option value="BRAND_VIEWER">Brand Viewer</option>
//                             <option value="EXTERNAL_BRAND_VIEWER">Guest</option>
//                         </CustomSelect>

//                          <CustomSelect className={'modifyRoleSelect'} name="" id="" value={selectedRights} onchange={(e) => setSelectedRights(e.target.value)}>
//                             <option value="" disabled>Select Rights</option>
//                             <option value="BRAND_MANAGER">View</option>
//                             <option value="BRAND_MEMBER">Edit</option>
//                         </CustomSelect>

//                     </div>
//                     <div style={{ display: 'flex', gap: '1rem' }}>
//                         {selectedBrand && selectedRole && <button onClick={handleModifyRole} className='submit-btn'>Submit</button>}
//                         <button onClick={() => setModalOpen(false)} className='cancel-btn'>cancel</button>
//                     </div>
//                 </div>
//             </ModalComponent >
//             {
//                 isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
//             }
//         </div >
//     )
// }

// export default ModifyRole;









import React, { useEffect, useState } from "react";
import "./usermanagement.scss";
import ModalComponent from "../../../utils/commonComponents/Modal/ModalComponent";
import { BASE_URL_NEW } from "../../../appConstant";
import axios from "axios";
import { toast } from "react-toastify";


 

// Mock existing data
const existingBrandRoles = [
  { brand: "Taali", role: "Member", rights: "View" },
  { brand: "VIP", role: "Admin", rights: "Edit" },
];

const mockBrands = ["Brand X", "Brand Y", "Brand Z"]; // For new additions
const mockRoles = ["MANAGER", "MEMBER", "ADMIN"];
const mockRights = ["VIEW", "EDIT"];




// there going to be two thing s first fetch existing brand role and right for a brand of the user that is clicked 

// second an update api to add assign new brand also with their role to the user only brands the admin has can be shown 
//an api for brands that admin(current person who is using this and assigning).  

//if a brand is already assigned to him don't show it in below options to add


const customStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40rem',
    bgcolor: 'background.paper',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    pr:2,
};

const ModifyRole = ({modifyRole, modalOpen, setModalOpen, fetchData }) => {


  // console.log('modify roles llllllljjjjj',modifyRole)
  const [brandRoles, setBrandRoles] = useState([]);

  const [brandAlldata, setbrandAlldata] = useState([]);

  const [updatedBrandData, setUpdatedBrandData] = useState(brandAlldata);






  const roleformat=(role)=>{
    if(role==='BRAND_MANAGER' || role==='MANAGER' || role==='AGENCY_MANAGER' )  return 'MANAGER';
    else if(role==='BRAND_ADMIN' || role==='ADMIN' || role==='AGENCY_ADMIN' )  return 'ADMIN';
    else if(role==='BRAND_MEMBER' || role==='MEMBER' || role==='AGENCY_MEMBER' )  return 'MEMBER';

  }




    // const [selectedBrand, setSelectedBrand] = useState('');
    // const [selectedRole, setSelectedRole] = useState('');
    // const [selectedRights, setSelectedRights] = useState('');
    // const [row, setRow] = useState({});
    // const [isLoading, setIsLoading] = useState(false);





// old one may use this or take reference 

useEffect(()=>{
    axios.get(`${BASE_URL_NEW}/user/${modifyRole?._id}/userbrands`)
    .then((res)=>{
        // console.log('modify roles data',res.data);
        setbrandAlldata(res.data)

        setUpdatedBrandData(res.data)

        // setBrandRoles(res.data)

    }).catch((err)=>{

    }).finally(()=>{

    })
},[modifyRole?._id])






  const handleChange = (index, field, value) => {


    setUpdatedBrandData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleSubmit = async () => {
    // console.log("Submitting data:", updatedBrandData);

     try {
  
      updatedBrandData.map((brand) =>
        axios.patch(`${BASE_URL_NEW}/access/${brand.access_id}`, {
          access_type: 'BRAND',
          access_role: roleformat(brand.access_role),
          access_right: brand.access_right,
          status: "ACTIVE",
        }).then(()=>{
          }).catch(()=>{
            }).finally(()=>{
              })

              );
    toast("Access Rights Updated!");
    setModalOpen(false);
  } catch (error) {
    console.error("Error updating access data:", error);
    toast("Error updating access data.");
  }

  };












  // const handleChange = (index, field, value) => {
  //   const updatedBrandRoles = [...brandRoles];
  //   updatedBrandRoles[index][field] = value;
  //   setBrandRoles(updatedBrandRoles);
  // };

  const addNewBrandRole = () => {
    setBrandRoles([...brandRoles, { brand: "", role: "", rights: "" }]);
  };

  const removeBrandRole = (index) => {
    const updatedBrandRoles = brandRoles.filter((_, i) => i !== index);
    setBrandRoles(updatedBrandRoles);
  };

  // const handleSubmit = () => {
  //   console.log("Updated Data brand roles:", brandRoles);
  //   //close card also
  //   setModalOpen(false);
  // };

  const handleCancel = () => {
    // setBrandRoles(existingBrandRoles);
    setModalOpen(false);
  };

  return (
    <div className="modify-roles">
    <ModalComponent
                    open={modalOpen}
                    modalClose={() => setModalOpen(false)}
                    customStyle={customStyle}
                >

    <div className="modify-container">
      <h2 className="title">Modify Brand/Roles/Rights</h2>

    {/*
      {brandRoles.map((br, index) => (
        <div key={index} className="brand-role-block">
          <select
            className="dropdown"
            value={br.brand}
            onChange={(e) => handleChange(index, "brand", e.target.value)}
            disabled={existingBrandRoles.some((item) => item.brand === br.brand)}
          >
            <option value="" disabled hidden>Select Brand</option>
            {mockBrands
              .concat(existingBrandRoles.map((b) => b.brand)) // Combine existing and new brands
              .map((brand) => (
                <option key={brand} value={brand}>
                  {brand}
                </option>
              ))}
          </select>

          <select
            className="dropdown"
            value={br.role}
            onChange={(e) => handleChange(index, "role", e.target.value)}
          >
            <option value="" disabled hidden>Select Role</option>
            {mockRoles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>

          <select
            className="dropdown"
            value={br.rights}
            onChange={(e) => handleChange(index, "rights", e.target.value)}
          >
            <option value="" disabled hidden>Select Rights</option>
            {mockRights.map((right) => (
              <option key={right} value={right}>
                {right}
              </option>
            ))}
          </select>

          {existingBrandRoles.some((item) => item.brand === br.brand) ? <div className="remove-btn-div-hidden"></div> : (
            <div  className="remove-btn-div">
            <button className="remove-btn" onClick={() => removeBrandRole(index)}>
              ✖
            </button>   
            </div>
          )}
        </div>
      ))}
    */}    



      {updatedBrandData.map((br, index) => (
        <div key={index} className="brand-role-block">
          <select
            className="dropdown"
            value={br.client_name}
            disabled={true}
          >
            <option value="" disabled hidden>Select Brand</option>
             <option key={index} >
                   {br.client_name}
                 </option>
          </select>


          <select
            className="dropdown"
            value={roleformat(br.access_role)}
            onChange={(e) => handleChange(index, "access_role", e.target.value)}
          >
            <option value="" disabled hidden>Select Role</option>
            {mockRoles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>



          <select
            className="dropdown"
            value={br.access_right}
            onChange={(e) => handleChange(index, "access_right", e.target.value)}
          >
            <option value="" disabled hidden>Select Rights</option>
            {mockRights.map((right) => (
              <option key={right} value={right}>
                {right}
              </option>
            ))}
          </select>



          {/*  existingBrandRoles.some((item) => item.brand === br.brand) ? <div className="remove-btn-div-hidden"></div> : (
            <div  className="remove-btn-div">
            <button className="remove-btn" onClick={() => removeBrandRole(index)}>
              ✖
            </button>   
            </div>
          )   */}

            {<div className="remove-btn-div-hidden"></div>}


        </div>
      ))}








      {/*
      <div style={{textAlign:'center'}}>
      <span className="add-btn" onClick={addNewBrandRole}>+ Add More</span>
      </div>

     */ }


      <div className="button-container">
        <button className="submit-btn" onClick={handleSubmit}>Submit</button>
        <button className="submit-btn cancel-btn" onClick={handleCancel}>Cancel</button>
      </div>
    </div>

    </ModalComponent>
    </div>
  );
};

export default ModifyRole;
