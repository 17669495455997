import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { useNavigate, } from "react-router-dom";
import axios from "axios";
import './authStyle.scss';
import Loader from "../../../utils/commonComponents/Loader/Loader";
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import { toast } from "react-toastify";
import { BASE_URL_NEW } from "../../../appConstant";
import EnlyticalImg from "../../../assets/enlyticalImg.png"

interface LogicState {
    reSendOtp: boolean,
    seconds: number | null
}

interface MainState {
    email: string | null,
    password: string,
    cnfmPassword: string
}

const PasswordChange: React.FC = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState<MainState>({
        email: "",
        password: "",
        cnfmPassword: ""
    });
    const [logic, setLogic] = useState<LogicState>({
        reSendOtp: true,
        seconds: null
    });


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const loginEmail = localStorage.getItem("email");
        setState(prevState => ({
            ...prevState,
            email: loginEmail
        }));
    }, []);

    const counter = () => {
        setLogic(prevState => ({ ...prevState, reSendOtp: false }));
        let s = 30;
        const tick = () => {
            if (s > 0) {
                setTimeout(tick, 1000);
                s = s - 1;
                setLogic(prevState => ({ ...prevState, seconds: s }));
            } else {
                setLogic(prevState => ({ ...prevState, reSendOtp: true }));
            }
        }
        tick()
    }

    useEffect(() => {
        counter();
    }, []);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));

    }

    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const { password, cnfmPassword } = state;

        if (password === "") {
            toast.error('Password is required')
            return;
        } else if (cnfmPassword === "") {
            toast.error('Password confirmation is required')
            return;
        } else if (password !== cnfmPassword) {
            toast.error('Password does not match')
            return;
        }
        setLoader(true);
        axios.post(`${BASE_URL_NEW}/auth/reset-password`, {
            email: state.email,
            password: state.password
        }).then((res) => {
            // console.log(res);
            navigate("/login");
            toast.success('Password updated successfully');
        }).catch((error: any) => {
            console.log(error);
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        })
    }

    return (
        <div className="login" >

            <div className="login-left">
                <div className="login-loginContainer">


                <div style={{ display: "flex", justifyContent: 'center', marginTop:'3rem' }}>
                        <img src={EnlyticalLogo} alt="logo" height={"80px"} />
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-4 my-4   login-loginContainerHeader">
                        <h5 style={{ color: '#000000', fontWeight: '700' }} className="mb-2">Reset Password</h5>
                    </div>

                    <form className="form">
                        {/* <div style={{ color: '#384D6C' }} className="" >
                            OTP has been sent to your registered email address.  {logic.reSendOtp ? <span onClick={reSendOtp} className="resendOtpBtn" >Resend OTP</span> : <span className="resendOtpBtn" >Fetching OTP:{logic.seconds} s</span>}
                        </div> */}


                        <div style={{ position: 'relative' }} className="mb-2 input-labels" >
                            <label className="mb-2 login-label">Enter New Password*</label>
                            <input
                                placeholder="Set Password"
                                className="form-control login-input"
                                type={showPassword ? 'text' : 'password'}
                                onChange={onChange}
                                name={"password"}
                                value={state.password}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>
                        <div style={{ position: 'relative' }} className="input-labels"  >
                            <label className="mb-2 login-label">Confirm New Password*</label>
                            <input
                                placeholder="Confirm Password"
                                className="form-control login-input"
                                type={showPassword ? 'text' : 'password'}
                                onChange={onChange}
                                name={"cnfmPassword"}
                                value={state.cnfmPassword}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-3">
                            <button
                                type="button"
                                className="login-btn"
                                onClick={onClick}>
                                {loader ? (
                            <div className="loader2"></div> 
                            ) : (
                            'Change Password' // Default button text
                            )}   
                            </button>
                        </div>

                    </form>
                    {/*
                        loader && <div style={{ position: 'absolute', top: '50%', right: '25%' }} >
                            <Loader />
                        </div>
                    */}
                </div>
            </div>

            <div className="login-right">
                <div className="right-text">
                    <h2 className="mb-0">
                        <strong>AI enabled </strong>Growth
                    </h2>
                    <h2 className="mb-0">We make E-commerce Faster,</h2>
                    <h2 className="mb-0">Simpler and Better</h2>
                </div>
                <div className="right-content">
                    {/* <div className="right-text">Your command center for tracking, managing, and optimizing all aspects of your business. Whether you're overseeing projects, monitoring performance metrics, or collaborating with your team, Enlytical.ai provides you with the tools and insights you need to stay ahead of the curve.</div> */}
                    <div className="main-enlytical-img-container"><img src={EnlyticalImg} alt="img" className="main-enlytical-img" /></div>
                </div>

            </div>

        </div>
    )
}

export default PasswordChange;