import React from 'react'

const ImagePlaceholder = () => {
  return (
    <div>
    <svg width="72" height="65" viewBox="0 0 72 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60.2714 62.895L5.653 56.8416C3.02695 56.5506 1.13418 54.1859 1.42515 51.5598L6.44529 6.26423C6.7364 3.63818 9.10112 1.74527 11.727 2.03638L66.3454 8.0898C68.9715 8.38091 70.8644 10.7456 70.5733 13.3715L65.5531 58.6671C65.2622 61.2932 62.8974 63.1861 60.2714 62.895Z" fill="url(#paint0_linear_277_12269)"/>
    <path d="M70.5744 13.3716L68.5951 31.2354L64.1957 7.85156L66.3474 8.08987C68.9726 8.38041 70.8651 10.7449 70.5744 13.3716Z" fill="url(#paint1_linear_277_12269)"/>
    <path d="M68.0994 54.4812L14.0938 64.6409C11.4973 65.1294 8.99632 63.4204 8.50784 60.8239L0.0823563 16.0365C-0.406118 13.44 1.30284 10.939 3.89938 10.4506L57.9049 0.290852C60.5015 -0.197622 63.0024 1.51133 63.4909 4.10787L71.9164 48.8952C72.4049 51.4919 70.6959 53.9929 68.0994 54.4812Z" fill="url(#paint2_linear_277_12269)"/>
    <path d="M64.6445 10.2368V55.1312L68.1 54.481C70.6965 53.9926 72.4055 51.4916 71.917 48.8951L64.6445 10.2368Z" fill="url(#paint3_linear_277_12269)"/>
    <path d="M8.24994 59.4575L8.50702 60.8241C8.9955 63.4207 11.4965 65.1296 14.093 64.6411L41.6474 59.4575H8.24994Z" fill="url(#paint4_linear_277_12269)"/>
    <path d="M59.8601 59.7366H4.90742C2.26528 59.7366 0.123474 57.5948 0.123474 54.9526V9.37965C0.123474 6.73751 2.26528 4.5957 4.90742 4.5957H59.8601C62.5023 4.5957 64.6441 6.73751 64.6441 9.37965V54.9526C64.6441 57.5948 62.5023 59.7366 59.8601 59.7366Z" fill="url(#paint5_linear_277_12269)"/>
    <path d="M55.3797 55.2414H9.38657C7.1753 55.2414 5.38263 53.4487 5.38263 51.2374V13.0948C5.38263 10.8835 7.1753 9.09082 9.38657 9.09082H55.3797C57.591 9.09082 59.3836 10.8835 59.3836 13.0948V51.2374C59.3836 53.4487 57.591 55.2414 55.3797 55.2414Z" fill="url(#paint6_linear_277_12269)"/>
    <path d="M35.5857 55.2415H9.38784C7.17615 55.2415 5.38263 53.4494 5.38263 51.2379V44.9851L12.0296 36.9058C14.2232 34.2371 18.3063 34.2371 20.5015 36.9058L24.6401 41.9374L35.5857 55.2415Z" fill="url(#paint7_linear_277_12269)"/>
    <path d="M59.3855 41.1839V51.2381C59.3855 53.4498 57.592 55.2418 55.3803 55.2418H13.6951L24.6409 41.9376L38.0875 25.5917C40.2811 22.9245 44.3658 22.9245 46.5594 25.5917L59.3855 41.1839Z" fill="url(#paint8_linear_277_12269)"/>
    <path d="M19.3731 24.4381C22.509 24.4381 25.0511 21.8959 25.0511 18.7601C25.0511 15.6242 22.509 13.082 19.3731 13.082C16.2372 13.082 13.6951 15.6242 13.6951 18.7601C13.6951 21.8959 16.2372 24.4381 19.3731 24.4381Z" fill="url(#paint9_linear_277_12269)"/>
    <defs>
    <linearGradient id="paint0_linear_277_12269" x1="18.4298" y1="10.4734" x2="59.0642" y2="61.1571" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFA936"/>
    <stop offset="0.411" stop-color="#FF8548"/>
    <stop offset="0.778" stop-color="#FF6C54"/>
    <stop offset="1" stop-color="#FF6359"/>
    </linearGradient>
    <linearGradient id="paint1_linear_277_12269" x1="69.2459" y1="18.9147" x2="65.8496" y2="19.6639" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F82814" stop-opacity="0"/>
    <stop offset="1" stop-color="#C0272D"/>
    </linearGradient>
    <linearGradient id="paint2_linear_277_12269" x1="12.7366" y1="16.5681" x2="66.3756" y2="53.213" gradientUnits="userSpaceOnUse">
    <stop stop-color="#CDEC7A"/>
    <stop offset="0.216" stop-color="#B0E995"/>
    <stop offset="0.561" stop-color="#87E4BB"/>
    <stop offset="0.835" stop-color="#6EE1D2"/>
    <stop offset="1" stop-color="#65E0DB"/>
    </linearGradient>
    <linearGradient id="paint3_linear_277_12269" x1="69.4144" y1="32.6841" x2="63.6197" y2="32.6841" gradientUnits="userSpaceOnUse">
    <stop stop-color="#CDEC7A" stop-opacity="0"/>
    <stop offset="0.235" stop-color="#9AD57D" stop-opacity="0.235"/>
    <stop offset="0.604" stop-color="#51B482" stop-opacity="0.604"/>
    <stop offset="0.868" stop-color="#239F85" stop-opacity="0.868"/>
    <stop offset="1" stop-color="#119786"/>
    </linearGradient>
    <linearGradient id="paint4_linear_277_12269" x1="24.9486" y1="62.3841" x2="24.9486" y2="58.9513" gradientUnits="userSpaceOnUse">
    <stop stop-color="#CDEC7A" stop-opacity="0"/>
    <stop offset="0.235" stop-color="#9AD57D" stop-opacity="0.235"/>
    <stop offset="0.604" stop-color="#51B482" stop-opacity="0.604"/>
    <stop offset="0.868" stop-color="#239F85" stop-opacity="0.868"/>
    <stop offset="1" stop-color="#119786"/>
    </linearGradient>
    <linearGradient id="paint5_linear_277_12269" x1="12.4603" y1="12.2426" x2="58.395" y2="58.1773" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F8F6FB"/>
    <stop offset="1" stop-color="#EFDCFB"/>
    </linearGradient>
    <linearGradient id="paint6_linear_277_12269" x1="15.919" y1="10.7463" x2="60.7209" y2="69.0337" gradientUnits="userSpaceOnUse">
    <stop stop-color="#18CEFB"/>
    <stop offset="0.297" stop-color="#2BB9F9"/>
    <stop offset="0.735" stop-color="#42A0F7"/>
    <stop offset="1" stop-color="#4A97F6"/>
    </linearGradient>
    <linearGradient id="paint7_linear_277_12269" x1="10.6706" y1="41.1526" x2="30.2982" y2="66.6882" gradientUnits="userSpaceOnUse">
    <stop stop-color="#CDEC7A"/>
    <stop offset="0.215" stop-color="#B0E995" stop-opacity="0.784"/>
    <stop offset="0.56" stop-color="#87E4BB" stop-opacity="0.439"/>
    <stop offset="0.833" stop-color="#6EE1D2" stop-opacity="0.165"/>
    <stop offset="0.999" stop-color="#65E0DB" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint8_linear_277_12269" x1="28.0694" y1="37.1919" x2="51.7416" y2="67.9896" gradientUnits="userSpaceOnUse">
    <stop stop-color="#CDEC7A"/>
    <stop offset="0.216" stop-color="#B0E995"/>
    <stop offset="0.561" stop-color="#87E4BB"/>
    <stop offset="0.835" stop-color="#6EE1D2"/>
    <stop offset="1" stop-color="#65E0DB"/>
    </linearGradient>
    <linearGradient id="paint9_linear_277_12269" x1="16.5519" y1="15.0899" x2="24.2286" y2="25.0773" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFD945"/>
    <stop offset="0.304" stop-color="#FFCD3E"/>
    <stop offset="0.856" stop-color="#FFAD2B"/>
    <stop offset="1" stop-color="#FFA325"/>
    </linearGradient>
    </defs>
    </svg>
    
    </div>
  )
}

export default ImagePlaceholder

