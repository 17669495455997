import React, { useEffect, useState } from 'react';
import InfoIcon from '../../../../assets/svgs/InfoIcon';
import Tippy from '@tippyjs/react';
import BlinkitIcon from '../../../../assets/svgs/BlinkitIcon';
import InstamartIcon from '../../../../assets/svgs/InstamartIcon';
import ZeptoIcon from '../../../../assets/svgs/ZeptoIcon';
import Backgroundprofileupload from '../../../../assets/svgs/Backgroundprofileupload';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GreenTick from '../../../../assets/GreenTick';
import CloudUploadIcon from '../../../../assets/svgs/CloudUploadIcon';
import successfullgif from '../../../../assets/successfull.gif';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../../appConstant';
import LoaderSkeletonGraph from '../../../../utils/commonComponents/Loader/LoaderSkeletonGraph';
import LoaderSkeletonOnboarding from '../../../../utils/commonComponents/Loader/LoaderSkeletonOnboarding';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';

const BrandOnboardingModule = () => {
  const userId = useAppSelector(state => state.user._id);

  const brandcurrentaccess = useAppSelector(state => state.client);

  const navigate = useNavigate();
  const [brandName, setBrandName] = useState(''); // Sub-brand input boxes
  const [subBrands, setSubBrands] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingafter, setIsLoadingafter] = useState(false);
  // const [uploadedFile, setUploadedFile] = useState(null);

  const [categories, setCategories] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  const [categorySearch, setCategorySearch] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [citySearch, setCitySearch] = useState('');
  const [logo, setLogo] = useState(null); // State to hold the uploaded image
  const [brandLogoFile, setBrandLogoFile] = useState<File | null>(null); // State to hold the uploaded image


  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);


  const [selectedPlatform, setSelectedPlatform] = useState<string[]>([]);

  // upload file module

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);


  const [errors, setErrors] = useState({
    brandName: false,
    // subBrands: false,
    selectedCategories: false,
    selectedCities: false,
    selectedPlatform: false,
    // uploadedFile: false,
    // logo: false,
  });

  const setStatesToInitial = () => {
    setLogo(null);
    setBrandName('');
    setSubBrands([]);
    setSelectedCategories([]);
    setSelectedCities([]);
    setSelectedPlatform([]);
    setUploadedFile(null);
  }

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL_NEW}/onboard/categories`)
      .then((res: any) => {
        const data = res.data?.sort();
        setCategories(data);
        // toast('categories fetched successful')
      })
      .catch((err: any) => {
        console.log(err)
        // toast('categories not fetched')
      })
      .finally(() => {
        setIsLoading(false);
      })

    axios.get(`${BASE_URL_NEW}/onboard/locations`)
      .then((res: any) => {
        const data = res.data?.sort()
        setCities(data);
      })
      .catch((err: any) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false);
      })

  }, [])



  const handlePublish = async () => {

    const newErrors = {
      brandName: brandName.trim() === "",
      // subBrands: subBrands.length === 0,
      selectedCategories: selectedCategories.length === 0,
      selectedCities: selectedCities.length === 0,
      selectedPlatform: selectedPlatform.length === 0,
      // uploadedFile: !uploadedFile,
      // logo: !logo,
    };
    setErrors(newErrors);

    // Notify the user about missing fields
    if (newErrors.brandName) toast.error("Brand Name is required!");
    // if (newErrors.subBrands) toast.error("At least one Sub Brand is required!");
    if (newErrors.selectedCategories) toast.error("Category Field is required!");
    if (newErrors.selectedCities) toast.error("City Field is required!");
    if (newErrors.selectedPlatform) toast.error("Platform selection is required!");
    // if (newErrors.uploadedFile) toast.error("Product Master File is required!");
    // if (newErrors.logo) toast.error(" Brand Logo is required!");


    // if (Object.values(newErrors).every((value) => !value)) {
    if (Object.values(newErrors).every((value) => !value)) {


      const data = new FormData();
      if (brandLogoFile) data.append('image_url', brandLogoFile); // Append file
      data.append('brand_name', brandName); // String
      data.append('sub_brands', JSON.stringify(subBrands)); // Array as string
      data.append('product_category', JSON.stringify(selectedCategories)); // Array as string
      data.append('city', JSON.stringify(selectedCities)); // Array as string
      data.append('platform', JSON.stringify(selectedPlatform)); // Array as string
      data.append('userId', userId);
      if (uploadedFile) data.append('product_master_url', uploadedFile); // String

      try {
        setIsLoadingafter(true)
        const response = await axios.post(`${BASE_URL_NEW}/onboard`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            // 'Content-Type': 'application/json',
          }
        });
        // Submit data to your API here
        // toast.success("Brand onboarded successfully!");
        setStatesToInitial();
        setIsLoadingafter(false);
        setIsModalOpen1(true);
      } catch (err: any) {
        console.error(err);
        {
          err ?
            toast.error(err.response?.data?.error) :
            toast.error("Failed to submit data.")
        }
      } finally {
        setIsLoadingafter(false);
      }

    }
  }


  // Platform configuration for colors
  const platformStyles = {
    Blinkit: { bgColor: "#F8F0C9" },
    Instamart: { bgColor: "#F7D8BF" },
    Zepto: { bgColor: "#DDBBF0" },
  };

  const handlePlatformClick = (platform: string) => {
    if (!selectedPlatform?.includes(platform)) {
      setSelectedPlatform([...selectedPlatform, platform]);
    } else {
      setSelectedPlatform(selectedPlatform.filter((plat) => plat !== platform));
    }
  };

  const handleAddSubBrand = () => {
    setSubBrands([...subBrands, ""]);
  };

  const handleSubBrandChange = (value: any, index: any) => {
    const updatedSubBrands = [...subBrands];
    updatedSubBrands[index] = value;
    setSubBrands(updatedSubBrands);
  };

  const handleRemoveSubBrand = (index: any) => {
    const updatedSubBrands = subBrands.filter((_, i) => i !== index);
    setSubBrands(updatedSubBrands);
  };

  const handlePicUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setBrandLogoFile(file);
      // console.log(brandLogoFile);
      const reader = new FileReader();
      reader.onload = (event: any) => {
        setLogo(event.target.result); // Update state with the uploaded image
        // console.log(event.target.result);

      };
      reader.readAsDataURL(file);
    }

  };


  const handleCategorySelect = (category: string) => {
    if (!selectedCategories.includes(category)) {
      setSelectedCategories([...selectedCategories, category]); // Add to tags
    }
    setCategorySearch(""); // Clear search input
    setIsCategoryDropdownOpen(false); // Close dropdown
  };
  const handleCitySelect = (city: string) => {
    if (!selectedCities.includes(city)) {
      setSelectedCities([...selectedCities, city]); // Add to tags
    }
    setCitySearch(""); // Clear search input
    setIsCityDropdownOpen(false); // Close dropdown
  };

  // Remove a selected category (tag)
  const handleCategoryRemove = (categoryToRemove: string) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat !== categoryToRemove));
  };
  const handleCityRemove = (cityToRemove: string) => {
    setSelectedCities(selectedCities.filter((ct) => ct !== cityToRemove));
  };





  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
    if (file && allowedExtensions.exec(file.name)) {
      setUploadedFile(file);
      setIsModalOpen(false); // Close modal after upload
    } else {
      toast("Only Excel files (.xls, .xlsx, .csv) are allowed!");
    }
  };

  const downloadSampleTemplate = () => {
    const headers = "adasin,pname,mrp,last_node_amazon_pdp,internal_category,\n"; // Example headers
    const blob = new Blob([headers], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "SampleProductMasterTemplate.csv";
    link.click();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);


  const tippyContent = () => {
    return (
      <div>
        <h6>Instructions</h6>
        <div>
          <ul style={{ color: '#b8b6b6', fontFamily: 'Inter', fontSize: "12px", margin: 0 }}>
            <li>Please choose the .xlsx, .xls and .csv file. Please upload excel with exact headers and their position.</li>
            <li>You can download the attached sample excel.</li>
            <li>Please confirm with all the details, then submit the excel file.</li>
          </ul>
        </div>
      </div>
    )
  }


  return (

    isLoading ? <LoaderSkeletonOnboarding /> :
      <>
        <h3>Brand Onboarding</h3>
        <div className={`brand-onboarding-container ${brandcurrentaccess?.current_brand?.access_role?.toLowerCase() === 'client' ? 'disableclass' : ''}`}>
          {/* Logo Upload */}
          <div className="brand-logo-container">

            <div className="brand-logo">
              {logo ? (
                <img src={logo} alt="Uploaded Logo" />
              ) : (
                <Backgroundprofileupload />
              )}
              <label htmlFor="logo-upload" className="upload-overlay">

                <div className="edit-label">
                  <span>Edit</span>
                  <i className="bi bi-pencil edit-icon" style={{ color: '#06486A' }}></i>
                </div>
              </label>
              <input type="file" id="logo-upload" className="logo-upload-input" accept="image/*" onChange={handlePicUpload} />
            </div>
          </div>


          {/* Brand Name and Sub-Brand Inputs */}
          <div className="brand-name-container">
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ marginBottom: "0.5rem" }}>Brand Name</div>
              <div>
                <input
                  type="text"
                  placeholder="Enter Brand Name"
                  className="brand-name-input"
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setErrors((prev) => ({ ...prev, brandName: false }));
                    }
                  }
                  }
                  style={{ border: errors.brandName ? '1px solid red' : '1px solid #ccc' }}
                />
                {subBrands.length === 0 && (
                  <button
                    className="add-sub-brand-button"
                    onClick={() => handleAddSubBrand()}
                  >
                    Add Sub Brand +
                  </button>
                )}
              </div>
            </div>


            {/* Sub-Brand Fields */}
            {subBrands.length > 0 && <>
              {subBrands.map((subBrand, index) => (
                <div style={{ marginBottom: "1rem" }} key={index}>
                  <div style={{ marginBottom: "0.5rem" }}>Sub Brand Name</div>
                  <div className="sub-brand-row" style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      placeholder="Enter Sub-Brand"
                      value={subBrand}
                      onChange={(e) => handleSubBrandChange(e.target.value, index)}
                      className="sub-brand-input"
                    />
                    {index === subBrands.length - 1 && (<>
                      <div style={{ marginRight: '10px', border: '1px solid #ccc', padding: '0.36rem', borderRadius: '0.2rem' }}>
                        <button
                          className="remove-sub-brand-button"
                          onClick={() => handleRemoveSubBrand(index)}
                        >
                          -
                        </button>
                      </div>
                      <button
                        className="add-sub-brand-button"
                        onClick={() => handleAddSubBrand()}
                      >
                        Add Sub Brand +
                      </button>
                    </>
                    )}
                  </div>


                </div>
              ))}
            </>}
          </div>

          {/* Product Category and City */}
          <div className="dropdown-container">
            <div className="dropdown">
              <div style={{ marginBottom: "0.5rem" }}>Product Category</div>

              {/* Tags Display */}
              <div className="dropdown-tags" style={{ display: "flex", flexWrap: "wrap", padding: "0rem", borderRadius: "0.2rem" }}>
                {selectedCategories.map((category, index) => (
                  <div
                    key={index}
                    className="tag"
                    style={{
                      backgroundColor: "#f0f0f0",
                      fontSize: '0.85rem',
                      padding: "0.3rem 0.4rem",
                      margin: "0.2rem",
                      borderRadius: "0.2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {category}
                    <span
                      onClick={() => handleCategoryRemove(category)}
                      style={{
                        marginLeft: "0.5rem",
                        cursor: "pointer",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      &times;
                    </span>
                  </div>
                ))}

                {/* Search Input */}

                <input type="text" value={categorySearch} placeholder="Search Product Category"
                  onChange={(e) => setCategorySearch(e.target.value)}
                  onFocus={() => {
                    setIsCategoryDropdownOpen(true)
                    if (errors.selectedCategories === true) {
                      setErrors((prev) => ({ ...prev, selectedCategories: false }));
                    }
                  }}
                  onBlur={() => setTimeout(() => setIsCategoryDropdownOpen(false), 200)} // To prevent immediate closure on click
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && categorySearch.trim() !== "") {
                      handleCategorySelect(categorySearch.trim());
                      (e.target as HTMLInputElement).blur();
                    }
                  }}
                  className="dropdown-search"
                  style={{ border: errors.selectedCategories ? "1px solid red" : "1px solid #ccc", }}
                />
              </div>
              {isCategoryDropdownOpen && (
                <div className="dropdown-list">
                  {categories
                    .filter((category) =>
                      category.toLowerCase().includes(categorySearch.toLowerCase()) &&
                      !selectedCategories.includes(category)
                    )
                    .map((category, index) => (
                      <div key={index} className="dropdown-item"
                        //  onMouseDown={() => {setCategorySearch(category);setIsCategoryDropdownOpen(false);}}
                        onMouseDown={() => handleCategorySelect(category)}
                        style={{ cursor: "pointer" }}>
                        {category}
                      </div>
                    ))}

                  {categories.every(
                    (category) =>
                      !category.toLowerCase().includes(categorySearch.toLowerCase()) ||
                      selectedCategories.includes(category)
                  ) && (
                      <div
                        className="dropdown-item"
                        style={{
                          color: "#999",
                          backgroundColor: "#f8f9fa",
                          padding: "8px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        No categories found. Press Enter to add a custom category.
                      </div>
                    )}
                </div>
              )}

            </div>

            {/* cities */}

            <div className="dropdown">
              <div style={{ marginBottom: "0.5rem" }}>City</div>

              <div className="dropdown-tags" style={{ display: "flex", flexWrap: "wrap", padding: "0rem", borderRadius: "0.2rem" }}>
                {selectedCities.map((cities, index) => (
                  <div
                    key={index}
                    className="tag"
                    style={{
                      backgroundColor: "#f0f0f0",
                      fontSize: '0.85rem',
                      padding: "0.3rem 0.4rem",
                      margin: "0.2rem",
                      borderRadius: "0.2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {cities}
                    <span
                      onClick={() => handleCityRemove(cities)}
                      style={{
                        marginLeft: "0.5rem",
                        cursor: "pointer",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      &times;
                    </span>
                  </div>
                ))}

                <input type="text" placeholder="Search City"
                  value={citySearch}
                  onChange={(e) => setCitySearch(e.target.value)}
                  onFocus={() => {
                    setIsCityDropdownOpen(true);
                    if (errors.selectedCities === true) {
                      setErrors((prev) => ({ ...prev, selectedCities: false }));
                    }
                  }}
                  onBlur={() => setTimeout(() => setIsCityDropdownOpen(false), 200)} // To prevent immediate closure on click
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && citySearch.trim() !== "") {
                      handleCitySelect(citySearch.trim());
                      (e.target as HTMLInputElement).blur();
                    }
                  }}
                  className="dropdown-search"
                  style={{ border: errors.selectedCities ? '1px solid red' : '1px solid #ccc' }}
                />
              </div>
              {isCityDropdownOpen && (
                <div className="dropdown-list">
                  {cities
                    .filter((city) => city?.toLowerCase().includes(citySearch.toLowerCase()) && !selectedCities.includes(city))
                    .map((city, index) => (
                      <div key={index} className="dropdown-item"
                        // onMouseDown={() => {setCitySearch(city); setIsCityDropdownOpen(false);}} 
                        onMouseDown={() => handleCitySelect(city)}
                        style={{ cursor: "pointer" }}>
                        {city}
                      </div>
                    ))}
                    {cities.every(
                    (city) =>
                      !city.toLowerCase().includes(citySearch.toLowerCase()) ||
                    selectedCities.includes(city)
                  ) && (
                      <div
                        className="dropdown-item"
                        style={{
                          color: "#999",
                          backgroundColor: "#f8f9fa",
                          padding: "8px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        No city found. Press Enter to add a custom city.
                      </div>
                    )}
                </div>
              )}

            </div>
          </div>

          {/* Buttons for Platforms */}
          <div style={{ marginBottom: "0.5rem" }}>Select Platform : </div>
          <div className="platform-buttons">
            <div style={{ backgroundColor: selectedPlatform?.includes("Instamart") ? platformStyles.Instamart.bgColor : "#ffffff", position: 'relative' }} onClick={() => handlePlatformClick("Instamart")}>
              <InstamartIcon />
              {selectedPlatform?.includes("Instamart") && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    // right: "5px",
                    // width: "12px",
                    // height: "12px",
                    // backgroundColor: "green",
                    // borderRadius: "50%",
                  }}
                >
                  <GreenTick />
                </span>
              )}
            </div>

            <div style={{ backgroundColor: selectedPlatform?.includes("Zepto") ? platformStyles.Zepto.bgColor : "#ffffff", position: 'relative' }} onClick={() => handlePlatformClick("Zepto")}>
              <ZeptoIcon />
              {selectedPlatform?.includes("Zepto") && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    // right: "5px",
                    // width: "12px",
                    // height: "12px",
                    // backgroundColor: "green",
                    // borderRadius: "50%",
                  }}
                >
                  <GreenTick />
                </span>
              )}
            </div>

            <div style={{ backgroundColor: selectedPlatform?.includes("Blinkit") ? platformStyles.Blinkit.bgColor : "#ffffff", position: 'relative' }} onClick={() => handlePlatformClick("Blinkit")}>
              <BlinkitIcon />
              {selectedPlatform?.includes("Blinkit") && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    // right: "5px",
                    // width: "12px",
                    // height: "12px",
                    // backgroundColor: "green",
                    // borderRadius: "50%",
                  }}
                >
                  <GreenTick />
                </span>
              )}
            </div>

          </div>

          {/* Product Master Upload  */}
          <div className="product-master-upload" style={{ marginBottom: "2rem" }}>
            {/* <div style={{ display: "flex", alignItems: "center" }}> */}
            {/* <span style={{ display: 'flex', width: 'fit-content', alignItems: 'center', border: '1px solid #ccc', borderRadius: '1rem', fontWeight: '600', padding: '.5rem', justifyContent: 'space-between', }}>
              <span style={{ borderRight: '1px solid #000000', marginLeft: '1rem', display: 'flex', paddingRight: '1.5rem', alignItems: 'center' }} >Have a Product Master?</span>
              <div style={{ width: '1px', height: '100%', backgroundColor: '#ccc', marginLeft: '1rem', }} ></div>
              <div
                className="upload-button"
                style={{ display: 'flex', alignItems: 'center', padding: '0.5rem 1rem', cursor: 'pointer', color: '#181818', borderRadius: '5px' }}
                onClick={openModal}
              ><i className="bi bi-upload" style={{ marginRight: '.6rem' }}></i>
                Upload
              </div>
            </span> */}

            <span style={{padding: '.5rem',}}></span>



            {uploadedFile && (
              <div className="uploaded-file-preview" style={{ marginTop: '1rem' }}>
                {/* <img
            src={URL.createObjectURL(uploadedFile)}
            alt="Uploaded File Preview"
            className="file-preview-image"
            style={{  border: '1px solid #ccc', padding: '5px', borderRadius: '5px' }}
          /> */}
                {/* <img
            // src="/path/to/excel-logo.png" // Replace with the actual path to the Excel logo
            src={URL.createObjectURL(uploadedFile)}
            alt="Excel Logo"
            style={{ width: "30px", height: "30px", marginRight: "1rem" }}
          /> */}
                <i className="bi bi-file-earmark-excel-fill " style={{ color: 'green', width: "30px", height: "30px", marginRight: ".5rem", marginLeft: '.5rem' }}></i>
                <span style={{ fontSize: "1rem", color: "#181818" }}>{uploadedFile.name}</span>
              </div>
              // from here 

            )}

            {isModalOpen && <>

              <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                  <div className="upload-icon" style={{ marginTop: '1rem' }}>
                    <CloudUploadIcon />
                  </div>
                  <label
                    htmlFor="file-upload"
                    className="file-upload-label"
                    style={{ border: '1.5px dashed #000000', borderRadius: '.5rem', padding: '.7rem .9rem', margin: '.7rem 0', textAlign: 'center', cursor: 'pointer', color: '#5B6871', fontSize: '.85rem' }}
                  >
                    Choose File
                    <input
                      id="file-upload"
                      type="file"
                      accept=".xls,.xlsx,.csv"
                      className="file-upload-input"
                      onChange={handleFileUpload}
                      style={{ display: 'none' }}
                    />
                  </label>
                  <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '.5rem' }}>Upload your Excel file to import data</p>
                  <div
                    className="back-button"
                    style={{ color: '#5595F6', cursor: 'pointer', }}
                    onClick={closeModal}
                  >
                    Back
                  </div>
                </div>

              </div>
            </>}
          </div>

          {/* Publish Button */}
          <div className="publish-button-container" style={{ marginBottom: '1rem' }}>
            <div>
              <button className="publish-button" onClick={() => { handlePublish() }} >
                {/* Publish <i className="bi bi-upload" style={{ marginLeft: '.6rem' }} ></i>  */}
                {isLoadingafter ? (
                        <div className="loader2"></div> 
                        ) : (<>
                          Publish <i className="bi bi-upload" style={{ marginLeft: '.6rem' }} ></i> 
                          </>)}   
                
                </button>
            </div>
            <div style={{ display: 'flex', color: '#06486A', cursor: 'pointer', alignItems: 'center', marginTop: '1rem', fontWeight: '600' }} onClick={() => { navigate('/home/brandsOnboarded'); }}>Already Uploaded ?</div>
          </div>

          {isModalOpen1 && (
            <div
              className="modal-overlay"
              style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}
            >
              <div
                className="modal-content"
                style={{ background: '#fff', border: '2px solid #06D001', borderRadius: '10px', padding: '2rem', textAlign: 'center', width: '500px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', }}
              >
                {/* Close button */}
                <span
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                  }}
                  onClick={closeModal1}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>×</span>
                </span>
                <img
                  src={successfullgif}
                  alt="Success GIF"
                  style={{ width: '220px', height: '190px', margin: '0 auto', display: 'block', }}
                />
                <h2
                  style={{ color: '#06D001', fontWeight: 'bold', marginBottom: '1rem', fontSize: '1.5rem', }}
                >
                  Brand Uploaded Successfully!
                </h2>
                <p
                  style={{ color: '#555', fontSize: '1rem', lineHeight: '1.5', }}>
                  Want to Check Your <span style={{ fontWeight: 'bold' }}>Uploaded Brand?</span>
                </p>
                {/* <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate('/reports/productStatus'); }}>Click Here</span> */}
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { navigate('/home/brandsOnboarded'); }}>Click Here</span>

              </div>
            </div>
          )}


        </div>
      </>
  )


};

export default BrandOnboardingModule;

