import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './authStyle.scss';
import { toast } from 'react-toastify';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import { BASE_URL_NEW } from '../../../appConstant';
import EnlyticalImg from "../../../assets/enlyticalImg.png"


const ForgetPassword2 = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        email: "",
    });

    // useEffect(() => {
    //     const loginEmail = localStorage.getItem("loginEmail");
    //     if (loginEmail) {
    //         setState({
    //             email: loginEmail
    //         })
    //     }
    // }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const { email } = state;
        if (email === "") {
            toast.error('email is required')
            return;
        } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            toast.error('Invalid email')
            return;
        }
        setLoader(true);
        try {
            const res = await axios.post(`${BASE_URL_NEW}/auth/forgot-password`, {
                email: state.email
            });
            // console.log(res);
            setLoader(false);
            toast.success(res.data.message)
            localStorage.setItem('email', state.email);
            navigate(`/verifyOtp`);
        } catch (error: any) {
            setLoader(false);
            console.log(error);
            toast.error(error?.response.data.error || 'no user exist')
        }
    }

    const goBackClick = (() => {
        navigate('/login');
    })
    return (
        <div className='login'>

            <div className="login-left">
                <div className="login-loginContainer ">
                    <div style={{ display: "flex", justifyContent: 'center', marginTop:'3rem' }}>
                        <img src={EnlyticalLogo} alt="logo" height={"80px"} />
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-4 my-4   login-loginContainerHeader">
                        <h5 style={{ color: '#000000', fontWeight: '700' }} className="mb-2">Forget Password</h5>
                    </div>

                    <form className='form'>
                        <div className="mb-2 forgot-entries"  >
                            <label className="mb-1 login-label">Email address* </label>
                            <input
                                placeholder="name@example.com"
                                className='form-control login-input'
                                type="email"
                                onChange={onChange}
                                name={"email"}
                                value={state.email}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <button
                                style={{ backgroundColor: "var(--secondary-color)", color: "#fff", fontSize: '17px' }}
                                className="btn btn-m col-lg-5 " onClick={goBackClick}>&larr; Go Back
                            </button> */}
                            <button
                                // style={{ backgroundColor: "var(--active-color)", color: "#fff", fontSize: '17px', }}
                                // className="btn btn-m col-lg-5" onClick={onClick}>Confirm Email
                                className="login-btn" onClick={onClick} disabled={loader}>
                                  {loader ? (
                             <div className="loader2"></div> 
                              ) : (
                              'Confirm Email' // Default button text
                               )}   

                            </button>
                        </div>
                    </form>

                    <div className="back-btn">
                        <button onClick={goBackClick} >Back</button>
                    </div>
                </div>



                {/* {
                    loader &&
                    <div style={{ position: 'absolute', top: '50%', right: '25%' }}>
                        <Loader />
                    </div>
                } */}
            </div>

            <div className="login-right">
                <div className="right-text">
                    <h2 className="mb-0">
                        <strong>AI enabled </strong>Growth
                    </h2>
                    <h2 className="mb-0">We make E-commerce Faster,</h2>
                    <h2 className="mb-0">Simpler and Better</h2>
                </div>
                <div className="right-content">
                {/* <div className="right-text">Your command center for tracking, managing, and optimizing all aspects of your business. Whether you're overseeing projects, monitoring performance metrics, or collaborating with your team, Enlytical.ai provides you with the tools and insights you need to stay ahead of the curve.</div> */}
                <div className="main-enlytical-img-container"><img src={EnlyticalImg} alt="img" className="main-enlytical-img" /></div>
                </div>
            </div>

        </div >
    )
}

export default ForgetPassword2