import { useEffect, useState } from 'react';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import { brandDetails } from '../../../redux/slices/clientSlice';
import { userDetails } from '../../../redux/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';





const UserRequest = ({setIsOpenRequest}) => {
  const [isLoading,setIsLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);


   const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)
    const brands = useAppSelector(state => state.client)
    const curragency=brands.current_brand?.agency?._id;



  useEffect(() => {
    fetchUserRequests();
  }, [curragency]);

  const fetchUserRequests = async () => {
    setIsLoading(true);
       axios.get(`${BASE_URL_NEW}/request?agency=${curragency}`)
                .then((res) => {
                    console.log('reqqqqssss',res.data)
                  setRequests(res.data);
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setIsLoading(false)
                })
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRequests(requests.map((req) => req._id));
    } else {
      setSelectedRequests([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedRequests((prev) =>  
        prev.includes(id) ? prev.filter((reqId) => reqId !== id) : [...prev, id]
    );
  };

  const updateRequests = async (status) => {
    if (selectedRequests.length === 0) {
      toast("Select at least one request");
      return;
    }
        
    const finalUpdate = requests.filter((req) => selectedRequests.includes(req._id));

    const formattedUpdate = finalUpdate.map((res)=>{
      return{
        access_id: res.access_id , subscription_id: res.subscription_id, request_type: res.request_type
      }
    })
    setIsLoading(true);
    axios.post(`${BASE_URL_NEW}/request/approval?approval=${status}`,finalUpdate)
    .then((res) => {
        toast.success('Requests Updated Successfully')
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        setSelectedRequests([]);
        setIsLoading(false)
        setIsOpenRequest(false);
    })

  };

  return (
    <div style={{ height: '100%' }}>
    <div className="modal-overlay">
    <div className="modal-box">
    {isLoading?<Loader/>:
    <>
    <button className="modal-close" onClick={() => {setIsOpenRequest(false); }}>
      ✖
    </button>
    
    <div><h5>User Requests</h5></div>

    <div className='request-card'> 
    
    {requests.length>0?
    <div style={{display:'flex',alignItems:'center',marginBottom:'.5rem',gap:'.2rem'   }}>
      <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={selectedRequests.length === requests.length && requests.length > 0}
      />&nbsp;
         <b> Select All</b>               
    </div>
    :<div style={{color:'#ccc'}}>No Pending Requests</div>
    }

    {requests.map((req) => (
        <div key={req._id} style={{display:'flex',marginBottom:'.5rem', alignItems:'center',gap:'.5rem'  }} >
          <input
            type="checkbox"
            checked={selectedRequests.includes(req._id)}
            onChange={() => handleCheckboxChange(req._id)}
          />&nbsp;
          {/* {req.user.email} has requested <b>{req.access_right}</b> access for <b>{req.module}</b>
           */}
           {
            req.request_type==='BRAND'? 
            <b>{req.user.first_name} : has requested {req.request_type} access of {req.access_role} for {req.brand?.client_name}</b>
            : 
            <b>{req.user.first_name} : has requested {req.module_name} {req.request_type} access of {req.access_right} for {req.brand?.client_name}</b>
           }
          {/* <b>{req.user.first_name?req.user.first_name + (req.user.last_name?req.user.last_name:'') : req.user.email.split('@')[0]}</b> has requested <b>{req.access_role}</b> access for brand <b>{req.brand?.client_name}</b> */}
        </div>
      ))}
                
    </div>

    
    <div style={{display:'flex',gap:'.5rem'}}>
    <button className="submit-btn" style={{ backgroundColor: requests.length>0 ? '#36BE13' :'#ccc' }} onClick={() => updateRequests("YES")} disabled={!requests.length>0} >
          Approve <i class="bi bi-check2" style={{fontSize:'1rem'}}></i>
    </button>
    <button className="submit-btn" style={{ backgroundColor: requests.length>0 ? '#ED5050' :'#ccc' }} onClick={() => updateRequests("NO")}   disabled={!requests.length>0}>
         Reject <i class="bi bi-x" style={{fontSize:'1rem'}} ></i>
    </button>  
    </div>   
              
    </>
  }
    </div>
    </div>

    </div>
  )
}

export default UserRequest