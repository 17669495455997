import React, { useState } from 'react'
import "./Search.scss"
import axios from 'axios';
import { ENLYTICAL_CRAWLING_BASE_URL } from '../../../appConstant';
import { toast } from 'react-toastify';
import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver'
import Tippy from '@tippyjs/react';

const crawledDataObj = [
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 1,
        "pname": "Candy Crew Orange Candy 500 grams | Narangee | Vegetarian | No Transfat | Sweet Toffee",
        "location": "110056",
        "platform_code": "B09M3X2NMM",
        "sponsor": "",
        "lightning": "Limited time deal",
        "sp": "179",
        "mrp": "199",
        "offer": "10%",
        "rating": "4.4",
        "review": "744",
        "prod_url": "https://www.amazon.in/dp/B09M3X2NMM"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 2,
        "pname": "Candy Cane Lane",
        "location": "110056",
        "platform_code": "B0CGNPR7CL",
        "sponsor": "",
        "lightning": "",
        "sp": "",
        "mrp": "",
        "offer": "",
        "rating": "",
        "review": "",
        "prod_url": "https://www.amazon.in/dp/B0CGNPR7CL"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 3,
        "pname": "Pulse Kachcha Aam Candy , 190gram / 209gram (Weight May Vary), Mango",
        "location": "110056",
        "platform_code": "B0B8NBVR16",
        "sponsor": "",
        "lightning": "",
        "sp": "41",
        "mrp": "60",
        "offer": "32%",
        "rating": "4.3",
        "review": "1742",
        "prod_url": "https://www.amazon.in/dp/B0B8NBVR16"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 4,
        "pname": "Cadbury Choclairs Gold Candy, 525 g (105 Candies)",
        "location": "110056",
        "platform_code": "B071CY3SJ8",
        "sponsor": "",
        "lightning": "",
        "sp": "170",
        "mrp": "200200",
        "offer": "15% 10%",
        "rating": "4.3",
        "review": "9913",
        "prod_url": "https://www.amazon.in/dp/B071CY3SJ8"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 5,
        "pname": "Kitchen Jungle Imli Candy 350gm, Khatti Meethi Imly, Chulbuli Emli Tamarind Twist Candy, Twisted Emly Candy Jar Pack of 1",
        "location": "110056",
        "platform_code": "B0DG56M4G6",
        "sponsor": "",
        "lightning": "",
        "sp": "149",
        "mrp": "299",
        "offer": "50%",
        "rating": "4.0",
        "review": "201",
        "prod_url": "https://www.amazon.in/dp/B0DG56M4G6"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 6,
        "pname": "DABUR Hajmola Maha Candy Aam/Imli Jar - 500 Candies ( 100 Aam + 400 Imli Candies ) |Tasty & Chatpata, Relief From Constipation & Gas, With Best Ayurvedic Ingredients, (500N x 3.2g = 1.600KG)",
        "location": "110056",
        "platform_code": "B076ZQG916",
        "sponsor": "",
        "lightning": "",
        "sp": "450",
        "mrp": "500500",
        "offer": "10% 10%",
        "rating": "4.4",
        "review": "485",
        "prod_url": "https://www.amazon.in/dp/B076ZQG916"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 7,
        "pname": "Astonished Retail Fox's Crystal Clear Candy- Mints Candy, Fruits Candy, Berries Candy And Fruity Mints Candy (4 Packs X90G), 360 Gram",
        "location": "110056",
        "platform_code": "B0D1QW5GHT",
        "sponsor": "",
        "lightning": "",
        "sp": "299",
        "mrp": "499",
        "offer": "40%",
        "rating": "4.3",
        "review": "44",
        "prod_url": "https://www.amazon.in/dp/B0D1QW5GHT"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 8,
        "pname": "Candyman Toffichoo, Strawberry - 100 Soft Toffees for In-home Serves, 240 grams",
        "location": "110056",
        "platform_code": "B079GXKM4R",
        "sponsor": "",
        "lightning": "",
        "sp": "50",
        "mrp": "50",
        "offer": "",
        "rating": "4.0",
        "review": "851",
        "prod_url": "https://www.amazon.in/dp/B079GXKM4R"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 9,
        "pname": "Candyman Fruitee Fun, Exciting Fruit Flavors May Vary - 300/350 Candies For In-Home Serves 750 Gram/875 Gram (Weight May Vary)",
        "location": "110056",
        "platform_code": "B07J33LHT7",
        "sponsor": "",
        "lightning": "",
        "sp": "133",
        "mrp": "150",
        "offer": "11%",
        "rating": "4.1",
        "review": "599",
        "prod_url": "https://www.amazon.in/dp/B07J33LHT7"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 10,
        "pname": "KOPIKO Cappuccino Coffee Candy - World's No 1 Coffee Hard Candy* - Pocket Pack -504 gm |24 Units X 6 Pieces in Each|",
        "location": "110056",
        "platform_code": "B09R1WKTKM",
        "sponsor": "",
        "lightning": "",
        "sp": "240",
        "mrp": "240",
        "offer": "",
        "rating": "4.4",
        "review": "256",
        "prod_url": "https://www.amazon.in/dp/B09R1WKTKM"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 11,
        "pname": "Fox's Crystal Clear Candy- Mints Candy, Fruits Candy, Berries Candy And Fruity Mints Candy 90 Grams Each- Combo Pack Of 4",
        "location": "110056",
        "platform_code": "B0B12M1912",
        "sponsor": "",
        "lightning": "",
        "sp": "305",
        "mrp": "596",
        "offer": "49%",
        "rating": "4.2",
        "review": "134",
        "prod_url": "https://www.amazon.in/dp/B0B12M1912"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 12,
        "pname": "ALPENLIEBE Creamfills - Butter Toffee, 40 pcs (144 g)",
        "location": "110056",
        "platform_code": "B00X9YX1FU",
        "sponsor": "",
        "lightning": "",
        "sp": "40",
        "mrp": "5050",
        "offer": "20% 2%",
        "rating": "4.5",
        "review": "1854",
        "prod_url": "https://www.amazon.in/dp/B00X9YX1FU"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 13,
        "pname": "GO DESi Popz Imli Pop, Tamarind & Jaggery Candy, 50 Pieces, Tangy Imli, Imli Candy, Lollipop, Digestive Candy, 400 g",
        "location": "110056",
        "platform_code": "B07LBKXXVP",
        "sponsor": "",
        "lightning": "",
        "sp": "249",
        "mrp": "250250",
        "offer": "",
        "rating": "4.0",
        "review": "8824",
        "prod_url": "https://www.amazon.in/dp/B07LBKXXVP"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 14,
        "pname": "Alpenliebe Juicy Fills, Orange & Mango Flavour, Assorted Candy Pouch, 144 g/174.8g, 40 pc",
        "location": "110056",
        "platform_code": "B08RFQGWMN",
        "sponsor": "",
        "lightning": "",
        "sp": "47",
        "mrp": "50",
        "offer": "6%",
        "rating": "4.2",
        "review": "782",
        "prod_url": "https://www.amazon.in/dp/B08RFQGWMN"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 15,
        "pname": "Swad Candy Jar (Digestive & Tangy Indian Masala Flavour Sweet Toffee) Vegan & Gluten Free, 200 Candies Jar",
        "location": "110056",
        "platform_code": "B071XP3TCY",
        "sponsor": "",
        "lightning": "Limited time deal",
        "sp": "378",
        "mrp": "400",
        "offer": "6%",
        "rating": "4.1",
        "review": "6993",
        "prod_url": "https://www.amazon.in/dp/B071XP3TCY"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 16,
        "pname": "Patanjali Amla Chatpata Candy 500 gram",
        "location": "110056",
        "platform_code": "B0CKL9GS4M",
        "sponsor": "",
        "lightning": "",
        "sp": "180",
        "mrp": "180",
        "offer": "",
        "rating": "4.3",
        "review": "195",
        "prod_url": "https://www.amazon.in/dp/B0CKL9GS4M"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 17,
        "pname": "HARIBO Candy Share Size Goldbears Gummy (Golden, White, Red, Clear, Strawberry, Pineapple, Orange, Raspberry, Lemon, 160 grams)",
        "location": "110056",
        "platform_code": "B07GJ8W1VF",
        "sponsor": "",
        "lightning": "",
        "sp": "225",
        "mrp": "500",
        "offer": "55%",
        "rating": "4.1",
        "review": "5045",
        "prod_url": "https://www.amazon.in/dp/B07GJ8W1VF"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 18,
        "pname": "Alpenliebe Juzt Jelly, Strawberry Pouch, 148 g, 40 pc",
        "location": "110056",
        "platform_code": "B00X9YWB6U",
        "sponsor": "",
        "lightning": "",
        "sp": "50",
        "mrp": "50",
        "offer": "26%",
        "rating": "4.4",
        "review": "2152",
        "prod_url": "https://www.amazon.in/dp/B00X9YWB6U"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 19,
        "pname": "Chupa Chups Sour Bites, Mixed Fruit Punch Flavour, Soft & Chewy Toffee, 30 Pc, 61.6 G,62 Grams",
        "location": "110056",
        "platform_code": "B07HVZBTC6",
        "sponsor": "",
        "lightning": "",
        "sp": "35",
        "mrp": "35",
        "offer": "",
        "rating": "4.3",
        "review": "1312",
        "prod_url": "https://www.amazon.in/dp/B07HVZBTC6"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 20,
        "pname": "Patanjali Amla Candy 500 Gram",
        "location": "110056",
        "platform_code": "B0CKLCTLP3",
        "sponsor": "",
        "lightning": "",
        "sp": "175",
        "mrp": "175",
        "offer": "",
        "rating": "4.0",
        "review": "164",
        "prod_url": "https://www.amazon.in/dp/B0CKLCTLP3"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 21,
        "pname": "Fox's Crystal Clear Fruits Candy 90g (90Gms- Pack of 2) & Fox's Crystal Clear Berries Candy (90Gms- Pack of 2)",
        "location": "110056",
        "platform_code": "B0B916KXH7",
        "sponsor": "",
        "lightning": "",
        "sp": "309",
        "mrp": "596",
        "offer": "48%",
        "rating": "4.2",
        "review": "41",
        "prod_url": "https://www.amazon.in/dp/B0B916KXH7"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 22,
        "pname": "Candy Crew Mango Candy 500Grams Vegetarian No Transfat Sweet Toffee",
        "location": "110056",
        "platform_code": "B09TXRVT45",
        "sponsor": "",
        "lightning": "Limited time deal",
        "sp": "179",
        "mrp": "199",
        "offer": "10%",
        "rating": "4.3",
        "review": "80",
        "prod_url": "https://www.amazon.in/dp/B09TXRVT45"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 23,
        "pname": "Chocoliebe Eclairs Plus Pouch, 40 Pieces, 136 Grams",
        "location": "110056",
        "platform_code": "B07Z5XHK8Y",
        "sponsor": "",
        "lightning": "",
        "sp": "47",
        "mrp": "50",
        "offer": "6%",
        "rating": "4.3",
        "review": "785",
        "prod_url": "https://www.amazon.in/dp/B07Z5XHK8Y"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 24,
        "pname": "\"NUTRIEFOODS\" Enriching Your Taste Aam Papad | 100% Natural Fruit Candy | Premium Mango Fruit Bar, Mango Toffee/Mango Candy (400Gram), Fresh",
        "location": "110056",
        "platform_code": "B0BJW1DCV5",
        "sponsor": "",
        "lightning": "Limited time deal",
        "sp": "359",
        "mrp": "399",
        "offer": "10%",
        "rating": "4.4",
        "review": "256",
        "prod_url": "https://www.amazon.in/dp/B0BJW1DCV5"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 25,
        "pname": "Parle Bigger Kaccha Mango Bite,198 g/214.5 g/ 217.8g",
        "location": "110056",
        "platform_code": "B08LNXHH5N",
        "sponsor": "",
        "lightning": "",
        "sp": "50",
        "mrp": "50",
        "offer": "2%",
        "rating": "4.4",
        "review": "721",
        "prod_url": "https://www.amazon.in/dp/B08LNXHH5N"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 26,
        "pname": "Mentos, Rainbow Assorted, Chewy Dragees Pouch, 108g, (40pc x 2.7 Grams Each)",
        "location": "110056",
        "platform_code": "B00X9Z6D4A",
        "sponsor": "",
        "lightning": "",
        "sp": "47",
        "mrp": "50",
        "offer": "6%",
        "rating": "4.3",
        "review": "2287",
        "prod_url": "https://www.amazon.in/dp/B00X9Z6D4A"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 27,
        "pname": "Halloween Pacl Chupa Chups Halloween Meet The Creeps Candy Gifting Box (Assorted Pack Of Chupa Chups Jelly & Lollipop And Fruitella Toffee) 231 G",
        "location": "110056",
        "platform_code": "B0CG947C7H",
        "sponsor": "",
        "lightning": "",
        "sp": "200",
        "mrp": "200",
        "offer": "",
        "rating": "5.0",
        "review": "7",
        "prod_url": "https://www.amazon.in/dp/B0CG947C7H"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 28,
        "pname": "Alpenliebe Gold, Cream Strawberry Candy Pouch, 324 Grams, 90 Pc",
        "location": "110056",
        "platform_code": "B01I0Y2078",
        "sponsor": "",
        "lightning": "",
        "sp": "92",
        "mrp": "105100",
        "offer": "12% 1%",
        "rating": "4.4",
        "review": "4364",
        "prod_url": "https://www.amazon.in/dp/B01I0Y2078"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 29,
        "pname": "Cadbury Choclairs Chocolate Gold Candy, 137 G|25 Pieces - 5.5 Gram Each",
        "location": "110056",
        "platform_code": "B01AM5R8KC",
        "sponsor": "",
        "lightning": "",
        "sp": "45",
        "mrp": "50",
        "offer": "10%",
        "rating": "4.4",
        "review": "3462",
        "prod_url": "https://www.amazon.in/dp/B01AM5R8KC"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 30,
        "pname": "Swad Mixed Assorted Candy Jar (Kaccha Mango, Imli, Coffee, Cola, Pan, Orange) 100% Vegan & Gluten Free, Digestive & Tasty Masala Toffee | Indian Sweets, 200 Candies Jar",
        "location": "110056",
        "platform_code": "B079J3X2VL",
        "sponsor": "",
        "lightning": "Limited time deal",
        "sp": "378",
        "mrp": "400",
        "offer": "6%",
        "rating": "3.7",
        "review": "758",
        "prod_url": "https://www.amazon.in/dp/B079J3X2VL"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 31,
        "pname": "Cadbury Dairy Milk Shots, Chocolate Balls, Carton Pack (58 X 3.6 Grams), 208.8 Grams",
        "location": "110056",
        "platform_code": "B07F7PZYQ7",
        "sponsor": "",
        "lightning": "",
        "sp": "104",
        "mrp": "116",
        "offer": "10%",
        "rating": "4.4",
        "review": "6397",
        "prod_url": "https://www.amazon.in/dp/B07F7PZYQ7"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 32,
        "pname": "Parle Rol-a-Cola,100 gram",
        "location": "110056",
        "platform_code": "B08KR6YM4V",
        "sponsor": "",
        "lightning": "",
        "sp": "24",
        "mrp": "30",
        "offer": "20%",
        "rating": "4.3",
        "review": "1265",
        "prod_url": "https://www.amazon.in/dp/B08KR6YM4V"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 33,
        "pname": "Parle Bigger Mango Bite 214.5 Gram",
        "location": "110056",
        "platform_code": "B08BLJJXMX",
        "sponsor": "",
        "lightning": "",
        "sp": "47",
        "mrp": "50",
        "offer": "6%",
        "rating": "4.4",
        "review": "289",
        "prod_url": "https://www.amazon.in/dp/B08BLJJXMX"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 34,
        "pname": "Alpenliebe Eclairs Plus Pouch, Birthday Pack, 425 g",
        "location": "110056",
        "platform_code": "B09QM6TX4J",
        "sponsor": "",
        "lightning": "",
        "sp": "132",
        "mrp": "150",
        "offer": "12%",
        "rating": "4.2",
        "review": "315",
        "prod_url": "https://www.amazon.in/dp/B09QM6TX4J"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 35,
        "pname": "Amul Chocomini Chocolate, 250 g",
        "location": "110056",
        "platform_code": "B08G5FTVMF",
        "sponsor": "",
        "lightning": "",
        "sp": "140",
        "mrp": "140",
        "offer": "11%",
        "rating": "4.3",
        "review": "4983",
        "prod_url": "https://www.amazon.in/dp/B08G5FTVMF"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 36,
        "pname": "Organic Purify Orange Candy | Narangee Candy | Narangee Toffee | SantraToffee | Vegetarian | Orange Flavoured Toffee (Pack of 400gm) Jar Pack",
        "location": "110056",
        "platform_code": "B09LQSFT4P",
        "sponsor": "",
        "lightning": "Limited time deal",
        "sp": "156",
        "mrp": "400",
        "offer": "61%",
        "rating": "3.9",
        "review": "385",
        "prod_url": "https://www.amazon.in/dp/B09LQSFT4P"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 37,
        "pname": "Kitchen Jungle Pachak Delicious Anardana Goli (350 Grams) Churan Diegestive Mouth Freshner Mukhwas, Pomegranate",
        "location": "110056",
        "platform_code": "B0DG58H9T9",
        "sponsor": "",
        "lightning": "",
        "sp": "135",
        "mrp": "299",
        "offer": "55%",
        "rating": "4.1",
        "review": "57",
        "prod_url": "https://www.amazon.in/dp/B0DG58H9T9"
    },
    {
        "platform": "Amazon",
        "keyword": "candy",
        "region": "INDIA",
        "rank": 38,
        "pname": "Kitchen Jungle Imli Candy 400gm, Khatti Meethi Imly, Chulbuli Emli Tamarind Twist Candy, Twisted Emly Candy jar pack of 1",
        "location": "110056",
        "platform_code": "B08H8PH8JJ",
        "sponsor": "",
        "lightning": "",
        "sp": "199",
        "mrp": "299",
        "offer": "33%",
        "rating": "4.1",
        "review": "1234",
        "prod_url": "https://www.amazon.in/dp/B08H8PH8JJ"
    }]

const SearchCrawler = (props: any) => {


    const [categorySearch, setCategorySearch] = useState('');
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const handleCategorySelect = (category: string) => {
        if (!selectedCategories.includes(category)) {
            setSelectedCategories([...selectedCategories, category]); // Add to tags
        }
        setCategorySearch(""); // Clear search input
    };

    const handleCategoryRemove = (categoryToRemove: string) => {
        setSelectedCategories(selectedCategories.filter((cat) => cat !== categoryToRemove));
    };





    const [pincodeSearch, setPincodeSearch] = useState('');
    const [selectedPincodes, setSelectedPincodes] = useState<string[]>([]);

    const handlePincodeSelect = (pincode: string) => {
        if (!selectedCategories.includes(pincode)) {
            setSelectedPincodes([...selectedPincodes, pincode]); // Add to tags
        }
        setPincodeSearch(""); // Clear search input
    };

    const handlePincodeRemove = (categoryToRemove: string) => {
        setSelectedPincodes(selectedPincodes.filter((cat) => cat !== categoryToRemove));
    };




    const [crawledData, setCrawledData] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async () => {

        const data: any[any] = []

        selectedCategories.forEach((keyword: any) => {
            selectedPincodes.forEach((pincode) => {
                data.push({ keyword, location: pincode })
            })
        })


        try {
            setIsLoading(true)
            const response = await axios.post(`${ENLYTICAL_CRAWLING_BASE_URL}/amazon/search`, { inputs: data }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            console.log(response);

            if (response.status == 201) {
                setCrawledData(response.data.data)
            }


        } catch (err: any) {
            console.error(err?.response?.data);
            {
                err?.response?.data?.detail ?
                    toast.error(err?.response?.data.detail) :
                    toast.error("Failed to submit data.")
            }
        } finally {
            setIsLoading(false);
        }

    }


    const [columns, setColumns] = useState<any[]>([
        { header: 'Platform', field: 'platform' },
        { header: 'Keyword', field: 'keyword' },
        { header: 'Region', field: 'region' },
        { header: 'Rank', field: 'rank' },
        { header: 'Pname', field: 'pname' },
        { header: 'Location', field: 'location' },
        { header: 'PlatformCode', field: 'platform_code' },
        { header: 'Sponsor', field: 'sponsor' },
        { header: 'Lightning', field: 'lightning' },
        { header: 'Sp', field: 'sp' },
        { header: 'Mrp', field: 'mrp' },
        { header: 'Offer', field: 'offer' },
        { header: 'Rating', field: 'rating' },
        { header: 'Review', field: 'review' },
        { header: 'ProdUrl', field: 'prod_url' }
    ])


    const exportToCSV = () => {
        let csvContent = '';
        const header = columns.map((header: any, i: number) => header.header).join(',')
        csvContent += header + '\n';
        crawledData.forEach((row: any) => {
            const rowData = columns.map((hd: any) => {
                if (row[hd.field]) return `"${row[hd.field]}"`
            }).join(',');
            csvContent += rowData + '\n';
        });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'product_master.csv');
    };


    return (
        <div className='searchCrawler' >


            <div className='searchCrawler-keywords'>
                <input type="text" value={categorySearch} placeholder="Keywords"
                    onChange={(e) => setCategorySearch(e.target.value)}

                    onKeyDown={(e) => {
                        if (e.key === "Enter" && categorySearch.trim() !== "") {
                            handleCategorySelect(categorySearch.trim());
                            (e.target as HTMLInputElement).blur();
                        }
                    }}
                    className="dropdown-search"
                />

                <div className='searchCrawler-keywords-selected'>
                    {selectedCategories.map((category, index) => (
                        <div
                            key={index}
                            className="tag"
                            style={{
                                backgroundColor: "#f0f0f0",
                                fontSize: '0.85rem',
                                padding: "0.3rem 0.4rem",
                                margin: "0.2rem",
                                borderRadius: "0.2rem",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {category}
                            <span
                                onClick={() => handleCategoryRemove(category)}
                                style={{
                                    marginLeft: "0.5rem",
                                    cursor: "pointer",
                                    color: "red",
                                    fontWeight: "bold",
                                }}
                            >
                                &times;
                            </span>
                        </div>
                    ))}
                </div>

            </div>


            <div className='searchCrawler-pincodes'>



                <input type="text" value={pincodeSearch} placeholder="Pincodes"
                    onChange={(e) => setPincodeSearch(e.target.value)}

                    onKeyDown={(e) => {
                        if (e.key === "Enter" && pincodeSearch.trim() !== "") {
                            handlePincodeSelect(pincodeSearch.trim());
                            (e.target as HTMLInputElement).blur();
                        }
                    }}
                    className="dropdown-search"
                />

                <div className='searchCrawler-pincodes-selected'>
                    {selectedPincodes.map((category, index) => (
                        <div
                            key={index}
                            className="tag"
                            style={{
                                backgroundColor: "#f0f0f0",
                                fontSize: '0.85rem',
                                padding: "0.3rem 0.4rem",
                                margin: "0.2rem",
                                borderRadius: "0.2rem",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {category}
                            <span
                                onClick={() => handlePincodeRemove(category)}
                                style={{
                                    marginLeft: "0.5rem",
                                    cursor: "pointer",
                                    color: "red",
                                    fontWeight: "bold",
                                }}
                            >
                                &times;
                            </span>
                        </div>
                    ))}
                </div>
            </div>


            <button onClick={() => { handleSubmit() }}>Submit</button>




            {
                isLoading ? <LoaderSkeletonTable height='350px' /> :
                    crawledData.length > 0 &&
                    <div>
                        <Tippy content='Download *.csv file'>
                            <button onClick={exportToCSV} className='downloadBtn'>Download</button>
                        </Tippy>
                        <PrimeReactProvider>
                            <DataTable
                                value={crawledData}
                                size='small'
                                columnResizeMode="expand"
                                resizableColumns
                                showGridlines
                                id='unique-table'
                                scrollable scrollHeight="60vh"
                                paginator rows={100}
                            // rowsPerPageOptions={[6, 8, 12, 16, 18]}

                            >
                                {columns.map((col: any, index: any) => (

                                    <Column
                                        key={index}
                                        field={col.field}
                                        header={col.header}
                                        body={col?.body}
                                        style={{ width: '150px' }}
                                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                    // bodyStyle={column?.bodyStyle}
                                    />

                                ))}

                            </DataTable>
                        </PrimeReactProvider>
                    </div>

            }





        </div>
    )
}

export default SearchCrawler
