import { useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title, Tooltip, CategoryScale,
  ChartData,
  ChartOptions,
  ActiveElement,
  ChartEvent,
  TooltipItem,
  Legend,
} from 'chart.js';
import { State } from '../types/type';

interface Props {
  graphData: any,
  dataFor: string
}

interface DataSet {
  label: string | undefined;
  data: number[] | never[] | string[];
  borderColor: string;
  backgroundColor: string;
  borderWidth: number;
  yAxisID: string;
}


ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip,Legend,
  {
    id: 'line',
    afterDraw: function (chart: any) {
      if (chart.config.type === 'line') {
        if (chart.tooltip?._active && chart.tooltip?._active.length) {
          const activePoint = chart.tooltip?._active[0];
          const { ctx } = chart;
          const x = activePoint.element.x;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(56, 77, 108, 0.8)';
          ctx.setLineDash([5, 5]);
          ctx.stroke();
          ctx.restore();
        }
      }
    }
  }
);



const LineGraph: React.FC<Props> = (props) => {

  const { graphData, dataFor } = props;
  // console.log(graphData);

  const chartRef = useRef<ChartJS>(null);

  const [yAxisObj] = graphData?.datasets?.filter((el: DataSet) => el.yAxisID === "y");
  // console.log(yAxisObj)
  const [y1AxisObj] = graphData?.datasets?.filter((el: DataSet) => el.yAxisID === "y1");
  const [y2AxisObj] = graphData?.datasets?.filter((el: DataSet) => el.yAxisID === "y2");
  const [y3AxisObj] = graphData?.datasets?.filter((el: DataSet) => el.yAxisID === "y3");

  const getLabel = (axisName: string) => {
    if (axisName === "y") {
      return yAxisObj?.label
    } else if (axisName === "y1") {
      return y1AxisObj?.label
    } else if (axisName === "y2") {
      return y2AxisObj?.label
    } else if (axisName === "y3") {
      return y3AxisObj?.label
    }
  }


  const tooltipCallback = (tooltipItem: any, data: any) => {
    const label = data.datasets[tooltipItem.datasetIndex].label || '';
    const dataValue = (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex] || 0)?.toFixed(2);
    // console.log(label, dataValue);

    if (label === 'Sales') {
      return `${label}:  ₹${dataValue.toLocaleString("en-IN")}`
    } else if (label === 'Spend') {
      return `Spend:  ₹${dataValue.toLocaleString("en-IN")}`;
    } else if (label === 'ACOS') {
      return `${label}:  ${dataValue}%`;
    } if (label === 'Orders') {
      return `${label}:  ${dataValue}`;
    } if (label === 'Clicks') {
      return `${label}:  ${dataValue}`;
    } if (label === 'CPC') {
      return `${label}:  ₹${dataValue.toLocaleString("en-IN")}`;
    } if (label === 'Impressions') {
      return `${label}:  ${dataValue}`;
    } if (label === 'CTR') {
      return `${label}:  ${dataValue}%`;
    } if (label === 'Conversions') {
      return `${label}:  ${dataValue}%`;
    } if (label === 'ROAS') {
      return `${label}:  ${dataValue}`;
    } if (label === 'TOS_IS') {
      return `${label}:  ${dataValue}%`;
    } if (label === 'CVR') {
      return `${label}:  ${dataValue}%`;
    } if (label === 'Units') {
      return `${label}:  ${dataValue}`;
    } if (label === 'AOV') {
      return `${label}:  ${dataValue}`;
    }
  }

  const ticksForGraterThanThousand = (value: number, label: string) => {
    let num = ((value) / 1000);
    if (label === 'Sales') {
      return '₹' + num + "k";
    } else if (label === 'Spend') {
      return '₹' + num + "k";
    } else if (label === 'ACOS') {
      return num * 1000 + '%';
    } if (label === 'Orders') {
      return num + "k";
    } if (label === 'Clicks') {
      return num + "k";
    } if (label === 'CPC') {
      return '₹' + num + "k";
    } if (label === 'Impressions') {
      return num + "k";
    } if (label === 'CTR') {
      return num * 1000 + '%';
    } if (label === 'Conversions') {
      return num * 1000 + '%';
    } if (label === 'ROAS') {
      return num + 'k';
    } if (label === 'TOS_IS') {
      return num + 'k';
    } if (label === 'CVR') {
      return num + '%';
    } if (label === 'Units') {
      return value;
    } if (label === 'AOV') {
      return value;
    }
  }
  const ticksForLessThanThousand = (value: number, label: string) => {
    if (label === ('Sales')) {
      return '₹' + value;
    } else if (label === ('Spend')) {
      return '₹' + value;
    } else if (label === 'ACOS') {
      return value + '%';
    } if (label === 'Orders') {
      return value;
    } if (label === 'Clicks') {
      return value;
    } if (label === 'CPC') {
      return '₹' + value;
    } if (label === 'Impressions') {
      return value;
    } if (label === 'CTR') {
      return value + '%';
    } if (label === 'Conversions') {
      return value + '%';
    } if (label === 'ROAS') {
      return value;
    } if (label === 'TOS_IS') {
      return value;
    } if (label === 'CVR') {
      return value + '%';
    } if (label === 'Units') {
      return value;
    } if (label === 'AOV') {
      return value;
    }
  };

  const yAxisTickscallback = (value: any, index: any, ticks: any[], axisName: string) => {
    // console.log(value);
    const label = getLabel(axisName);
    // console.log(label);
    if (ticks.length <= 2) {
      let mid = (ticks[0].value + ticks[1]?.value) / ticks.length;
      // console.log(mid);
      ticks.push({
        value: mid,
        label: mid >= 1000 ? ticksForGraterThanThousand(mid, label) : ticksForLessThanThousand(mid, label)
      })
    }
    if ((value) >= 1000) {
      return ticksForGraterThanThousand(value, label);
    } else {
      return ticksForLessThanThousand(value, label);
    }
  }

  const options: any = {
    elements: {
      point: {
        radius: 0,
      }
    },
    tension: 0.4,
    borderWidth: 1,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false
    },
    plugins: {
      legend: {
        display:true,
        position: 'bottom',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      // title: {
      //   // display: true,
      //   //  text: 'Chart.js Line Chart',
      // },
      tooltip: {
        usePointStyle: true,
        backgroundColor: 'rgba(255, 255, 255,1)',
        bodyColor: 'rgba(0, 0, 0 , 0.8)',
        titleColor: 'rgba(0, 0, 0 , 0.6)',
        titleFont: {
          size: 14,
          weight: 'bold',
          family: 'Inter'
        },
        titleMarginBottom: 6,
        padding: 10,
        bodySpacing: 8,
        bodyFont: {
          family: 'Inter',
          weight: "normal",
          size: 14
        },
        borderColor: "rgba(245, 245, 247, 1)",
        borderWidth: 1,
        boxWidth: 40,
        boxPadding: 10,
        bodyAlign: 'right',
        callbacks: {
          label: (tooltipItem: TooltipItem<'line'>) => tooltipCallback(tooltipItem, graphData)
        }
      },
      datalabels: {
        display: false,
      },

    },

    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          maxTicksLimit: dataFor === 'marketing' ? 24 : 8,
          // autoSkip:true
          maxRotation: 0,
          minRotation: 0,

        }
      },

      y: {
        grid: {
          display: true,
          borderWidth: 0
        },
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3,
          color: 'rgba(0, 178, 255, 1)',
          display: true,
          callback: (value: any, index: any, values: any[]) => yAxisTickscallback(value, index, values, "y")
        },

      },
      y1: {
        position: 'right',
        grid: {
          display: false,
          borderWidth: 0
        },
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3,
          color: 'rgba(255, 199, 0, 1)',
          display: true,
          callback: (value: any, index: any, values: any[]) => yAxisTickscallback(value, index, values, "y1")
        }
      },
      y2: {
        position: 'right',
        grid: {
          display: false,
          borderWidth: 0
        },
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3,
          color: 'rgba(238, 52, 101, 1)',
          display: true,
          callback: (value: any, index: any, values: any[]) => yAxisTickscallback(value, index, values, "y2")
        }
      },
      y3: {
        position: 'left',
        grid: {
          display: false,
          borderWidth: 0
        },
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3,
          color: 'rgba(97, 0, 255, 1)',
          display: true,
          callback: (value: any, index: any, values: any[]) => yAxisTickscallback(value, index, values, "y3")

        }
      },

    },
  }

  return (
    <div>
      <  Chart
        ref={chartRef}
        data={graphData}
        type='line'
        height={300}
        options={options}

      />
    </div>
  )
}

export default LineGraph