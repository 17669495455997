import './authStyle.scss';
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import './authStyle.scss';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import { toast } from "react-toastify";
import ImagePlaceholder from "../../../assets/svgs/ImagePlaceholder";
import EnlyticalImg from "../../../assets/enlyticalImg.png"
import { BASE_URL_NEW } from "../../../appConstant";
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../../redux/hook';
import { userDetails } from '../../../redux/slices/userSlice';
import { login } from '../../../redux/slices/authSlice';


interface User {
    email: string;
    // Add other expected properties here
}

const InviteSignup: React.FC = () => {
    let navigate = useNavigate();
    const { userId } = useParams();
    const [userData, setUserData] = useState<User | null>(null);
    const [error, setError] = useState("");

        const dispatch = useAppDispatch();



    //api call to find user info through userid from link

    useEffect(() => {
        if (userId) {
            axios.get(`${BASE_URL_NEW}/user/${userId}`)
                .then((response) => setUserData(response.data))
                .catch((err) => {
                    setError("Invalid or expired invitation link");
                    toast("Invalid or expired invitation link");
                    setTimeout(() => navigate("/"), 3000); // Redirect after 5 sec
                });
        }
    }, [userId, navigate]);


    useEffect(() => {
        if (userData?.email) {
            setState((prevState) => ({
                ...prevState,
                email: userData.email, // Update the email in state
            }));
        }
    }, [userData]);


    // console.log('user data invite signup ', userData?.email)






    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        phone:"",
        email: "",
        temp_password: "",
        password: '',
        cnfmPassword: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showTempPassword, setShowTempPassword] = useState(false);
    const [profileImage, setProfileImage] = useState<string | null | any>(null);
    const [profileImage2, setProfileImage2] = useState<File | null>(null);

    // const [Image, setImage] = useState<string | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [UserInfo, setUserInfo] = useState([])

    const [agreeTerms, setAgreeTerms] = useState(false);



    // console.log('user data', UserInfo)

    // useEffect(() => {
    //     // if (!type) {
    //     if (type !== 'BRAND_OWNER' && type !== 'AGENCY') {
    //         navigate("/signup-mode"); // Redirect to home or any other fallback route
    //     }
    // }, [type, navigate]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: name === 'phone' ? value.replace(/\D/g, '') : value,
        });
    };

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        // console.log(state);
        if (state.first_name === '') {
            toast.error(`First Name is required`);
            return
        }
        if (state.last_name === '') {
            toast.error(`Last Name is required`);
            return
        }
        if (state.phone === '') {
            toast.error('Phone Number is required');
            return
        }
        // if (state?.phone?.length < 10) {
        //     toast.error('Phone Number length must be 10 digits');
        //     return
        // }
        if (!state.email  || state.email === '' ) {
            toast.error('email is required');
            return
        }
        if (state.temp_password === '') {
            toast.error('Temporary password is required');
            return
        }
        if (state.password === '') {
            toast.error('password is required');
            return
        }
        if (state.cnfmPassword === '') {
            toast.error('password confirmation is required');
            return
        }
        if (state.cnfmPassword !== state.password) {
            toast.error('password does not match');
            return
        }

        if (!agreeTerms) {
            toast.error("You must agree to the terms and conditions.");
            return;
        }
        // toast.success("all entries are there!");

        const newstate = { ...state, user_img: profileImage, logo: Image }
        // const newstate = { ...state, user_img: profileImage, logo: Image }

        const formData = new FormData();

        // Append all text fields from newstate
        Object.entries(state).forEach(([key, value]) => {
            // formData.append(key, value as string); // Assuming values are strings

            // if (typeof value === "number" || typeof value === "boolean") {
            if (key === "phone") {
                formData.append(key, value as string);
            } else {
                formData.append(key, value as string);
            }
        });


        // Append image and logo files (assuming they are File objects)
        if (profileImage2) formData.append("user_img", profileImage2);


        // formData.forEach((value, key) => {
        //     console.log(`${key}:`, value);
        //     console.log(`${key}:`, value, ` | Type:`, typeof value);
        // });
        setIsLoading(true);
        axios
            .post(`${BASE_URL_NEW}/auth/signup-member`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/json',
                }
            }
            )
            .then((response) => {
                //console.log(response.data.user);
                if (response.status === 201 && response.data.user) {
                    setUserInfo(response.data.user);
                    // toast.success("User created successfully");
                    const { token, user } = response.data;
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    dispatch(userDetails(user));
                    dispatch(login());
                    setTimeout(() => {
                        navigate('/home/brandOnboarding');
                    }, 1000);

                    // Navigate and pass state
                    //   navigate("/verifySignupOtp", { state: { userInfo: response.data.user } });

                }
            })
            .catch((error) => {
                console.log(error);

                const status = error.response?.status;
                if (status === 406) {
                }
                const message = error.response?.data?.error || error.message || "Something went wrong";

                toast.error(`Error ${status}: ${message}`);
                console.log(`Error ${status}: ${message}`);
            })
            .finally(() => {
                // navigate('/verifySignupOtp');
                setIsLoading(false);
            });

    };


    const handleImageUpload = (event: any) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            if (file.size <= 10 * 1024 * 1024) { // 10MB limit
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    setProfileImage(reader.result as string);
                    setProfileImage2(file)
                };
                reader.readAsDataURL(file);
            } else {
                alert("File size exceeds 10MB limit!");
            }
        } else {
            alert("Only PNG and JPEG formats are allowed.");
        }
    };



    const handleTermsChange = () => {
        setAgreeTerms(!agreeTerms);
    };



    return (
        <div className="login">
            <div className="login-left">
                <div className="login-loginContainer login-left-signup-container">
                    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }} className="mt-1 login-loginContainerHeader" >
                        <div><h4 className="mb-1">Sign Up</h4></div>
                        <div><img src={EnlyticalLogo} alt="logo" height={"50px"} /></div>
                    </div>


                    <form className='mt-2'>
                        <div className="mb-2 signup-input">

                            <div className="profile-upload-container">
                                <div className="image-preview">
                                    {profileImage ? (
                                        <img src={profileImage} alt="Profile" className="profile-image" />
                                    ) : (
                                        // <div className="placeholder">+</div>
                                        <div className="profile-image-before" >
                                            <ImagePlaceholder />
                                        </div>

                                    )}
                                </div>
                                <div className="upload-controls">
                                    <div className="profile-text">Profile Picture</div>
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={handleImageUpload}
                                        className="file-input"
                                        id="fileInput"
                                    />
                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                        <label htmlFor="fileInput" className="upload-button"> <i className="bi bi-upload"></i>Upload Image</label>
                                        {profileImage && (
                                            <button className="remove-button" onClick={() => setProfileImage(null)} >
                                                Remove
                                            </button>
                                        )}
                                    </div>
                                    <div className="file-info">*png, *jpeg files up to 10MB</div>
                                </div>
                            </div>





                            {/* image */}
                        </div>
                        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
                            <div className="mb-2 signup-input">
                                <label className="mb-1 login-label">First Name*</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="form-control login-input"
                                    id="first_name"
                                    placeholder="First Name"
                                    onChange={handleInputChange}
                                    value={state.first_name}
                                />
                            </div>
                            <div className="mb-2 signup-input">
                                <label className="mb-1 login-label">Last Name*</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    className="form-control login-input"
                                    id="last_name"
                                    placeholder="Last Name"
                                    onChange={handleInputChange}
                                    value={state.last_name}
                                />
                            </div>
                        </div>
                        <div className="signup-input mb-2">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className="mb-1 login-label" >Email*</label>
                            </div>
                            <input
                                type="email"
                                name="email"
                                className="form-control login-input"
                                id="email"
                                placeholder="Email"
                                // onChange={handleInputChange}
                                value={userData?.email}
                                disabled
                            />
                        </div>

                        <div className="signup-input mb-2">
                            <label className="mb-1 login-label">Phone Number*</label>
                            <input
                                type="text"
                                name="phone"
                                className="form-control login-input"
                                id="phone"
                                maxLength={10}
                                placeholder="Phone Number"
                                onChange={handleInputChange}
                                value={state.phone}
                            />
                        </div>
                        {/* <div style={{ display: 'flex', gap: '1rem' }}> */}


                        <div style={{ position: 'relative' }} className="signup-input mb-2">
                            <label className="mb-1 login-label">Temporary Password*</label>
                            <input
                                type={showTempPassword ? 'text' : 'password'}
                                name="temp_password"
                                value={state.temp_password}
                                placeholder="Temporary Password"
                                style={{ paddingRight: '30px' }}
                                onChange={handleInputChange}
                                className="form-control login-input"
                                id="temp_password"
                            />
                            <span
                                onClick={() => setShowTempPassword(!showTempPassword)}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showTempPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>


                        <div style={{ position: 'relative' }} className="signup-input mb-2">
                            <label className="mb-1 login-label">Set Password*</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={state.password}
                                placeholder="Password"
                                style={{ paddingRight: '30px' }}
                                onChange={handleInputChange}
                                className="form-control login-input"
                                id="password"
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>
                        <div style={{ position: 'relative', fontSize: '.75rem' }} className="signup-input mb-2">
                            <label className="mb-1 login-label">Confirm Password*</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="cnfmPassword"
                                value={state.cnfmPassword}
                                placeholder="Password"
                                style={{ paddingRight: '30px' }}
                                onChange={handleInputChange}
                                className="form-control login-input"
                                id="cnfmPassword"
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>
                        {/* </div> */}

                        {/* Terms & Conditions + Captcha */}
                        <div className="mb-1 mt-1 signup-input check-box-block">
                            <label className="terms-checkbox">
                                <input type="checkbox" checked={agreeTerms} onChange={handleTermsChange} />
                                I agree to the <a href="/terms" target="_blank" className="terms-link">terms and conditions</a>
                            </label>
                            {/* <div className="captcha-block">
                                <ReCAPTCHA sitekey="6Lf_ic4qAAAAANQtztgG4wCavALvwPe2iG1W2wg7" onChange={handleCaptchaVerify} />
                            </div> */}

                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '0rem' }}>
                                <div>
                                    <button
                                        className="login-btn"
                                        id="signup-btn"
                                        type="button"
                                        style={{ padding: '.4rem 1.3rem' }}
                                        onClick={handleSubmit}
                                        disabled={!agreeTerms || isLoading}
                                    >
                                        {isLoading ? (
                                            <div className="loader2"></div>
                                        ) : (
                                            'Next' // Default button text
                                        )}
                                    </button>
                                </div>
                                <div>
                                    {/* <span style={{ fontSize: '.75rem' }}>Already have an account?</span> <Link style={{ color: '#5D38E0', fontWeight: '600', fontSize: '.85rem' }} to={"/login"}> Sign in </Link> */}
                                </div>
                            </div>


                        </div>

                    </form>
                </div>
            </div>

            <div className="login-right">
                <div className="right-text">
                    <h2 className="mb-0">
                        <strong>AI enabled </strong>Growth
                    </h2>
                    <h2 className="mb-0">We make E-commerce Faster,</h2>
                    <h2 className="mb-0">Simpler and Better</h2>
                </div>
                <div className="right-content">
                    {/* <div className="right-text">Your command center for tracking, managing, and optimizing all aspects of your business. Whether you're overseeing projects, monitoring performance metrics, or collaborating with your team, Enlytical.ai provides you with the tools and insights you need to stay ahead of the curve.</div> */}
                    <div className="main-enlytical-img-container"><img src={EnlyticalImg} alt="img" className="main-enlytical-img" /></div>
                </div>

            </div>
        </div>
    );
};

export default InviteSignup;

