import React, { useEffect, useState } from 'react'
import BrandPage from '../pages/BrandPage';
import AgencyPage from '../pages/AgencyPage';
import Invite from './Invite';
import '../pages/userstyle.scss';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { brandDetails } from '../../../redux/slices/clientSlice';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import UserRequests from './UserRequest';



const ManagementDetails = () => {
  const [tabs, setTabs] = useState('agency');
  const [search, setSearch] = useState('')
  const [brandList, setBrandList] = useState([]);
  const [UsersData, setUsersData] = useState([]);
  const [brandWiseUserData, setBrandWiseUserData] = useState([]);
  const [searchClick,setSearchClick]=useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // console.log('brandList',brandList)
 
  const user = JSON.parse(localStorage.getItem('user') || '{}'); // Parse the JSON string
  

  const [inviteType, setInviteType] = useState(null);


  //new 

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRequest, setIsOpenRequest] = useState(false);
  const roles = ["Admin", "Manager", "Member","Client"];

  const [isCardOpen, setIsCardOpen] = useState(false);


  const filterUserData = UsersData?.filter((el) => (el.first_name)?.toLowerCase().includes(search.toLowerCase())
    || (el.last_name)?.toLowerCase().includes(search.toLowerCase())
  );
  const filterBrandList = brandList?.filter((el) => (el.client_name)?.toLowerCase().includes(search.toLowerCase()));

  // const filterBrandWiseUser = brandWiseUserData?.filter((el) => (el.user.first_name)?.toLowerCase().includes(search.toLowerCase()) ||
  //   (el.user.last_name)?.toLowerCase().includes(search.toLowerCase())
  // );

  const handleTabs = (tab) => {
    setTabs(tab);
  }



  //new trial

  const [entries, setEntries] = useState([
    { email: "", brandRoles: [], isCardOpen: false },
  ]);




  // Handle email input change
  const updateEmail = (index, value) => {
    setEntries((prev) =>
      prev.map((entry, i) => (i === index ? { ...entry, email: value } : entry))
    );
  };

  // Toggle brand/role selection per user
  const toggleCard = (index) => {
    setEntries((prev) =>
      prev.map((entry, i) =>
        i === index ? { ...entry, isCardOpen: !entry.isCardOpen } : entry
      )
    );
  };

  // Add a new user entry
  const addEntry = () => {
    setEntries([...entries, { email: "", brandRoles: [], isCardOpen: false }]);
  };

  // Remove a user entry
  const removeEntry = (index) => {
    setEntries(entries.filter((_, i) => i !== index));
  };




  const handleBrandChange = (entryIndex, brand) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry, idx) => {
        if (idx !== entryIndex) return entry; // Keep other entries unchanged
  
        const updatedBrandRoles = entry.brandRoles.some((br) => br.brand === brand)
          ? entry.brandRoles.filter((br) => br.brand !== brand) // Remove brand if unchecked
          : [...entry.brandRoles, { brand, role: "" }]; // Add brand with empty role
  
        return { ...entry, brandRoles: updatedBrandRoles };
      })
    );
  };
  
  const handleRoleChange = (entryIndex, brand, role) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry, idx) => {
        if (idx !== entryIndex) return entry;
  
        const updatedBrandRoles = entry.brandRoles.map((br) =>
          br.brand === brand ? { ...br, role } : br
        );
  
        return { ...entry, brandRoles: updatedBrandRoles };
      })
    );
  };
  
  const handleSubmit = () => {

    setEntries((prevEntries) =>
      prevEntries.map((entry) => ({
        ...entry,
        isCardOpen: false, // Set isCardOpen to false for all entries
      }))
    );
  };  


  const dispatch = useAppDispatch();
  const user2 = useAppSelector((state) => state.user)
  const brands2 = useAppSelector(state => state.client)
  const { current_brand, brand_array, current_agency } = brands2;
  const { isAuthenticated } = useAppSelector(state => state.auth);


  // console.log('user2',user2)
  // console.log('brands2',brands2?.current_brand?.access_role)




  const formatrole=(role)=>{
    if(role==='Member'){
      return "MEMBER";
    }
    else if(role==='Manager'){
      return "MANAGER";
    }
    else if(role==='Admin'){
      return "ADMIN";
    }
    else if(role==='Client'){
      return "CLIENT";
    }
    else{
      return "";
    }
  }



  const handleFinalSubmit = () => {

    if (inviteType === "AGENCY") {

      // If agency invite, send only email
    const validEmails = entries.filter(entry => entry.email.trim() !== "");

    if (validEmails.length === 0) {
      toast.error("Please enter at least one email.");
      return;
    }

    const formattedData = validEmails.map(entry => ({
      email: entry.email,
      invited_by: user2._id,
      agency: brands2?.current_agency?._id, 
      access_type: "AGENCY",
      access_role:'ADMIN'

    }));

    setIsLoading(true);
    axios.post(`${BASE_URL_NEW}/access/invite`, formattedData)
      .then(() => toast.success("Agency Invitation sent!"))
      .catch(() => toast.error("Error sending invitation."))
      .finally(()=>{
        setIsLoading(false);
        setIsOpen(false);
      });

  }  
  else {

    // Filter out invalid entries (must have email & at least one brandRole)
    const validEntries = entries.filter(entry => 
      entry.email.trim() !== "" && entry.brandRoles.length > 0
      && entry.brandRoles.some(br => br.brand!=="" && br.role!=="")
      
    );


    const formattedData = validEntries.flatMap(entry =>
      entry.brandRoles.map(br => ({
        email: entry.email,
        access_type: "BRAND", 
        brand: br.brand, // is it client_id handle mapping and all only potential invites needed and diff b/w invite and request access      
        access_role: formatrole(br.role), 
        invited_by: user2._id, 
        agency: brands2?.current_agency?._id,   //current agency 
      }))
    );
  
    if (formattedData.length === 0) {
      toast.error("Please fill out all required fields (Email, Brand, and Role).");
      return;
    }


    //  console.log('final formatted data',formattedData)

    // API Call 
    setIsLoading(true);
    axios.post(`${BASE_URL_NEW}/access/invite`, formattedData)
      .then((res) => {
        toast.success("Brand Invitation sent successfully!");
        // console.log('invite data formatted Data',formattedData)
      })
      .catch((err) => {
        toast.error("Error sending invitation.");
      })
      .finally(()=>{
        setIsLoading(false);
        setIsOpen(false);
      });
  }


  };

  
  



  return (
    <div className='detailsPage'>
      <div className='detailsPage-header'>

        <div style={{display:'flex' , gap:'.5rem'}}>
        <div className='detailsPage-btn_box'>
          <button className={tabs === 'agency' ? 'detailsPage-box_button detailsPage-box_button_active' : 'detailsPage-box_button'}
            onClick={() => handleTabs('agency')}>Users</button>
          <button className={tabs === 'brands' ? 'detailsPage-box_button detailsPage-box_button_active' : 'detailsPage-box_button'}
            onClick={() => handleTabs('brands')}>Brands</button>
        </div>
        {searchClick?

         <div className='detailsPage-inputBox'>
         <input value={search} onChange={(e) => setSearch(e.target.value)} className='detailsPage-input' type="text" placeholder={`${tabs === 'agency' ? 'Search User' : 'Search Brand'}`} />
         <i className="bi bi-search px-1"></i> 
         </div>

          :
        <div className='wrapper-box'>
        <div className='search-input-box' onClick={()=>setSearchClick(true)}><i className="bi bi-search px-1 justify-center"></i></div>
        </div>   
        }
        </div>

        <div className='detailsPage-header-right'>
        {/* searchClick?

        <div className='detailsPage-inputBox'>
        <input value={search} onChange={(e) => setSearch(e.target.value)} className='detailsPage-input' type="text" placeholder={`${tabs === 'agency' ? 'Search User' : 'Search Brand'}`} />
        <i className="bi bi-search px-1"></i>
        </div>

        :
        <div className='wrapper-box'>
        <div className='search-input-box' onClick={()=>setSearchClick(true)}><i className="bi bi-search px-1 justify-center"></i></div>
        </div>   
         */}

      <div className="relative">
       { brands2?.current_brand?.access_role ==='BRAND_MEMBER' || brands2?.current_brand?.access_role ==='MEMBER' ?"":
        <div style={{display:'flex' ,gap:'.5rem'}}>
          { brands2?.current_brand?.access_role ==='CLIENT' ?"":
        <div  style={{cursor:'pointer'}} className=" request-btn bg-blue-600 text-white px-4 py-2 rounded-md cursor-pointer flex items-center gap-2" onClick={()=>{ setIsOpenRequest(true) ;setSearchClick(false)}}> Requests <i class="bi bi-people-fill"></i></div> }
        <div  style={{cursor:'pointer'}} className=" request-btn bg-blue-600 text-white cursor-pointer px-4 py-2 rounded-md flex items-center gap-2" 
                          onClick={()=>{ setIsOpen(true);
                                   if (brands2?.current_brand?.access_role === "CLIENT") {
                                     setInviteType("CLIENT");
                                    }
                                    setSearchClick(false)}}>
                                               User Invite <i class="bi bi-people-fill"></i></div>
        </div>
       }
        </div>

        {/* Overlay & Modal of user requests */}



        {isOpenRequest && (
         <UserRequests 
            setIsOpenRequest={setIsOpenRequest}
         />
      )}





         {/* Overlay & Modal of user invite */}
      {isOpen && (
        <div className="modal-overlay">
        <div className="modal-box">
        {isLoading?<Loader/>:
        <>
        <button className="modal-close" onClick={() => {setIsOpen(false); setInviteType(null);}}>
          ✖
        </button>

        { inviteType?
        <h5 className="text-l font-semibold mb-4"> Invite Users</h5>:
        <h5 className="text-l font-semibold mb-4">Select one to Invite</h5>
      }

        
        {!inviteType ? (
      
      <div
  className="invite-type-selection"
  style={{
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    marginBottom: "1rem",
  }}
>
  <button
    className="invite-btn"
    onClick={() => setInviteType("AGENCY")}
    style={{
      backgroundColor: "#909090",
      color: "white",
      border: "none",
      padding: "10px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "14px",
      transition: "background-color 0.3s ease-in-out",
    }}
    onMouseEnter={(e) => (e.target.style.backgroundColor = "#06486A")}
    onMouseLeave={(e) => (e.target.style.backgroundColor = "#909090")}
  >
    Invite as Agency
  </button>
  <button
    className="invite-btn"
    onClick={() => setInviteType("BRAND")}
    style={{
      backgroundColor: "#909090",
      color: "white",
      border: "none",
      padding: "10px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "14px",
      transition: "background-color 0.3s ease-in-out",
    }}
    onMouseEnter={(e) => (e.target.style.backgroundColor = "#06486A")}
    onMouseLeave={(e) => (e.target.style.backgroundColor = "#909090")}
  >
    Invite as Brand
  </button>
</div>



      ) : (
        <>
        
        {entries.map((entry, entryIndex) => (
        <div className='mb-3' style={{display:'flex', alignItems:'center',justifyContent:'center', gap:'1rem'}}>
        {/* Email Input */}
        <div >
          <input
            type="email"
            className="input-field"
            placeholder="Enter email"
            value={entry.email}
            onChange={(e) => updateEmail(entryIndex, e.target.value)}
          />  
        </div>

        {/* Role/brand Dropdown */}
        <div>
        {(inviteType === "BRAND" || inviteType === "CLIENT")  && (
          <button className="brand-role-btn" onClick={() => {setIsCardOpen(!isCardOpen);
            toggleCard(entryIndex);
          }}
          >
          <div className='circular-container'>
          {entry.brandRoles.length > 0
            ? `${entry.brandRoles.length} brand(s) selected`
            : "Select Brand/Role"}
             <div className='circular-back'><i class="bi bi-chevron-down"></i></div></div>
          </button>
          )}
        </div>

        {entries.length > 1 && (
          <div  className="remove-btn" onClick={() => removeEntry(entryIndex)}>
            Remove
          </div>
        )}
        


         {entry.isCardOpen && (inviteType === "BRAND" || inviteType === "CLIENT") &&  ( 
        

          <div className='brand-role-card'>
          <div className="selection-container">

            <div  key={entryIndex} className="brand-list">




            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', color: '#1E1E1E' }}>
              <h4 className="font-medium mb-2" style={{ color: '#1E1E1E' }} >Brands</h4>
              <h4 className="font-medium mb-2" style={{ color: '#1E1E1E' }}>Roles</h4>
            </div>
        
                
                {brand_array.map((brand) => (
                  <div key={brand.client_id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          name="brand"
                          value={brand.client_id}
                          className="mr-2"
                          checked={entry.brandRoles.some((br) => br.brand === brand.client_id)}
                          onChange={() => handleBrandChange(entryIndex, brand.client_id)}
                        />
                        {brand.client_name}
                      </li>
                    </ul>
                    
          
          
          
                <div className="role-dropdown">
                  <div className="custom-select">
                    <select
                      className="select-dropdown"
                      onChange={(e) => handleRoleChange(entryIndex, brand.client_id, e.target.value)}
                      value={ entry.brandRoles.find((br) => br.brand === brand.client_id)?.role || "" }
                      disabled={!entry.brandRoles.some((br) => br.brand === brand.client_id)  } // Disable if brand is not selected
                      // style={{pointerEvents:brands2?.current_brand?.access_role==='CLIENT'?'none':'auto'}}

                    >
                      {/* If CLIENT role, show only Client as an option */}
                   {brands2?.current_brand?.access_role === "CLIENT" ? (<>
                    <option value="" disabled>
                    Choose Role
                    </option>
                   <option value="Client">Client</option>
                    </>
                    ) : (
                      <>
                      <option value="" disabled>
                       Choose Role
                      </option>
                      {roles.map((role) => (
                        <option key={role} value={role}>
                          {role}
                        </option>
                      ))}
                     </> )}
                    </select>
                    <div className="select-arrow"><i class="bi bi-chevron-down"></i></div>
                  </div>
                </div>
        
             </div>   
            ))}


          </div> 
              
          </div>
  
  
           <div className="submit-container">
           <button
             className="submit-btn"
             onClick={handleSubmit}
           >
             Submit
           </button>
         </div>
         
          </div>
          )}
           

          
          
          </div>
          
          
          
          
        ))}



        <div className='mb-3' style={{display:'flex', alignItems:'center',justifyContent:'center', gap:'1rem'}}>
        <div className='add-more-btn' onClick={addEntry}>
          Add more +
        </div>
        </div>

      
        <div className="mt-4 ">
        {brands2?.current_brand?.access_role==='CLIENT'?'':
          <button
            className="submit-btn"
            onClick={() => {
              setInviteType(null);
            }}
            style={{marginRight:'.5rem'}}
          >
            Back
          </button>
        }
          <button
            className="submit-btn"
            onClick={handleFinalSubmit}
          >
            Submit main
          </button>

          <span style={{color:'#06486A'}}> &nbsp; Want to add more Users? <span style={{textDecoration:'underline', cursor:'pointer'}}>Click here</span></span>
        </div>

        </>
      )}

      </>}
      </div>
       </div>

      )}



      </div>


        </div>


      <div >
        {tabs === 'agency' && <AgencyPage
          brandList={brandList}
          UsersData={UsersData}
          setBrandList={setBrandList}
          setUsersData={setUsersData}
          filterUserData={filterUserData}
        />}
        {tabs === 'brands' && <BrandPage
          brandList={brandList}
          setBrandList={setBrandList}
          // filterBrandWiseUser={filterBrandWiseUser}
          setBrandWiseUserData={setBrandWiseUserData}
          brandWiseUserData={brandWiseUserData}
          filterBrandList={filterBrandList}
        />}
      </div>
    </div>
  )
}

export default ManagementDetails