import { useState, useMemo, useEffect } from 'react'
import Loader from '../../../utils/commonComponents/Loader/Loader';
import ReportsErrorPage from '../../../utils/commonComponents/errorFallback/ReportsErrorPage';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { BASE_URL_NEW, USER_ROLES } from '../../../appConstant';
import { getUserColor } from '../../../utils/commonFunction';
import { Avatar } from '@mui/material';
import { useAppSelector } from '../../../redux/hook';
import { toast } from 'react-toastify';

const Approvals = () => {
    const loggeduser = useAppSelector((state) => state.user);
    const [approvalData, setApprovalData] = useState([]);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const fetchApprovalData = () => {
        axios.get(`${BASE_URL_NEW}/access/pending/${loggeduser?._id}`)
            .then((res) => {
                console.log(res.data);
                setApprovalData(res.data)
            }).catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        fetchApprovalData()
    }, [loggeduser?._id])

    const handleApproval = (accessId, status) => {
        // console.log(accessId, status);
        setIsLoading(true)
        axios.patch(`${BASE_URL_NEW}/access/${accessId}`, {
            status
        }).then((res) => {
            fetchApprovalData();
            toast.success('Request action successful')
        }).catch((error) => {
            console.log(error);
            toast.error('Request action unsuccessful')
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const defaultColDef = useMemo(() => ({
        sortable: true, resizable: true, flex: 1
    }), []);

    const nameComp = (props) => {
        const { data } = props;
        // console.log(data);
        const { status, access_role, user } = props.data;
        return (
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <span style={{ position: 'relative' }}>
                    <Avatar sx={{ width: '2rem', height: '2rem', backgroundColor: `${getUserColor(access_role)}` }}>
                        <span style={{ fontSize: '0.85rem', color: '#ffffff' }}>{user?.first_name?.split(" ")[0][0]?.toUpperCase() + user?.last_name?.split(" ")[0][0]?.toUpperCase()}</span>
                    </Avatar>
                </span>
                <span style={{ textTransform: 'capitalize' }}>{user?.first_name + ' ' + user?.last_name}</span>
            </div>
        )
    }

    const actionComp = (props) => {
        const { data } = props;
        return (
            <div style={{ display: 'flex', gap: '1rem' }}>
                <div>
                    <button onClick={() => handleApproval(data._id, 'ACTIVE')}
                        className='btn btn-sm' style={{ backgroundColor: '#2fe34742', padding: '2px 6px', border: '2px solid #2FE346', borderRadius: '1rem', color: '#2FE346', }}>
                        <i style={{ color: '#2FE346', WebkitTextStroke: '0.5px' }} className="bi bi-check2-circle"></i>&nbsp;Approve
                    </button>
                </div>
                <div>
                    <button onClick={() => handleApproval(data._id, 'REJECTED')}
                        className='btn btn-sm' style={{ backgroundColor: '#fe202041', padding: '2px 6px', border: '2px solid #FE2020', borderRadius: '1rem', color: '#FE2020' }}>
                        <i style={{ color: '#FE2020', WebkitTextStroke: '0.5px' }} className="bi bi-x-circle"></i>&nbsp;Reject
                    </button>
                </div>
            </div>
        )
    }

    const roleComp = (props) => {
        const { data: { access_role } } = props;
        return (
            <div>
                {USER_ROLES[access_role]}
            </div>
        )
    }

    const brandComp = (props) => {
        const { data: { brand } } = props;
        return (
            <div>
                {<span>{brand?.logo ? <img src={brand?.logo} alt="" width={60} height={40} /> : brand.client_name}&nbsp;</span>}
            </div>
        )
    }

    const columnDefs = useMemo(() => [
        { headerName: "NAME", field: "name", cellRenderer: nameComp, },
        { headerName: "ROLE REQUESTED", field: 'access_role', cellRenderer: roleComp, cellClass: 'center-text' },
        { headerName: "BRAND REQUESTED", field: '', cellRenderer: brandComp, cellClass: 'center-text' },
        { headerName: "ACTIONS", field: '', cellRenderer: actionComp, cellClass: 'center-text' },
    ], [])

    return (
        <section style={{ display: 'flex', flexDirection: 'column', gap: '2rem', }}>
            <h5 style={{ color: '#384D6C', textAlign: 'center' }}>Approvals</h5>
            <div>
                <div style={{ color: '#384D6C', fontWeight: 500, marginBottom: '0.25rem' }}>Pending Approvals</div>
                <div className="ag-theme-alpine agency-table approve-table" style={{ height: '70vh', width: '100%', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px " }}>
                    {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                        <AgGridReact
                            // ref={gridRef}
                            rowData={approvalData}
                            rowHeight={40}
                            headerHeight={50}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            suppressNoRowsOverlay={true}
                            suppressDragLeaveHidesColumns={true}
                            suppressMenuHide={true}
                            suppressCellFocus={true}
                            animateRows={true}
                        >
                        </AgGridReact>
                    }
                    {
                        isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                    }
                </div>
            </div>
        </section>
    )
}

export default Approvals