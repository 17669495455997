import axios from 'axios';
import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import { formatedMetric, interpolateColor } from '../../../../utils/commonFunction';
import Loader from '../../../../utils/commonComponents/Loader/Loader';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver'

const MetricwiseTable = ({ selectedDate, clickedKPI, setClickedKPI }: any) => {
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client
  const [rowData, setRowData] = useState<any[]>([]);
  const time_stamps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (selectedDate) {
      setIsLoading(true);
      axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/aggregate?date=${selectedDate?.date}&aggregateLevel=Hourly`)
        .then((res) => {
          // console.log(res.data);
          let temp: any = [];
          let keysArr = ['cost', 'impressions', 'clicks', 'attributedSales14d', 'attributedConversions14d', 'attributedUnitsOrdered14d', 'acos', 'roas', 'cpc', 'ctr', 'cvr', 'aov']
          keysArr.forEach((el) => {
            let obj: any = {};
            res.data.forEach((item: any) => {
              obj['metric'] = el
              if (el === 'ctr' || el === 'acos' || el === 'cpc' || el === 'roas' || el === 'cvr') {
                obj[`${item.hour + 1}_hour`] = Math.round(item[el] * 100) / 100;
              } else {
                obj[`${item.hour + 1}_hour`] = Math.round(item[el]);
              }
            })
            temp.push(obj)
          })
          // console.log(temp);

          setRowData(temp);

        }).catch((error) => {
          console.log(error);

        }).finally(() => {
          setIsLoading(false);
        })
    }
  }, [client_id, selectedDate])


  function heatmap(value: any, el: any) {
    // console.log(value);

    let a: any = Object.values(value);
    a.splice(0, 1);
    const min = Math.min(...a.filter((x: number) => x !== 0));
    const max = Math.max(...a.filter((x: number) => x !== 0));
    const currVal = value[`${el}_hour`]
    if (min === max || currVal === 0) return '#ffffff'

    const normalizedFactor = ((currVal - min) / (max - min))
    return interpolateColor('#B3FFFF', '#1ABFFF', normalizedFactor);
  }

  const hoursBody = (props: any, el: any) => {
    // console.log(props);

    return (
      <div style={{
        backgroundColor: heatmap(props, el),
        width: '100%',
        height: '100%',
        textAlign: 'center',
        padding: '10px 0px',
        boxSizing: 'border-box'
      }}>
        {props[`${el}_hour`] !== undefined ? (props[`${el}_hour`])?.toLocaleString() : '-'}
      </div>
    )
  }

  let obj: any = {};
  const totalBody = (props: any) => {
    // console.log(props);
    let total = 0;

    for (let time of time_stamps) {
      if (props[`${time}_hour`]) {
        total = total + props[`${time}_hour`];
      }
      obj[`total_${props.metric}`] = total;

    }

    if (props?.metric === 'acos') {
      total = (obj['total_cost'] / obj['total_attributedSales14d']) * 100
    } else if (props?.metric === 'ctr') {
      total = (obj['total_clicks'] / obj['total_impressions']) * 100
    } else if (props?.metric === 'cpc') {
      total = obj['total_cost'] / obj['total_clicks']
    } else if (props?.metric === 'roas') {
      total = obj['total_attributedSales14d'] / obj['total_cost']
    } else if (props?.metric === 'cvr') {
      total = (obj['total_attributedConversions14d'] / obj['total_clicks']) * 100
    } else if (props?.metric === 'aov') {
      total = obj['total_attributedSales14d'] / obj['total_attributedConversions14d']
    } else {
      total = Math.round(total)
    }
    return (
      <div>
        {total?.toLocaleString()}
      </div>
    )
  }

  const performanceBody = (props: any) => {
    const { metric } = props;
    return (
      <div>
        {formatedMetric(metric)}
      </div>
    )
  }


  const exportToCSV = () => {
    let csvContent = '';
    const header = ['metric/hour', ...time_stamps].map(col => col).join(',');
    csvContent += header + '\n';

    rowData.forEach((row: any) => {
      csvContent += row['metric']
      csvContent += ','
      const rowData = time_stamps.map(col => {
        return row[`${col}_hour`]
      }).join(',');
      csvContent += rowData + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'metric_wise_performance.csv');
  };

  const isRowDataEmpty = rowData.every(item => Object.keys(item).length === 0);

  return (
    <div style={{ marginTop: '1rem' }} className='table_card_new'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
          <span className='table_kpi_name'>Metric Wise Performance</span>
        </div>
      </div>

      <PrimeReactProvider>
        {isLoading ? (<LoaderSkeletonTable height='350px' />
        ) : isRowDataEmpty ? (
          <div
            style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}
          >
            No Data Available
          </div>
        ) : (

          <DataTable
            value={rowData}
            size='small'
            columnResizeMode="expand"
            resizableColumns
            scrollable scrollHeight="350px"
            // virtualScrollerOptions={{ itemSize: 20 }}
            selectionMode="single"
            selection={clickedKPI}
            onSelectionChange={(e) => setClickedKPI(e.value)}
            dataKey='metric'
          >
            <Column field='metric' header='Metric/Hour'
              style={{ width: '100px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
              body={performanceBody}
            />
            {
              time_stamps.map((el, i) => {
                return (
                  <Column
                    key={i}
                    header={el}
                    field={`${el}_hour`}
                    style={{ width: '42px' }}
                    headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                    bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                    bodyClassName="no-padding"
                    body={(props) => hoursBody(props, el)}

                  />
                )
              })
            }
            <Column field="" header='Total'
              style={{ width: '60px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
              body={totalBody}
            />
          </DataTable>
        )}
      </PrimeReactProvider>
    </div>
  );
}

export default MetricwiseTable

