
// exports.BASE_URL = "https://enlytical.azurewebsites.net/"; //prod
// exports.BASE_URL = "https://enlytical3.azurewebsites.net/"; //uat//
const BASE_URL = "https://enlytical-backend-dot-famous-archway-436113-a8.el.r.appspot.com";
const BASE_URL_NEW = "https://enlytical-backend-dot-famous-archway-436113-a8.el.r.appspot.com";
// const BASE_URL_NEW = "http://localhost:3001";
// const BASE_URL = "http://localhost:3001";

const ENLYTICAL_PROCESSING_BASE_URL='https://enlytical-processing-microservice-dot-famous-archway-436113-a8.el.r.appspot.com'
const ENLYTICAL_CRAWLING_BASE_URL='https://enlytical-crawling-microservice-dot-famous-archway-436113-a8.el.r.appspot.com'

// const ENLYTICAL_CRAWLING_BASE_URL='http://localhost:8000'
 
const USER_ROLES = {
    AGENCY_ADMIN: 'Agency Admin',
    ADMIN:'ADMIN',
    MANAGER:'MANAGER',
    MEMBER:'MEMBER',
    CLIENT:'CLIENT',
    BRAND_MANAGER: 'Brand Manager',
    BRAND_MEMBER: 'Brand Member',
    BRAND_VIEWER: 'Brand Viewer',
    EXTERNAL_BRAND_VIEWER: 'External Brand Viewer'
};


const AD_TYPE_FULL_NAME = {
    SB: 'Sponsered Brands (SB)',
    SP: 'Sponsered Products (SP)',
    SD: 'Sponsered Display (SD)',
    SBVC: "SBVC"
};
const TARGET_TYPE_FULL_NAME = {
    kt: "Keyword Targeting (kt)",
    pt: "Product Targeting (pt)",
};


const AG_AR = {
    rH: 32,
    hH: 32
}

const asin_row_height = 36

const PERCENTAGE_CHANGE = {
    percentage_change: "Percentage change",
}

const product_master_headers = {
    ad_asin: "ASINs",
    pname: "Product Name"
}

export { BASE_URL, BASE_URL_NEW, USER_ROLES, AG_AR, AD_TYPE_FULL_NAME, TARGET_TYPE_FULL_NAME, asin_row_height, PERCENTAGE_CHANGE, product_master_headers, ENLYTICAL_PROCESSING_BASE_URL, ENLYTICAL_CRAWLING_BASE_URL }