import React, { useEffect, useState } from 'react'
import './HomeSelectionScreen.scss'
import axios from 'axios';
import { BASE_URL_NEW } from '../../appConstant';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../redux/hook';
import { Navigate, useNavigate } from 'react-router-dom';




const HomeSelectionScreen = () => {


    const [data,setData]=useState([]); 
    const navigate= useNavigate();




    const brands2 = useAppSelector(state => state.client)
    
    const { current_brand, brand_array, current_agency } = brands2;




    // const data=[
    //     {
    //         "_id": "67bc374f08951c58a9ec6678",
    //         "module": "DASHBOARD_TOTAL_SALES",
    //         "type": "DASHBOARD",
    //         "name": "total sales",
    //         "description": "this is a dashboard for total sales",
    //         "status": "ACTIVE",
    //         "logo": "https://storage.googleapis.com/enlytical-bucket/logo/drew-beamer-Vc1pJfvoQvY-unsplash.jpg",
    //         "createdAt": "2025-02-24T09:09:35.690Z",
    //         "updatedAt": "2025-02-24T09:09:35.690Z",
    //         "__v": 0
    //     },
    //     {
    //         "_id": "67bc374f08951c58a9ec66b4",
    //         "module": "DASHBOARD_TOTAL_SALES",
    //         "type": "DASHBOARD",
    //         "name": "total sales",
    //         "description": "this is a dashboard for total sales",
    //         "status": "ACTIVE",
    //         "logo": "https://storage.googleapis.com/enlytical-bucket/logo/drew-beamer-Vc1pJfvoQvY-unsplash.jpg",
    //         "createdAt": "2025-02-24T09:09:35.690Z",
    //         "updatedAt": "2025-02-24T09:09:35.690Z",
    //         "__v": 0
    //     },
    // ]


    useEffect(() => {
            axios.get(`${BASE_URL_NEW}/module?brand=${current_brand.client_id}`)
                .then((response) => {
                    setData(response.data);
                    const initialToggleStates = response.data.reduce((acc: any, item: any) => {
                        acc[item._id] = item?.subscription?.status === "ACTIVE"; // Prefill based on status
                        return acc;
                    }, {});
                    setToggleStates(initialToggleStates);
                }

                )
                .catch((err) => {
                    console.log(err)
                    // toast("Invalid or expired invitation link");
                })
                .finally(()=>{
                    
                });
    }, []);
  

    
    const [toggleStates, setToggleStates] = useState(
        data.reduce((acc:any, item:any) => {
            acc[item._id] = 'false'; // Default state
            return acc;
        }, {})
    );
    


    const handleToggle = (id:any) => {
        setToggleStates((prev:any) => ({
          ...prev,
          [id]: !prev[id],
        }));
      };  


     // API Call on Save
  const handleSaveChanges = () => {
    const updatedData = Object.keys(toggleStates).map((id:any) => ({
      module: id,
      status: toggleStates[id] ? "ACTIVE" : "INACTIVE",
    brand: current_brand.client_id,
    }));

    // console.log("Data to send:", updatedData);
    // Call API here with updatedData



    axios.post(`${BASE_URL_NEW}/subscription`,updatedData)
    .then((res) => {toast.success('subscription saved successfully')
        setTimeout(() => navigate("/home/details"), 2000); 
    })
    .catch((err) => {
        console.log(err)
    })
    .finally(()=>{
    });
  };


    return (
        <div className='selection-screen-container'>
            <div className='header'> Select Dashboard</div>
            <div className='dashboard-card-container'> 

            {data.filter((item: any) => item.type === "DASHBOARD").map((item:any) => (
                <div className='card'>
                    <div className='card-heading'>
                        <img
                            src={item.logo}
                            alt="Logo"
                            className="card-logo"
                        />{item.name}</div>
                    <div className='card-description'>{item.description}</div>
                    <div className='card-button-container'>
                        <div className='view-button-link'>View Dashboard</div>
                        <div className={`toggle-btn  ${toggleStates[item._id] ? "active" : ""}`} onClick={()=>handleToggle(item._id)}></div>
                    </div>

                </div>

            ))}

            </div>


            <div className='header'>Select Reports</div>
            <div className='dashboard-card-container'>

            {data.filter((item: any) => item.type === "REPORT").map((item:any) => (
                <div className='card'>
                    <div className='card-heading'>
                        <img
                            src={item.logo}
                            alt="Logo"
                            className="card-logo"
                        />{item.name}</div>
                    <div className='card-description'>{item.description}</div>  
                    <div className='card-button-container'>
                        <div className='view-button-link'>View Report</div>
                        <div className={`toggle-btn  ${toggleStates[item._id] ? "active" : ""}`} onClick={()=>handleToggle(item._id)}></div>
                    </div>

                </div>

            ))}
            </div>



          <span className='save-btn' onClick={handleSaveChanges}>Save Changes and Proceed</span>  

        </div>
    )

};

export default HomeSelectionScreen
