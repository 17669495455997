import React, { useEffect, useState } from 'react';
import InfoIcon from '../../../../assets/svgs/InfoIcon';
import Tippy from '@tippyjs/react';
import BlinkitIcon from '../../../../assets/svgs/BlinkitIcon';
import InstamartIcon from '../../../../assets/svgs/InstamartIcon';
import ZeptoIcon from '../../../../assets/svgs/ZeptoIcon';
import Backgroundprofileupload from '../../../../assets/svgs/Backgroundprofileupload';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GreenTick from '../../../../assets/GreenTick';
import CloudUploadIcon from '../../../../assets/svgs/CloudUploadIcon';
import successfullgif from '../../../../assets/successfull.gif';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../../appConstant';
import LoaderSkeletonGraph from '../../../../utils/commonComponents/Loader/LoaderSkeletonGraph';
import LoaderSkeletonOnboarding from '../../../../utils/commonComponents/Loader/LoaderSkeletonOnboarding';
import './StatusScreen.scss'
import { PrimeReactProvider } from 'primereact/api';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as XLSX from "xlsx";

const StatusScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();


  const steps = [
    { name: "Brand Onboarding", route: "/home/brandOnboarding" },
    { name: "Product Master", route: "/Qcom/productMaster" },
    { name: "Competition Master", route: "/Qcom/competitivemaster" },
    { name: "Product Harmonization", route: "/Qcom/productHarmonization" },
    { name: "Product Status", route: "/Qcom/productStatus" },
  ];


  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);


  const [brandName, setBrandName] = useState(''); // Sub-brand input boxes
  const [subBrands, setSubBrands] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<string[]>([]);
  const [logo, setLogo] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);

  //product master table
  const [MockData, setMockData] = useState<any[]>([])
  const bodyStyle = { color: '#000000', fontSize: '0.85rem' }
  const [columns, setColumns] = useState<any[]>([])






  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL_NEW}/onboard/${id}`)
      .then((res: any) => {
        const data = res.data;

        // Safely handle dynamic data
        setLogo(data.image_url || null);
        setBrandName(data.brand_name || "");
        setSubBrands(data.sub_brands?.split("|").map((s: string) => s.trim()) || []);
        setSelectedCategories(data.product_category?.split("|").map((c: string) => c.trim()) || []);
        setSelectedCities(data.city?.split("|").map((c: string) => c.trim()) || []);
        setSelectedPlatform(data.platform?.split("|").map((p: string) => p.trim()) || []);
        setUploadedFile(data.product_master_url || null);

        // toast('fetched successful')
        // console.log('data for status screen',data)

      })
      .catch((err: any) => {
        console.log(err)
        // toast('categories not fetched')
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [id])



  useEffect(() => {
    const fetchAndProcessFile = async () => {
      if (!uploadedFile) return;

      setIsLoadingTable(true);
      try {
        const response = await axios.get(uploadedFile, {
          responseType: "blob",

        });

        const fileBlob = response.data;
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const data = new Uint8Array(e?.target?.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const rawData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as Array<any>;
          const headers = rawData[0];

          const dataJson = XLSX.utils.sheet_to_json<Record<string, any>>(sheet);
          setMockData(dataJson);
          setIsLoading(false);
          const extractedColumns = headers.map((key: string) => ({
            header: key,
            field: key.toString(),
          }));
          setColumns(extractedColumns);

        };

        reader.readAsArrayBuffer(fileBlob);

      } catch (error) {
        console.error("Error reading the file:", error);
      } finally {
        setIsLoadingTable(false);
      }
    };

    fetchAndProcessFile();
  }, [uploadedFile]);




  // Platform configuration for colors
  const platformStyles: {
    [key: string]: { bgColor: string };
  } = {
    Blinkit: { bgColor: "#F8F0C9" },
    Instamart: { bgColor: "#F7D8BF" },
    Zepto: { bgColor: "#DDBBF0" },
  };

  const renderIcon = (platform: string) => {
    if (platform.toLowerCase() === 'blinkit') return <BlinkitIcon />
    else if (platform.toLowerCase() === 'zepto') return <ZeptoIcon />
    else if (platform.toLowerCase() === 'instamart') return <InstamartIcon />
  }


  return (
    isLoading ? (
      <LoaderSkeletonOnboarding />
    ) : (
      <>
        <h3 style={{marginBottom:'1rem'}} >Status Screen</h3>
        <div className='status-screen'>
        <div className="status-screen-container">
          <div className="status-bar">
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <div className="step-container">
                  <div
                    className={`step ${index === steps.length - 1 ? "last-step" : ""}`}
                    onClick={() => navigate(step.route)}
                  >
                    <div className="circle">{   
                    // index === steps.length - 1
                     index === 0
                     ? <i className="bi bi-check"></i> : ""}</div>
                  </div>
                </div>
                {index < steps.length - 1 && <div className="line"></div>}
              </React.Fragment>
            ))}
          </div>
          <div className="status-bar">
            {steps.map((step, index) => (
              <span key={index} className="step-name">
                {step.name}
              </span>
            ))}
          </div>
        </div>
        </div>

        <div className="brand-onboarding-container">
          {/* Logo */}
          <div className="brand-logo-container">
            <div className="brand-logo">
              {logo ? (
                <img src={logo} alt="Uploaded Logo" />
              ) : (
                <Backgroundprofileupload />
              )}
            </div>
          </div>

          {/* Brand Name and Sub-Brands */}
          <div className="brand-name-container">
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ marginBottom: "0.5rem" }}>Brand Name</div>
              <div>
                <input
                  type="text"
                  placeholder="Enter Brand Name"
                  className="brand-name-input"
                  value={brandName}
                  disabled
                />
              </div>
            </div>

            {subBrands.length > 0 && subBrands[0] !== "" && (
              <>
                {subBrands.map((subBrand, index) => (
                  <div style={{ marginBottom: "1rem" }} key={index}>
                    <div style={{ marginBottom: "0.5rem" }}>Sub Brand Name</div>
                    <div className="sub-brand-row" style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        placeholder="Enter Sub-Brand"
                        value={subBrand}
                        className="sub-brand-input"
                        disabled
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          {/* Product Categories */}
          <div className="dropdown-container">
            <div className="dropdown">
              <div style={{ marginBottom: "0.5rem" }}>Product Category</div>
              <div className="dropdown-tags" style={{ display: "flex", flexWrap: "wrap", padding: "0rem", borderRadius: "0.2rem" }}>
                {selectedCategories.map((category, index) => (
                  <div
                    key={index}
                    className="tag"
                    style={{
                      backgroundColor: "#f0f0f0",
                      fontSize: '0.85rem',
                      padding: "0.3rem 0.4rem",
                      margin: "0.2rem",
                      borderRadius: "0.2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>

            {/* Cities */}
            <div className="dropdown">
              <div style={{ marginBottom: "0.5rem" }}>City</div>
              <div className="dropdown-tags" style={{ display: "flex", flexWrap: "wrap", padding: "0rem", borderRadius: "0.2rem" }}>
                {selectedCities.map((city, index) => (
                  <div
                    key={index}
                    className="tag"
                    style={{
                      backgroundColor: "#f0f0f0",
                      fontSize: '0.85rem',
                      padding: "0.3rem 0.4rem",
                      margin: "0.2rem",
                      borderRadius: "0.2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {city}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Platforms */}
          <div style={{ marginBottom: "0.5rem" }}>Selected Platforms:</div>
          <div className="platform-buttons">
            {selectedPlatform?.map((platform: string) => (
              <div
                key={platform}
                style={{
                  backgroundColor: platformStyles[platform]?.bgColor || "#ffffff",
                  position: 'relative',
                }}
              >
                {/* {platform} */}
                {renderIcon(platform)}
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                  }}
                >
                  <GreenTick />
                </span>
              </div>
            ))}
          </div>






          {/* Product Master */}
          <div className="product-master-upload" style={{ marginBottom: "2rem" }}>
            {uploadedFile && (
              <div className="uploaded-file-preview" >

                {/* will show table here  */}

                <div className=' table_card_new'>
                  <div style={{ position: 'relative' }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                      <span style={{ display: 'flex' }}>
                        <span className='table_kpi_name'>
                          <span>{uploadedFile ? `${uploadedFile.substring(uploadedFile.lastIndexOf('/') + 1)} Table` : ''}</span>
                        </span>
                      </span>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <i className="bi bi-file-earmark-excel-fill " style={{ color: 'green', width: "30px", height: "30px", marginRight: ".5rem", marginLeft: '.5rem' }}></i>
                      </div>
                    </div>
                    <PrimeReactProvider>
                      {
                        isLoadingTable ?
                          <LoaderSkeletonTable height='350px' /> :

                          <DataTable
                            value={MockData}
                            size='small'
                            columnResizeMode="expand"
                            resizableColumns
                            showGridlines
                            id='unique-table'
                            scrollable scrollHeight="350px"

                          >
                            {columns.map((col, index) => (
                              <Column
                                key={index}
                                field={col.field}
                                header={col.header}
                                // header={renderHeader(col.header, col.field)}
                                style={{ width: '150px' }}
                                headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                // bodyStyle={column?.bodyStyle}
                                body={col.body}

                              />
                            ))}
                          </DataTable>

                      }

                    </PrimeReactProvider>
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>
      </>
    )
  );


};

export default StatusScreen;

