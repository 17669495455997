import { useEffect, useRef, useState } from 'react';
import './reportsStyle.scss';
import DateRange from '../components/DateRange';
import KpiTile from '../components/KpiTile';
import Table from '../components/Table';
import DailyPageGraph from '../components/DailyPageGraph';
import { useAppSelector } from '../../../redux/hook';
import { ColumnFilter, Filter, KPI, PerformanceData } from '../types/type';
import { BASE_URL_NEW } from '../../../appConstant';
import axios from 'axios';
import MetricTable from '../components/MetricTable';
import Main from '../components/comparison/Main';
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import LoaderSkeletonGraph from '../../../utils/commonComponents/Loader/LoaderSkeletonGraph';
import effects from 'chart.js/dist/helpers/helpers.easing';
import ReportMenu from '../components/reportMenu/ReportMenu';

// category,placement,ad_asin,campaign_type,type,keyword_type,keyword,range,campaign: Performace Type
// "Accepted values of view : [INDIVIDUAL,AGGREGATE]"

const DailyReport: React.FC = () => {
    // const bodyStyle = { color: '#545454', fontSize: '0.85rem', fontWeight: 500, }
    const bodyStyle = { color: '#000000', fontSize: '0.85rem', fontWeight: 500, }


    const asinComp = (props: any) => {
        // console.log(props);
        const { ad_asin } = props;
        // if (ad_asin === "Total") {
        //     return (
        //         <div  >
        //             <a style={{ color: "rgb(0, 115, 199)", cursor: "pointer" }} >{props.value}</a>
        //         </div>
        //     )
        // }
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                {/* <div className="imgContainer">
                    <img src={props.data.image_url} height={"100%"} width={"100%"} alt="" ></img>
                </div> */}
                <a href={`https://www.amazon.in/dp/${ad_asin}`} target="_blank" style={{ cursor: "pointer", fontWeight: "500" }} >{ad_asin}</a>
            </div>
        )
    }
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client;


    const [selected4KpiArr, setSelected4KpiArr] = useState<KPI[]>([])
    const [data, setData] = useState<KPI[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const defaultKPI = ['cvr', 'acos', 'cost', 'sales']

    const [intervalType, setIntervalType] = useState<string>('DAILY')

    const [intervalRange, setIntervalRange] = useState()

    const [graphIntervalRange, setGraphIntervalRange] = useState({})

   

    const [publishedOrder, setPublishedOrder] = useState<number[]>([]); // Stores the order of ranks

    const [resetFlag,setResetFlag] = useState<boolean>(true);


    // console.log('publishorder',publishedOrder)





    // category,range,ad_asin,campaign_type,type,keyword_type,keyword,range,campaign_name,placement,: Performace Type
    const [fs, setFs] = useState<Filter>({
        sub_brand_arr: [],
        category_arr: [],
        range_arr: [],
        ad_asin_arr: [],
        campaign_type_arr: [],
        type_arr: [],
        keyword_type_arr: [],
        campaign_name_arr: [],
        keyword_arr: [],
        placement_arr: [],
    })

    // console.log(fs);


    const [fs_col, setFs_col] = useState<ColumnFilter>({
        sub_brand_col: [],
        category_col: [],
        range_col: [],
        ad_asin_col: [],
        campaign_type_col: [],
        type_col: [],
        keyword_type_col: [],
        campaign_name_col: [],
        keyword_col: [],
        placement_col: [],
    });
    // console.log(fs_col);

    const { sub_brand_arr, category_arr, range_arr, ad_asin_arr, campaign_type_arr, type_arr, keyword_type_arr, keyword_arr, placement_arr, campaign_name_arr } = fs;


    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/report/tiles/${client_id}`)
            .then((res): any => {
                const data = res.data;
                // console.log(data);
                setData(data);
                const selectedData = data.filter((el: any) => defaultKPI.includes(el?.metric))
                setSelected4KpiArr(selectedData)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false)
            })
    }, [client_id]);

    useEffect(() => {
        setFs({
            sub_brand_arr: [],
            category_arr: [],
            range_arr: [],
            ad_asin_arr: [],
            campaign_type_arr: [],
            type_arr: [],
            placement_arr: [],
            keyword_type_arr: [],
            keyword_arr: [],
            campaign_name_arr: []
        })
        setFs_col({
            sub_brand_col: [],
            category_col: [],
            range_col: [],
            ad_asin_col: [],
            campaign_type_col: [],
            type_col: [],
            placement_col: [],
            keyword_type_col: [],
            keyword_col: [],
            campaign_name_col: [],
        })
    }, [client_id])

    // sub_brand,category,placement,ad_asin,campaign_type,type,keyword_type,keyword,range,campaign_name: Performace Type
    // sub_brand,category,placement,ad_asin,campaign_type,campaign_name,type,keyword_type,keyword,range,metric
    const [components, setComponents] = useState([
        // {
        //     reportType: 'brand_wise',
        //     bucket: "Slot 1",
        //     name: 'Brand Wise',
        //     rank: 1,
        //     dependency_arr: [],
        //     active: true,

        // },
        {
            reportType: 'category_wise',
            bucket: "Slot 1",
            selectedColumns: fs_col.category_col,
            selectedColumnsName: 'category_col',
            firstColumn: [{ header: 'CATEGORY', field: 'category', bodyStyle }],
            performance_type: 'category',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr }
            ],
            dependency_arr: [sub_brand_arr],
            selectedName: 'category_arr',
            name: 'Category Wise',
            rank: 2,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
            
        },
        {
            reportType: 'range_wise',
            bucket: "Slot 1",
            selectedColumns: fs_col.range_col,
            selectedColumnsName: 'range_col',
            firstColumn: [{ header: 'RANGE', field: 'range', bodyStyle }],
            performance_type: 'range',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr }
            ],
            dependency_arr: [sub_brand_arr, category_arr],
            selectedName: 'range_arr',
            name: 'Range Wise',
            rank: 3,
            active: false,
            publish:false,
        },
        {
            reportType: 'asin_wise',
            bucket: "Slot 1",
            selectedColumns: fs_col.ad_asin_col,
            selectedColumnsName: 'ad_asin_col',
            firstColumn: [{ header: 'ASIN', field: 'ad_asin', bodyStyle }],
            performance_type: 'ad_asin',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr],
            selectedName: 'ad_asin_arr',
            name: 'ASIN Wise',
            rank: 4,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
        },
        {
            reportType: 'adType_wise',
            bucket: "Slot 2",
            selectedColumns: fs_col.campaign_type_col,
            selectedColumnsName: 'campaign_type_col',
            firstColumn: [{ header: 'AD-TYPE', field: 'campaign_type', bodyStyle }],
            performance_type: 'campaign_type',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr],
            selectedName: 'campaign_type_arr',
            name: 'Ad-Type Wise',
            rank: 5,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
        },
        {
            reportType: 'targeting_wise',
            bucket: "Slot 2",
            selectedColumns: fs_col.campaign_type_col,
            selectedColumnsName: 'campaign_type_col',
            firstColumn: [{ header: 'TARGETING', field: 'type', bodyStyle }],
            performance_type: 'type',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
                { performance_type: "campaign_type", values: campaign_type_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr, campaign_type_arr],
            selectedName: 'type_arr',
            name: 'Targeting Type',
            rank: 6,
            active: false,
            publish:false,
        },
        {
            reportType: 'keyword_wise',
            bucket: "Slot 2",
            selectedColumns: fs_col.keyword_type_col,
            selectedColumnsName: 'keyword_type_col',
            firstColumn: [{ header: 'KEYWORD TYPE', field: 'keyword_type', bodyStyle }],
            performance_type: 'keyword_type',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
                { performance_type: "campaign_type", values: campaign_type_arr },
                { performance_type: "type", values: type_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr, campaign_type_arr, type_arr],
            selectedName: 'keyword_type_arr',
            name: 'Keyword Type',
            rank: 7,
            active: false,
            publish:false,
        },
        {
            reportType: 'campaign_wise',
            bucket: "Slot 3",
            selectedColumns: fs_col.campaign_name_col,
            selectedColumnsName: 'campaign_name_col',
            firstColumn: [{ header: 'CAMPAIGN WISE', field: 'campaign_name', bodyStyle }],
            performance_type: 'campaign_name',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
                { performance_type: "campaign_type", values: campaign_type_arr },
                { performance_type: "type", values: type_arr },
                { performance_type: "keyword_type", values: keyword_type_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr, campaign_type_arr, type_arr, keyword_type_arr],
            selectedName: 'campaign_name_arr',
            name: 'Campaign Wise',
            rank: 8,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
        },
        {
            reportType: 'search_term_wise',
            bucket: "Slot 3",
            selectedColumns: fs_col.keyword_col,
            selectedColumnsName: 'keyword_col',
            firstColumn: [{ header: 'SEARCH TERM WISE', field: 'keyword', bodyStyle }],
            performance_type: 'keyword',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
                { performance_type: "campaign_type", values: campaign_type_arr },
                { performance_type: "type", values: type_arr },
                { performance_type: "keyword_type", values: keyword_type_arr },
                { performance_type: "campaign_name", values: campaign_name_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr, campaign_type_arr, type_arr, keyword_type_arr, campaign_name_arr],
            selectedName: 'keyword_arr',
            name: 'Search Term Wise',
            rank: 9,
            active: false,
            publish:false,
        },
        {
            reportType: 'placement_wise',
            bucket: "Slot 3",
            selectedColumns: fs_col.placement_col,
            selectedColumnsName: 'placement_col',
            firstColumn: [{ header: 'PLACEMENT WISE', field: 'placement', bodyStyle }],
            performance_type: 'placement',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
                { performance_type: "campaign_type", values: campaign_type_arr },
                { performance_type: "type", values: type_arr },
                { performance_type: "keyword_type", values: keyword_type_arr },
                { performance_type: "campaign_name", values: campaign_name_arr },
                { performance_type: "keyword", values: keyword_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr, campaign_type_arr, type_arr, keyword_type_arr, campaign_name_arr, keyword_arr],
            selectedName: 'placement_arr',
            name: 'Placement Type',
            rank: 10,
            active: false,
            publish:false,
        },
    ])


    const [selectedItems, setSelectedItems] = useState<any[]>([
        {
            reportType: 'category_wise',
            bucket: "Slot 1",
            selectedColumns: fs_col.category_col,
            selectedColumnsName: 'category_col',
            firstColumn: [{ header: 'CATEGORY', field: 'category', bodyStyle }],
            performance_type: 'category',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr }
            ],
            dependency_arr: [sub_brand_arr],
            selectedName: 'category_arr',
            name: 'Category Wise',
            rank: 2,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
            
        },
        {
            reportType: 'asin_wise',
            bucket: "Slot 1",
            selectedColumns: fs_col.ad_asin_col,
            selectedColumnsName: 'ad_asin_col',
            firstColumn: [{ header: 'ASIN', field: 'ad_asin', bodyStyle }],
            performance_type: 'ad_asin',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr],
            selectedName: 'ad_asin_arr',
            name: 'ASIN Wise',
            rank: 4,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
        },
        {
            reportType: 'adType_wise',
            bucket: "Slot 2",
            selectedColumns: fs_col.campaign_type_col,
            selectedColumnsName: 'campaign_type_col',
            firstColumn: [{ header: 'AD-TYPE', field: 'campaign_type', bodyStyle }],
            performance_type: 'campaign_type',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr],
            selectedName: 'campaign_type_arr',
            name: 'Ad-Type Wise',
            rank: 5,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
        },
        {
            reportType: 'campaign_wise',
            bucket: "Slot 3",
            selectedColumns: fs_col.campaign_name_col,
            selectedColumnsName: 'campaign_name_col',
            firstColumn: [{ header: 'CAMPAIGN WISE', field: 'campaign_name', bodyStyle }],
            performance_type: 'campaign_name',
            filters: [
                { performance_type: "sub_brand", values: sub_brand_arr },
                { performance_type: "category", values: category_arr },
                { performance_type: "range", values: range_arr },
                { performance_type: "ad_asin", values: ad_asin_arr },
                { performance_type: "campaign_type", values: campaign_type_arr },
                { performance_type: "type", values: type_arr },
                { performance_type: "keyword_type", values: keyword_type_arr },
            ],
            dependency_arr: [sub_brand_arr, category_arr, range_arr, ad_asin_arr, campaign_type_arr, type_arr, keyword_type_arr],
            selectedName: 'campaign_name_arr',
            name: 'Campaign Wise',
            rank: 8,
            // active: false,
            // publish:false,
            active: true,
            publish:true,
        },
    ]);



    const [selectedRank, setSelectedRank] = useState(1);

    // const handleReset = () => {
    //     setSelectedRank(2);
    //     setComponents((prev: any) => prev.map((el: any) => (
    //         el.reportType === 'category_wise' ? el : { ...el, active: false }
    //     )))
    // }

    const handlePublish2 =()=>{
        setPublishedOrder(selectedItems.map((item:any) => item.rank)); // Store the rank order
       
        setComponents((prev: any) => prev.map((el: any) => (
            // el.active ? { ...el, publish:true,rank: selectedItems.findIndex((item) => item.rank === el.rank) + 1, // Update rank based on selectedItems order
            el.active ? { ...el,filters:[],
                dependency_arr:[],  publish:true   }:{ ...el,filters:[],dependency_arr:[], publish:false } 
        )))
        // const filternull=components.map((component:any)=>{
        //       return {...component,filters:[]}
        // })

        setFs({
            sub_brand_arr: [],
            category_arr: [],
            range_arr: [],
            ad_asin_arr: [],
            campaign_type_arr: [],
            type_arr: [],
            keyword_type_arr: [],
            campaign_name_arr: [],
            keyword_arr: [],
            placement_arr: [],
        });
    
        setFs_col({
            sub_brand_col: [],
            category_col: [],
            range_col: [],
            ad_asin_col: [],
            campaign_type_col: [],
            type_col: [],
            placement_col: [],
            keyword_type_col: [],
            keyword_col: [],
            campaign_name_col: [],
        });
    
        // Optional: Trigger a reset flag for child components
        setResetFlag((prev) => !prev);
    }

    const handleReset = () => {
        setComponents((prev: any) => prev.map((el: any) => (
            // el.reportType === 'category_wise' ? el : { ...el, active: false, publish:false }
             { ...el, active: false, publish:false }

        )))
        setSelectedRank(1);
    };


    const handleButtonClick = (rank: number) => {
        // setSelectedRank(rank);
        components.forEach((el: any) => {
            if (el.rank === rank) {
                el.active = !el.active
            }
        })
        // console.log(components);
        setComponents(components);
    };


    const defaultPublishedOrder = components
    .filter((comp) => comp.publish)
    .map((comp) => comp.rank);

    let renderOrder = publishedOrder.length > 0 ? publishedOrder : defaultPublishedOrder;

    // console.log('render order',renderOrder)


    // const handleRemoveSelected = (rank: number) => {
    //     // setSelectedItems((prev) => prev.filter((item) => item.rank !== rank));

    //     // components.forEach((el: any) => {
    //     //     if (el.rank === rank) {
    //     //         el.active = !el.active
    //     //     }
    //     // })
    //     // setComponents(components);

    //     // // Call the parent's handler to update its state (if necessary)
    //     // handleButtonClick(null);
    //     setComponents((prev: any) => prev.map((el: any) => (
    //         el.rank===rank ? { ...el,active:false,publish:false }:{ ...el } 
    //     )))
    // };


    useEffect(() => {
        setComponents(prevComponents =>
            prevComponents.map(component => ({
                ...component,
                filters: component.filters.map((filter) => ({
                    ...filter,
                    values: fs[`${filter.performance_type}_arr` as keyof Filter] || filter.values
                })),
                dependency_arr: component.dependency_arr.map(dep =>
                    dep === fs[`${component.performance_type}_arr` as keyof Filter] ? dep : fs[`${component.performance_type}_arr` as keyof Filter]
                ),
                // selectedRow: component.selectedName === 'category_arr' ? fs.category_arr : component.selectedRow,
            }))
        );
    }, [fs]);

    // useEffect(()=>{
    //     // setComponents([...components]);
        
        
    // },[handlePublish2])

    useEffect(() => {
        setComponents(prevComponents =>
            prevComponents.map(component => ({
                ...component,
                selectedColumns: component.selectedColumns === fs_col[`${component.performance_type}_col` as keyof ColumnFilter] ? component.selectedColumns : fs_col[`${component.performance_type}_col` as keyof ColumnFilter]
            }))
        );
    }, [fs_col]);

    // const handleRowSelect = (performanceType: string, selectedRow: any) => {
    //     setFs((prevFs) => ({
    //         ...prevFs,
    //         [`${performanceType}_arr`]: selectedRow,
    //     }));
    
    //     // Get the rank of the current table
    //     const currentComponent = components.find((comp) => comp.performance_type === performanceType);
    //     const currentRank = currentComponent?.rank;
    
    //     // Update only downstream tables (tables with a rank greater than the current table)
    //     setComponents((prevComponents) =>
    //         prevComponents.map((component) => {
    //             if (component.rank > currentRank) {
    //                 return {
    //                     ...component,
    //                     filters: component.filters.map((filter) => ({
    //                         ...filter,
    //                         values: filter.performance_type === performanceType ? selectedRow : fs[`${filter.performance_type}_arr` as keyof Filter],
    //                     })),
    //                 };
    //             }
    //             return component; // Keep other tables as-is
    //         })
    //     );
    // };
    


    // return (
    //     <main className='main'>
    //         <section className='main-header'>
    //             <div>
    //                 {intervalType !== 'COMPARISON' && <KpiTile
    //                     selected4KpiArr={selected4KpiArr}
    //                     setSelected4KpiArr={setSelected4KpiArr}
    //                     dataArr={data}
    //                     isLoading={isLoading}
    //                 />}
    //             </div>
    //             <DateRange
    //                 intervalType={intervalType}
    //                 setIntervalType={setIntervalType}
    //                 intervalRange={intervalRange}
    //                 setIntervalRange={setIntervalRange}
    //                 graphIntervalRange={graphIntervalRange}
    //                 setGraphIntervalRange={setGraphIntervalRange}
    //             />
    //             {/* <ReportMenu/> */}
    //         </section>
    //         {intervalType !== 'COMPARISON' ?
    //             <>
    //                 <section>
    //                     {isLoading ? <LoaderSkeletonGraph /> :
    //                         <DailyPageGraph
    //                             intervalType={intervalType}
    //                             selected4KpiArr={selected4KpiArr}
    //                             intervalRange={graphIntervalRange}
    //                         />
    //                     }
    //                 </section>
    //                 <section>


    //                     <div style={{ marginBottom: '0.5rem' }} className='table_card_new'>
    //                         <div>
    //                         </div>
    //                         <div className='table_card-header'>

    //                             <button className={tableView === 'sub_brand' ? 'table_card-button_active' : 'table_card-button_active'} onClick={() => handlePerformanceType('sub_brand')}>SubBrand Wise Performance</button>
    //                         </div>
    //                         <div >
    //                             {
    //                                 <Table
    //                                     selectedColumns={fs_col.sub_brand_col}
    //                                     selectedColumnsName={'sub_brand_col'}
    //                                     fs_col={fs_col}
    //                                     setFs_col={setFs_col}
    //                                     firstColumn={[{ header: 'SubBrand', field: 'sub_brand', bodyStyle }]}
    //                                     intervalType={intervalType}
    //                                     performance_type='sub_brand'
    //                                     filters={[]}
    //                                     dependency_arr={[]}
    //                                     selectedRow={fs.sub_brand_arr}
    //                                     selectedName={'sub_brand_arr'}
    //                                     fs={fs}
    //                                     setFs={setFs}
    //                                     intervalRange={intervalRange}
    //                                 />
    //                             }
    //                         </div>

    //                     </div>

    //                     <div className='table_card_new'>
    //                         <div>
    //                         </div>
    //                         <div className='table_card-header'>

    //                             <button className={tableView === 'category' ? 'table_card-button_active' : 'table_card-button'} onClick={() => handlePerformanceType('category')}>Category Wise Performance</button>
    //                             <button className={tableView === 'campaign_type' ? 'table_card-button_active' : 'table_card-button'} onClick={() => handlePerformanceType('campaign_type')}>Ad-type Wise Performance</button>
    //                         </div>

    //                         <div ref={categoryRef}>
    //                             {
    //                                 tableView === 'category' &&
    //                                 <Table
    //                                     selectedColumns={fs_col.category_col}
    //                                     selectedColumnsName={'category_col'}
    //                                     fs_col={fs_col}
    //                                     setFs_col={setFs_col}
    //                                     firstColumn={[{ header: 'CATEGORY', field: 'category', bodyStyle }]}
    //                                     intervalType={intervalType}
    //                                     performance_type='category'
    //                                     filters={[{
    //                                         performance_type: "sub_brand",
    //                                         values: fs.sub_brand_arr
    //                                     }]}
    //                                     dependency_arr={[sub_brand_arr]}
    //                                     selectedRow={fs.category_arr}
    //                                     selectedName={'category_arr'}
    //                                     fs={fs}
    //                                     setFs={setFs}
    //                                     intervalRange={intervalRange}
    //                                 />
    //                             }
    //                         </div>


    //                         <div ref={adTypeRef}>
    //                             {

    //                                 tableView === 'campaign_type' &&
    //                                 <Table
    //                                     selectedColumns={fs_col.campaign_type_col}
    //                                     selectedColumnsName={'campaign_type_col'}
    //                                     fs_col={fs_col}
    //                                     setFs_col={setFs_col}
    //                                     firstColumn={[{ header: 'AD-TYPE', field: 'campaign_type', bodyStyle }]}
    //                                     intervalType={intervalType}
    //                                     performance_type='campaign_type'
    //                                     filters={[{
    //                                         performance_type: "sub_brand",
    //                                         values: fs.sub_brand_arr
    //                                     }]}
    //                                     dependency_arr={[sub_brand_arr]}
    //                                     selectedRow={fs.campaign_type_arr}
    //                                     selectedName={'adType_arr'}
    //                                     fs={fs}
    //                                     setFs={setFs}
    //                                     intervalRange={intervalRange}
    //                                 />
    //                             }
    //                         </div>

    //                     </div>



    //                     {/* ts = category->C->metric wise  */}
    //                     <div ref={catogMetricRef}>
    //                         {
    //                             fs_col.category_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.category_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'category'}
    //                                 intervalRange={graphIntervalRange}
    //                             />

    //                         }
    //                     </div>


    //                     {/* ts  ad-type -> C -> metric 1  */}
    //                     <div ref={adTypeMetric1Ref}>
    //                         {
    //                             fs_col.campaign_type_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.campaign_type_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'campaign_type'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>



    //                     {/* range table */}

    //                     {/* ts = category-> R-> Range wise */}
    //                     <div ref={catogRangeRef}>
    //                         {

    //                             // fs.category_arr.length > 0 && dataCount.length > 0 &&
    //                             fs.category_arr.length > 0 &&
    //                             <div style={{ marginTop: '0.5rem' }} className='table_card_new'>
    //                                 <div className='table_card-header'>
    //                                     <button className='table_card-button_active'>Range Wise Performance</button>
    //                                 </div>
    //                                 <Table
    //                                     selectedColumns={fs_col.range_col}
    //                                     selectedColumnsName={'range_col'}
    //                                     fs_col={fs_col}
    //                                     setFs_col={setFs_col}
    //                                     firstColumn={[{ header: 'RANGE', field: 'range', bodyStyle }]}
    //                                     intervalType={intervalType}
    //                                     performance_type='range'
    //                                     filters={[{
    //                                         performance_type: "category",
    //                                         values: fs.category_arr
    //                                     }]}
    //                                     dependency_arr={[category_arr]}
    //                                     selectedRow={fs.range_arr}
    //                                     selectedName={'range_arr'}
    //                                     fs={fs}
    //                                     setFs={setFs}
    //                                     intervalRange={intervalRange}
    //                                     setDataCount={setDataCount}
    //                                 />
    //                             </div>

    //                         }
    //                     </div>



    //                     {/* ts = category-> R-> Range wise p -> C -> Metric wise p */}
    //                     <div ref={catogRangeMetricRef}>
    //                         {

    //                             fs_col.range_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.range_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'range'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>

    //                     {/* ad-asin table */}

    //                     {/* ts = category-> R-> Rangewise p -> R -> Asinwise p */}
    //                     <div ref={catogRangeAsinRef}>
    //                         {
    //                             // ((fs.category_arr.length > 0 && dataCount.length === 0) || (fs.category_arr.length > 0 && fs.range_arr.length > 0)) &&
    //                             fs.category_arr.length > 0 &&
    //                             <div style={{ marginTop: '0.5rem' }} className='table_card_new'>
    //                                 <div className='table_card-header'>
    //                                     <button className='table_card-button_active'>Asin Wise Performance</button>
    //                                 </div>
    //                                 <Table
    //                                     selectedColumns={fs_col.ad_asin_col}
    //                                     selectedColumnsName={'asin_col'}
    //                                     fs_col={fs_col}
    //                                     setFs_col={setFs_col}
    //                                     firstColumn={[{ header: 'ASIN', field: 'ad_asin', bodyStyle, body: asinComp }]}
    //                                     intervalType={intervalType}
    //                                     performance_type='ad_asin'
    //                                     filters={[{
    //                                         performance_type: "category",
    //                                         values: fs.category_arr
    //                                     }, {
    //                                         performance_type: "range",
    //                                         values: fs.range_arr
    //                                     }]}
    //                                     dependency_arr={[category_arr, range_arr]}
    //                                     selectedRow={fs.ad_asin_arr}
    //                                     selectedName={'asin_arr'}
    //                                     fs={fs}
    //                                     setFs={setFs}
    //                                     intervalRange={intervalRange}
    //                                 />
    //                             </div>
    //                         }


    //                     </div>


    //                     {/* ts  ad-type->C->Metricwise 2 */}
    //                     <div>
    //                         {
    //                             fs_col.campaign_type_col.length > 0 && (
    //                                 <div>
    //                                     <MetricTable
    //                                         selectedColumns={fs_col.asin_col}
    //                                         intervalType={intervalType}
    //                                         performance_type={'ad_asin'}
    //                                         intervalRange={graphIntervalRange}
    //                                     />
    //                                 </div>
    //                             )}

    //                     </div>


    //                     {/* targeting-type & campaign table */}



    //                     {/* ts ad-type->R->Targeting and campaingn */}
    //                     {
    //                         fs.adType_arr.length > 0 &&
    //                         <div style={{ marginTop: '0.5rem' }} className='table_card_new'>
    //                             <div className='table_card-header'>
    //                                 <button className={tableView1 === 'type' ? 'table_card-button_active' : 'table_card-button'} onClick={() => handlePerformanceType1('type')}>Targeting Type Wise Performance</button>
    //                                 <button className={tableView1 === 'campaign' ? 'table_card-button_active' : 'table_card-button'} onClick={() => handlePerformanceType1('campaign')}>Campaign Wise Performance</button>
    //                             </div>
    //                             {
    //                                 tableView1 === 'type' && (
    //                                     <div ref={targetingTypeRef}>
    //                                         <Table
    //                                             selectedColumns={fs_col.type_col}
    //                                             selectedColumnsName={'type_col'}
    //                                             fs_col={fs_col}
    //                                             setFs_col={setFs_col}
    //                                             firstColumn={[{ header: 'TARGETING', field: 'type', bodyStyle }]}
    //                                             intervalType={intervalType}
    //                                             performance_type='type'
    //                                             filters={[{
    //                                                 performance_type: "campaign_type",
    //                                                 values: fs.adType_arr
    //                                             }]}
    //                                             dependency_arr={[adType_arr]}
    //                                             selectedRow={fs.type_arr}
    //                                             selectedName={'type_arr'}
    //                                             fs={fs}
    //                                             setFs={setFs}
    //                                             intervalRange={intervalRange}
    //                                         />
    //                                     </div>
    //                                 )}
    //                             {
    //                                 tableView1 === 'campaign' && (
    //                                     <div ref={campaignRef}>
    //                                         <Table
    //                                             selectedColumns={fs_col.campaign_name_col}
    //                                             selectedColumnsName={'campaign_name_col'}
    //                                             fs_col={fs_col}
    //                                             setFs_col={setFs_col}
    //                                             firstColumn={[{ header: 'CAMPAIGN NAME', field: 'campaign_name', bodyStyle }]}
    //                                             intervalType={intervalType}
    //                                             performance_type='campaign_name'
    //                                             filters={[{
    //                                                 performance_type: "campaign_type",
    //                                                 values: fs.adType_arr
    //                                             }]}
    //                                             dependency_arr={[adType_arr]}
    //                                             selectedRow={fs.campaign}
    //                                             selectedName={'campaign'}
    //                                             fs={fs}
    //                                             setFs={setFs}
    //                                             intervalRange={intervalRange}
    //                                         />
    //                                     </div>
    //                                 )}
    //                         </div>
    //                     }

    //                     {/* ts ad-type->R->Targeting type->C->metricwise  */}
    //                     <div ref={targetMetricRef}>
    //                         {
    //                             fs_col.type_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.type_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'type'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>

    //                     {/* ts ad-type->R->campaign type->C->metricwise  */}
    //                     <div ref={campaignMetricRef}>
    //                         {
    //                             fs_col.campaign_name_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.campaign_name_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'campaign_name'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>


    //                     {/* search-term table */}



    //                     {/* ts ad-type->R->Targeting type->R->targetwise p  */}

    //                     <div ref={targetingTypeTargetRef}>

    //                         {
    //                             fs.adType_arr.length > 0 && fs.type_arr.length > 0 && tableView1 === 'type' &&
    //                             <div style={{ marginTop: '0.5rem' }} className='table_card_new'>
    //                                 <div className='table_card-header'>
    //                                     <button className='table_card-button_active'>Targeting Wise Performance</button>
    //                                 </div>
    //                                 <Table
    //                                     selectedColumns={fs_col.keyword_col}
    //                                     selectedColumnsName={'keyword_col'}
    //                                     fs_col={fs_col}
    //                                     setFs_col={setFs_col}
    //                                     firstColumn={[{ header: 'TARGETING-WISE', field: 'keyword', bodyStyle }]}
    //                                     intervalType={intervalType}
    //                                     performance_type='keyword'
    //                                     filters={[{
    //                                         performance_type: "campaign_type",
    //                                         values: fs.adType_arr
    //                                     }, {
    //                                         performance_type: "type",
    //                                         values: fs.type_arr
    //                                     }]}
    //                                     dependency_arr={[adType_arr, type_arr]}
    //                                     selectedRow={fs.keyword_arr}
    //                                     selectedName={'keyword_arr'}
    //                                     fs={fs}
    //                                     setFs={setFs}
    //                                     intervalRange={intervalRange}
    //                                 />
    //                             </div>
    //                         }
    //                     </div>

    //                     {/* ts ad-type->R->Targeting type->R->targetwise p->C->metricwise1 */}
    //                     <div ref={targetingTypeTargetMetric1Ref}>
    //                         {
    //                             fs_col.keyword_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.keyword_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'keyword'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>

    //                     {/* placement & asin & search_term table */}


    //                     {/* ts = ad-type->R->campaignType->R->placement/asin/targeting */}
    //                     {
    //                         fs.adType_arr.length > 0 && fs.campaign.length > 0 && tableView1 === 'campaign' &&
    //                         <div style={{ marginTop: '0.5rem' }} className='table_card_new'>
    //                             <div className='table_card-header'>
    //                                 <button className={tableView2 === 'placement' ? 'table_card-button_active' : 'table_card-button'} onClick={() => handlePerformanceType2('placement')}>Placement Wise Performance</button>
    //                                 <button className={tableView2 === 'asin' ? 'table_card-button_active' : 'table_card-button'} onClick={() => handlePerformanceType2('asin')}>Asin Wise Performance</button>
    //                                 <button className={tableView2 === 'serch_term' ? 'table_card-button_active' : 'table_card-button'} onClick={() => handlePerformanceType2('serch_term')}>Targeting Wise Performance</button>
    //                             </div>


    //                             <div ref={campaignPlacementref}>
    //                                 {tableView2 === 'placement' &&
    //                                     <Table
    //                                         selectedColumns={fs_col.placement_col}
    //                                         selectedColumnsName={'placement_col'}
    //                                         fs_col={fs_col}
    //                                         setFs_col={setFs_col}
    //                                         firstColumn={[{ header: 'PLACEMENT', field: 'placement', bodyStyle }]}
    //                                         intervalType={intervalType}
    //                                         performance_type='placement'
    //                                         filters={[{
    //                                             performance_type: "campaign_type",
    //                                             values: fs.adType_arr
    //                                         }, {
    //                                             performance_type: "campaign_name",
    //                                             values: fs.campaign
    //                                         }]}
    //                                         dependency_arr={[adType_arr, campaign]}
    //                                         selectedRow={fs.placement_arr}
    //                                         selectedName={'placement_arr'}
    //                                         fs={fs}
    //                                         setFs={setFs}
    //                                         intervalRange={intervalRange}
    //                                     />
    //                                 }
    //                             </div>

    //                             <div ref={campaignAsinref}>
    //                                 {tableView2 === 'asin' &&
    //                                     <Table
    //                                         selectedColumns={fs_col.asin_col}
    //                                         selectedColumnsName={'asin_col'}
    //                                         fs_col={fs_col}
    //                                         setFs_col={setFs_col}
    //                                         firstColumn={[{ header: 'ASIN', field: 'ad_asin', bodyStyle }]}
    //                                         intervalType={intervalType}
    //                                         performance_type='ad_asin'
    //                                         filters={[{
    //                                             performance_type: "campaign_type",
    //                                             values: fs.adType_arr
    //                                         }, {
    //                                             performance_type: "campaign_name",
    //                                             values: fs.campaign
    //                                         }]}
    //                                         dependency_arr={[adType_arr, campaign]}
    //                                         selectedRow={fs.asin_arr}
    //                                         selectedName={'asin_arr'}
    //                                         fs={fs}
    //                                         setFs={setFs}
    //                                         intervalRange={intervalRange}
    //                                     />
    //                                 }
    //                             </div>

    //                             <div ref={campaignTargetingref}>
    //                                 {tableView2 === 'serch_term' &&
    //                                     <Table
    //                                         selectedColumns={fs_col.keyword_col}
    //                                         selectedColumnsName={'keyword_col'}
    //                                         fs_col={fs_col}
    //                                         setFs_col={setFs_col}
    //                                         firstColumn={[{ header: 'TARGETING-WISE', field: 'keyword', bodyStyle }]}
    //                                         intervalType={intervalType}
    //                                         performance_type='keyword'
    //                                         filters={[{
    //                                             performance_type: "campaign_type",
    //                                             values: fs.adType_arr
    //                                         }, {
    //                                             performance_type: "campaign_name",
    //                                             values: fs.campaign
    //                                         }]}
    //                                         dependency_arr={[adType_arr, campaign]}
    //                                         selectedRow={fs.keyword_arr}
    //                                         selectedName={'keyword_arr'}
    //                                         fs={fs}
    //                                         setFs={setFs}
    //                                         intervalRange={intervalRange}
    //                                     />
    //                                 }
    //                             </div>

    //                         </div>
    //                     }



    //                     {/* ts = ad-type->R->campaignType->R->placement->C->metricwise */}
    //                     <div ref={campaignPlacementMetricref}>
    //                         {
    //                             fs_col.placement_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.placement_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'placement'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>

    //                     {/* ts = ad-type->R->campaignType->R->asin->C->metricwise */}
    //                     <div ref={campaignAsinMetricref}>
    //                         {
    //                             fs_col.asin_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.asin_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'ad_asin'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>

    //                     {/* ts = ad-type->R->campaignType->R->targeting->C->metricwise */}
    //                     <div ref={campaignTargetingMetricref}>

    //                         {
    //                             fs_col.keyword_col.length > 0 &&
    //                             <MetricTable
    //                                 selectedColumns={fs_col.keyword_col}
    //                                 intervalType={intervalType}
    //                                 performance_type={'keyword'}
    //                                 intervalRange={graphIntervalRange}
    //                             />
    //                         }
    //                     </div>


    //                 </section>
    //             </>
    //             : <Main
    //                 intervalRange={intervalRange}
    //             />
    //         }
    //     </main>
    // )

    return (
        <div>
            <section className='main-header'>
                <div>
                    {intervalType !== 'COMPARISON' && <KpiTile
                        selected4KpiArr={selected4KpiArr}
                        setSelected4KpiArr={setSelected4KpiArr}
                        dataArr={data}
                        isLoading={isLoading}
                    />}
                </div>
                <DateRange
                    intervalType={intervalType}
                    setIntervalType={setIntervalType}
                    intervalRange={intervalRange}
                    setIntervalRange={setIntervalRange}
                    graphIntervalRange={graphIntervalRange}
                    setGraphIntervalRange={setGraphIntervalRange}
                />
                <div>
                    <ReportMenu
                        components={components}
                        setComponents={setComponents}
                        selectedRank={selectedRank}
                        handleButtonClick={handleButtonClick}
                        handleReset={handleReset}
                        handlePublish2={handlePublish2}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        setPublishedOrder={setPublishedOrder}
                    />
                </div>
            </section>

            <section style={{ marginTop: '1rem' }}>
                {isLoading ? <LoaderSkeletonGraph /> :
                    <DailyPageGraph
                        intervalType={intervalType}
                        selected4KpiArr={selected4KpiArr}
                        intervalRange={graphIntervalRange}
                    />
                }
            </section>

            {
                // components
                // .filter((item: any) => item.publish)
                // .sort((a: any, b: any) => a.rank - b.rank) // Ensure they are sorted by updated rank
                // .map((el: any) => {

                    // selectedItems
                    // .filter((item) => components.some((comp) =>  comp.rank === item.rank))
                    // .map((el) => {

                    renderOrder
                        .map((rank) => components.find((comp) => comp.rank === rank)) // Get matching component
                        .filter((el) => el?.publish) // Ensure only published components are rendered
                        .map((el) => {

                // components.map((el) => {
                    if ( el?.publish) {
                        return (
                            <div>
                                <>
                                    <div style={{ marginTop: '1rem' }} className='table_card_new'>
                                        <Table
                                            key={el.rank}
                                            intervalType={intervalType}
                                            intervalRange={intervalRange}
                                            fs={fs}
                                            setFs={setFs}
                                            setFs_col={setFs_col}
                                            components={components} 
                                            setComponents={setComponents}
                                            // onRowSelect={handleRowSelect}
                                            renderOrder={renderOrder}
                                            handlePublish2={handlePublish2}
                                            resetFlag={resetFlag}
                                            {...el}
                                        />
                                    </div>
                                    {
                                        fs_col[`${el.performance_type}_col` as keyof ColumnFilter].length > 0 && (
                                            <MetricTable
                                                selectedColumns={el.selectedColumns}
                                                intervalType={intervalType}
                                                performance_type={el.performance_type}
                                                intervalRange={graphIntervalRange}
                                            />
                                        )}
                                </>

                            </div>


                        )
                    }
                })
            }
        </div>
    )
}

export default DailyReport;
