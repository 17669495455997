import { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import './authStyle.scss';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import { toast } from "react-toastify";
import EnlyticalImg from "../../../assets/enlyticalImg.png"
import AgencyFillIcons from "../../../assets/svgs/AgencyFillIcons";
import AgencyNonFillIcon from "../../../assets/svgs/AgencyNonFillIcon";
import BrandOwnerFillIcon from "../../../assets/svgs/BrandOwnerFillIcon";
import BrandOwnerNonFillIcon from "../../../assets/svgs/BrandOwnerNonFillIcon";


const SignupMode: React.FC = () => {
    let navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return (
        <div className="login">
            <div className="login-left">
                <div className="login-loginContainer">
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <img src={EnlyticalLogo} alt="logo" height={"80px"} />
                    </div>
                    <div className="login-loginContainerHeader">
                        <h4 className="mb-1">Join Us !</h4>
                        <h6 className="mb-2">To begin this journey, tell us what type of account you’d be opening.</h6>
                    </div>
                    

                    

                    <div className="account-selection">
                        <div className="account-card"  
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={()=>navigate('/signup?type=BRAND_OWNER')}>
                            <div className="icon-container" >
                                {/* <i className="bi bi-person-rolodex"></i> */}
                                
                                {isHovered ? <AgencyFillIcons /> : <AgencyNonFillIcon />}
                               
                            </div>
                            <div className="account-details" >
                                <div className="account-header">Brand Owner</div>
                                <div className="account-description">Personal account to manage all your activities.</div>
                            </div>
                        </div>

                        <div className="account-card"
                            onMouseEnter={() => setIsHovered2(true)}
                            onMouseLeave={() => setIsHovered2(false)}
                            onClick={()=>navigate('/signup?type=AGENCY')}>
                            <div className="icon-container">
                                {/* <i className="bi bi-briefcase"></i> */}
                                {isHovered2 ? <BrandOwnerFillIcon /> : <BrandOwnerNonFillIcon />}
                            </div>
                            <div className="account-details" >
                                <div className="account-header">Agency</div>
                                <div className="account-description">Own or belong to a company, this is for you.</div>
                            </div>
                        </div>
                    </div>



                    <div className="already-account-block">Already have an account? <span onClick={()=>navigate('/login')}>Sign In</span></div>


                </div>
            </div>

            <div className="login-right">
                <div className="right-text">
                    <h2 className="mb-0">
                        <strong>AI enabled </strong>Growth
                    </h2>
                    <h2 className="mb-0">We make E-commerce Faster,</h2>
                    <h2 className="mb-0">Simpler and Better</h2>
                </div>
                <div className="right-content">
                    {/* <div className="right-text">Your command center for tracking, managing, and optimizing all aspects of your business. Whether you're overseeing projects, monitoring performance metrics, or collaborating with your team, Enlytical.ai provides you with the tools and insights you need to stay ahead of the curve.</div> */}
                    <div className="main-enlytical-img-container"><img src={EnlyticalImg} alt="img" className="main-enlytical-img" /></div>
                </div>

            </div>
        </div>
    );
};

export default SignupMode;
