import React, { useEffect, useState } from 'react'
import "./ReportMenu.scss"
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided, DroppableProvided } from 'react-beautiful-dnd';

const ReportMenu = (props: any) => {
    const { components, setComponents, selectedRank, handleButtonClick, handleReset, handlePublish2, selectedItems, setSelectedItems, } = props;
    const [toggle, setToggle] = useState<boolean>(false);
    // const [latestSlot, setLatestSlot] = useState<number | null>(null); // Track the latest active slot by its number
    const [latestSlot, setLatestSlot] = useState<number | null>(3); // Track the latest active slot by its number
    // console.log(' selected items',selectedItems);

    // console.log('components',components)



    //Update selected items when `selectedRank` changes (using `handleButtonClick` indirectly)
    useEffect(() => {
        const newItem = components.find((report: any) => report.rank === selectedRank);
        if (newItem && !selectedItems.some((item:any) => item.rank === newItem.rank)) {
            setSelectedItems((prev:any) => [...prev, newItem]);
        }
    }, [selectedRank, components, handleButtonClick, selectedItems]);

       
    // Handle drag-and-drop reordering
    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(selectedItems);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setSelectedItems(reorderedItems);

    };



    const handleRemoveSelected = (rank: number) => {
        // setSelectedItems((prev) => prev.filter((item) => item.rank !== rank));

        // components.forEach((el: any) => {
        //     if (el.rank === rank) {
        //         el.active = !el.active
        //     }
        // })
        // setComponents(components);

        // // Call the parent's handler to update its state (if necessary)
        // handleButtonClick(null);
        setComponents((prev: any) => prev.map((el: any) => (
            el.rank===rank ? { ...el,active:false }:{ ...el } 
        )))

         // Update `selectedItems` to remove the item with the given rank
        setSelectedItems((prev:any) => prev.filter((item:any) => item.rank !== rank));

        // Recalculate the latest slot based on the remaining items in `selectedItems`
       setLatestSlot((prev) => {
        const remainingSlots = selectedItems
            .filter((item:any) => item.rank !== rank)
            .map((item:any) => parseInt(item.bucket.replace("Slot ", ""))); // Extract slot numbers
        return remainingSlots.length > 0 ? Math.max(...remainingSlots) : null;
       });
    
    };



    return (
        <div className='report_menu_main'>
            <div className='report_menu ' onClick={() => setToggle((prev) => !prev)}>
                <span className='report_menu-bar' >Reports</span>
            </div>
            <div className='report_menu_main1'>
                {toggle && (
                    <div style={{ position: 'absolute', zIndex: 9999, marginTop: '.35rem', backgroundColor: '#fff', color: '#5B6871', border: '1px solid #ccc', borderRadius: '20px', padding: '1rem', right: '0rem', fontSize: '.88rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h4 style={{ color: '#181818' }}>Reports Menu</h4>
                            <button
                                onClick={() => setToggle((prev) => !prev)}
                                style={{ backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer', fontWeight: 'bold' }}
                            >
                                <i className="bi bi-x"></i>
                            </button>
                        </div>
                        <hr />
                        <div className='report-mid' style={{ display: 'flex', gap: '1rem' }}>

                            {/* Left section for displaying selected tables */}
                            <div className='report_left-section' style={{ flex: 1, border: '1px solid #D1D1D1', borderRadius: '16px', padding: '1rem', minHeight: '80px', width: '18em' }}>
                                {/* <h4>Selected Tables</h4> */}
                                {selectedItems.length === 0 ? (
                                    <p>No tables selected</p>
                                ) : (

                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="selectedItems">
                                            {(provided:any) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                    {selectedItems.map((item:any,index:any) => (
                                        <Draggable
                                        key={item.rank}
                                        draggableId={item.rank.toString()}
                                        index={index}
                                    >
                                        {(provided:any) => (
                                        <div  ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}

                                        key={item.rank} style={{ display: 'flex', border: '1.5px solid #D1D1D1', borderRadius: '8px', justifyContent: 'space-between', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', marginBottom: '0.5rem',
                                            ...provided.draggableProps.style,
                                         }}>
                                            <span>{item.name}</span>
                                            <button
                                                onClick={() => { handleRemoveSelected(item.rank) }}
                                                style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}
                                            >
                                                ×
                                            </button>
                                        </div>
                                    )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                                )}
                            </div>

                            {/* Right section for slot buttons */}
                            <div style={{ flex: 1, marginTop: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {/* <h4>Slot 1</h4> */}

                                    {components.map((report: any) => report.bucket === 'Slot 1' && (
                                        <button
                                            key={report.rank}
                                            onClick={() => {
                                                handleButtonClick(report.rank)
                                                setSelectedItems((prev:any) => [...prev, report]);
                                                setLatestSlot(1);
                                            }
                                            }
                                            disabled={
                                                (latestSlot !== null && latestSlot > parseInt(report.bucket.replace("Slot ", ""))) || 
                                                selectedItems.some((item:any) => item.rank === report.rank)
                                            }
                                            className={`slot-button ${latestSlot !== null && latestSlot > 1 ||  selectedItems.some((item:any) => item.rank === report.rank) ? 'disabled' : ''}`}
                                        >
                                            {report.name}
                                        </button>
                                    ))}
                                    <hr className='slot_hr' />
                                    {/* <h4>Slot 2</h4> */}
                                    {components.map((report: any) => report.bucket === 'Slot 2' && (
                                        <button
                                            key={report.rank}
                                            onClick={() => {
                                                handleButtonClick(report.rank)
                                                setSelectedItems((prev:any) => [...prev, report]);
                                                setLatestSlot(2);
                                            }
                                            }
                                            disabled={
                                                (latestSlot !== null && latestSlot > parseInt(report.bucket.replace("Slot ", ""))) || 
                                                selectedItems.some((item:any) => item.rank === report.rank)
                                            }
                                            className={`slot-button ${latestSlot !== null && latestSlot > 2 || selectedItems.some((item:any) => item.rank === report.rank) ? 'disabled' : ''}`}
                                        >
                                            {report.name}
                                        </button>
                                    ))}
                                    <hr className='slot_hr' />
                                    {/* <h4>Slot 3</h4> */}
                                    {components.map((report: any) => report.bucket === 'Slot 3' && (
                                        <button
                                            key={report.rank}
                                            onClick={() => {
                                                handleButtonClick(report.rank)
                                                setSelectedItems((prev:any) => [...prev, report]);
                                                setLatestSlot(3);
                                            }
                                            }
                                            
                                            disabled={
                                                (latestSlot !== null && latestSlot > parseInt(report.bucket.replace("Slot ", ""))) || 
                                                selectedItems.some((item:any) => item.rank === report.rank)
                                            }
                                            className={`slot-button ${selectedRank !== null && selectedItems.some((item:any) => item.rank === report.rank) ? 'disabled' : ''}`}
                                        >
                                            {report.name}
                                        </button>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <hr />

                        {/* Footer with Publish and Clear All buttons */}
                        <div className="report-menu-footer">
                            <button className="publish-button" onClick={() => { handlePublish2();  setToggle((prev) => !prev) }}><i className="bi bi-person-fill-check"></i>&nbsp;Publish</button>
                            <button className="clear-button publish-button" onClick={() => { handleReset(); setSelectedItems([]); setLatestSlot(null); setToggle((prev) => !prev) }}>
                                <i className="bi bi-trash-fill"></i>&nbsp;Clear All</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ReportMenu;
